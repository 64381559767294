import React, { useState } from "react";
import "./SliderComponent.scss";

const SliderComponent = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => {
      if (prevIndex > 0) {
        return prevIndex - 1;
      }
      return prevIndex; 
    });
  };
  
  const handleNext = () => {
    setCurrentIndex((prevIndex) => {
      if (prevIndex < images.length - 1) {
        return prevIndex + 1;
      }
      return prevIndex; 
    });
  };

  return (
    <div className="slider">
      <div className="slider__container">
        <div
          className="slider__content"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
          {images.map((image, index) => (
            <div className="slider__slide" key={index}>
              <p dangerouslySetInnerHTML={{ __html: image.text }}></p>
              <img src={image.src} alt={image.label} />
            </div>
          ))}
        </div>
        <div className="slider__navigation">
          <div
            className="slider__arrow"
            onClick={handlePrevious}
            style={{ opacity: currentIndex === 0 ? 0.5 : 1 }}
          >
            <svg
              width="22"
              height="38"
              viewBox="0 0 22 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 35L3 19L19 3"
                stroke={currentIndex === 0 ? "#888888" : "white"}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div
            className="slider__arrow"
            onClick={handleNext}
            style={{ opacity: currentIndex === images.length - 1 ? 0.5 : 1 }}
          >
            <svg
              width="22"
              height="38"
              viewBox="0 0 22 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 35L19 19L3 3"
                stroke={currentIndex === images.length - 1 ? "#888888" : "white"}
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderComponent;
