import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import React from "react";
import QuizMediaComponent from "../../quizComponent/quizMediaComponent/quizMediaComponent/QuizMediaComponent";
import "./QtestQuestionBasicComponent.scss";
import { S3URL } from "../../../api/BaseUrl";
import QuizFIBComponent from "../../quizComponent/quizFIBComponent/QuizFIBComponent";
import QuizMCQOptionsComponent from "../../quizComponent/quizMCQOptionsComponent/QuizMCQOptionsComponent";
import QtestQuestionInfoComponent from "../qtestQuestionInfoComponent/QtestQuestionInfoComponent";

function QtestQuestionBasicComponent({
  question,
  selectedAnswer,
  setSelectedAnswer,
  questionCount,
  noOfQuestions,
  handleAnswerSubmit
}) {

  const {
    questionTitle,
    qtestType,
    questionType,
    imageName,
    answerChoices,
    answerBlanks,
  } = question || {};

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "lg"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  const handleMCQAnswer = (e) => {
    handleAnswerSubmit(e.currentTarget.value)
  };

  const handleFIBAnswer = (answer) => {
    handleAnswerSubmit(answer)
  };

  const handleNQSProceed = () => {
    handleAnswerSubmit()
  };

  const answerChoicesList = eval(answerChoices);

  return (
    <div className="quizBasic">
      <div className="quizBasic__question prevent-select">
        <p>{questionTitle}</p>
        <p>
          {questionCount} / {noOfQuestions}
        </p>
      </div>

      <div className="quizBasic__image">
        <QuizMediaComponent
          // mediaUrl = {S3URL + "test/sampleVideo.mp4"}
          mediaUrl={
            S3URL +
            (qtestType == "Interest Based" ? "generated/" : "readymade/") +
            imageName
          }
        />
      </div>
      
      {["FIB", "CNT"].includes(questionType) ? (
        <div className="quizBasic__fib">
          <QuizFIBComponent
            handleFIBAnswer={handleFIBAnswer}
            answerBlanks={answerBlanks}
          />
        </div>
      ) : questionType =="NQS" ?
        <QtestQuestionInfoComponent
          handleNext = {handleNQSProceed}
          displayNote = {answerChoicesList[0]}
          endQuizFlag = {questionCount == noOfQuestions}
          infoDuration = {question.timeToAnswer}
        />  
      : (
        <QuizMCQOptionsComponent
          answerChoicesList={answerChoicesList}
          handleSubmitAnswer={handleMCQAnswer}
          selectedAnswer={selectedAnswer}
        />
      )}
    </div>
  );
}

export default QtestQuestionBasicComponent;
