import "./EditProfileComponent.scss"
import React, { useContext, useEffect, useState } from 'react'
import TitleComponent from '../../shared/titleComponent/TitleComponent';
import FillButtonComponent from "../../shared/fillButtonComponent/FillButtonComponent";
import Context from "../../context/Context"
import { useNavigate } from "react-router-dom";
import { Autocomplete, Button, FormControlLabel, Grid, Paper, Radio, RadioGroup, TextField } from "@mui/material";
import { cities } from "../../utils";
import { makeStyles } from "@mui/styles";
import CustomModalComponent from "../../shared/customModalComponent/CustomModalComponent";
import axios from "axios";
import { BEURL } from "../../api/BaseUrl";
import apiEndpoints from "../../api/EndPoints";
import InputComponent from "../../shared/inputComponent/InputComponent";
import { checkTypes, checkValidation } from "../../utils/inputCheckValidation";
import LoginPromptComponent from "../../shared/loginPromptComponent/LoginPromptComponent";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    color: "white",
    fontFamily: "CaustenM",
    fontSize: "1.375rem",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
      border: "1px solid #FFF",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
      border: "1px solid #FFF",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
      border: "1px solid #FFF",
    }
  }
}));

const RadioBtn = ({value, label}) => (
  <FormControlLabel sx = {{
    '.MuiFormControlLabel-label':{
        color:"#FFF",
        fontFamily:"CaustenR",
        fontWeight: 700,
        fontSize:"1.188rem",
        lineHeight:"1.188rem",
        '@media screen and (max-width:1200px)': {
          fontSize: '1.188rem',
          lineHeight: '1.188rem',          
        },
        '@media screen and (max-width:1000px)': {
          fontSize: '1rem',
          lineHeight: '1rem',          
        },
        '@media screen and (max-width:600px)': {
          fontSize: '1rem',
          lineHeight: '1rem',          
        }
      }
    }} 
  value={value} 
  control={ <Radio 
              sx={{
                color: "#FFF",
                '&.Mui-checked': {
                  color: "#fdb913",
                },                                    
              }}
          />} 
  label={label} />
)

const EditProfileComponent = () => {
  const navigate = useNavigate()
  const [city, setCity] = useState("");
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [intro, setIntro] = useState("")
  const [username, setUsername] = useState("")
  const [ageGroup, setAgeGroup] = useState("")
  const [isDisabled, setIsDisabled] = useState(false)
  const [imageURL, setImageURL] = useState("")
  const [isImageUploaded, setIsImageUploaded] = useState(false)
  const {isLoggedIn, setNotificationType, setNotificationMessage, setNotificationDisplayFlag, setLoadingDisplayFlag} = useContext(Context)
  const classes = useStyles();
  const setAlert = (type, message) => {
    setNotificationType(type)
    setNotificationMessage(message)
    setNotificationDisplayFlag(true)
  }

  useEffect(() => {
    if (!isLoggedIn)
      return //do not proceed if not logged in
    const url = BEURL + apiEndpoints.getProfile
    setLoadingDisplayFlag(true)
    axios.get(url).then((res) => {
      setFirstName(res.data['given_name'] || "")
      setLastName(res.data['family_name'] || "")
      setUsername(res.data['username'] || "")
      setIsDisabled(!!res.data['username'])
      setAgeGroup(res.data['custom:age_group'] || "")
      setCity(res.data['locale'] || "")
      setPhoneNumber(res.data['phone_number']?.slice(3) || "") 
      setIntro(res.data['profile'] || "")
      setImageURL(res.data['picture'] || "https://qshala-quiz-profile-images.s3.ap-south-1.amazonaws.com/no-image.jpg") 
      setLoadingDisplayFlag(false)
    }).catch((err) => console.log(err))
  }, [])  

  const handleSave = () => {
    var errorMessage = checkValidation([
      {value: username, fieldName:"Username", checksFor: [checkTypes.NOTEMPTY, checkTypes.NOOFCHARS], charLength: 5, charLengthCheckType:"GREATER"},      
      {value: ageGroup, fieldName:"Age", checksFor: [checkTypes.NOTEMPTY]},
      {value: phoneNumber, fieldName:"Mobile Number", checksFor: [checkTypes.NOOFCHARS], charLength: 10, charLengthCheckType:"EQUAL"},
      {value: firstName, fieldName:"First Name", checksFor: [checkTypes.NOTEMPTY, checkTypes.NAME]},
      {value: lastName, fieldName:"Last Name", checksFor: [checkTypes.NOTEMPTY, checkTypes.NAME]},
    ])
    if(errorMessage){
      return setAlert("error", errorMessage)
    }else{
      const url = BEURL + apiEndpoints.updateProfile
      const data = {
        'given_name':firstName.trim(),
        'family_name':lastName.trim(),
        'locale':city,
        'profile':intro.trim(),
        'phone_number':phoneNumber,
        'custom:age_group': ageGroup,
        'preferred_username':username.trim().toLowerCase()
      }
      if (isImageUploaded){
        data['image'] = imageURL
      }
      axios.post(url,data).then(() => {
        setAlert('success', 'Profile successfully updated!')
        setTimeout(() => {
          navigate("/profile")
        }, 2000);  
      })
    }  
  };

  const handleUsernameChange = (e) => {
    if(username.length>=5){
      const url = BEURL + apiEndpoints.checkUsername
      const data = {
        "username": e.target.value
      }
      setLoadingDisplayFlag(true)
      axios.post(
        url,
        data
      ).then((res) => {
          setAlert("info", res.data)
          setUsername(e.target.value)
        }
      ).catch((error) => {
          setAlert("error", error.response.data)
          setUsername("")
        }
      ).finally(() => setLoadingDisplayFlag(false))
    }
  }

  const handleImageClick = () => {
    document.getElementById("imgupload").click()
  }

  const handleFileChange = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    
    if (file.size> 2000000){
      setAlert("error","Image too big, max image size is 2MB")
      return
    }

    reader.readAsDataURL(file)

    reader.onloadend = () => {
      setImageURL(reader.result)
      setIsImageUploaded(true)
    }
  }
  
  
  if (!isLoggedIn){
    return(
      <LoginPromptComponent open={!isLoggedIn} handleClose={() => navigate('/')} />
    )
  }
  return (
    <div className='editprofile' id="editprof">
      <TitleComponent 
        title="Edit Profile" 
        handleOnClick={() => navigate("/profile")}
        align="left" style={{marginRight: "auto", marginLeft:'14%'}}
      />  
      <div className="editprofile__pic">
        <img className="editprofile__pic__img" src= {imageURL} 
          onClick={handleImageClick} 
        />     
        <input 
          type="file" id="imgupload" style={{display:"none"}}
          accept="image/*"
          onChange={handleFileChange}
        />  
      </div>     
        <div className="editprofile__content">
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md:10, lg: 30 }}>
            <Grid item xs={12} md={6}>
              <InputComponent 
                ClassName="editprofile__content__input"
                Maxlength="20"
                Disabled={isDisabled}
                Label="Username" Type="text" Value={username} OnChange={(e) => setUsername(e.target.value)} OnBlur={handleUsernameChange}
              />
            </Grid>
            <Grid item xs={12} md={6} >
              <div className="column">
                <label className="editprofile__content__label">Age</label>        
                <RadioGroup
                  aria-label="quiz"
                  name="quiz"
                  value={ageGroup}
                  onChange={(e) => setAgeGroup(e.target.value)}
                  row
                  sx = {{rowGap: "0.5rem", columnGap: "%"}}
                >                       
                  <RadioBtn label={"6-8 YEARS"} value={"6 to 8"} />
                  <RadioBtn label={"9-17 YEARS"} value={"9 to 14"}/>                                              
                  <RadioBtn label={"18+ YEARS"} value={"9 to 14 "}/>      
                </RadioGroup>  
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputComponent 
                ClassName="editprofile__content__input"
                Maxlength="15"
                Label="First Name" Type="text" Value={firstName} OnChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputComponent 
                  ClassName="editprofile__content__input"
                  Maxlength="15"
                  Label="Last Name" Type="text" Value={lastName} OnChange={(e) => setLastName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputComponent 
                    ClassName="editprofile__content__input"
                    Label="Mobile Number" Type="number" Value={phoneNumber} OnChange={(e) => setPhoneNumber(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="editprofile__content__item">
                <label>City</label>
                <Autocomplete   
                  classes={classes}                              
                  options={cities}
                  autoComplete
                  value={city}
                  onChange={(e, newValue) => {setCity(newValue)}}
                  PaperComponent={({ children }) => (
                    <Paper 
                      className="editprofile__content__item__autocomplete"
                    style={{ 
                      background: "#75B543", 
                      color: "#FFF", 
                      fontFamily: "CaustenM",
                      fontSize: "1.375rem",
                      letterSpacing: "0.0313rem",
                      lineHeight: "1.375rem", 
                      borderRadius: "10px",
                      border: "1px solid #FFF",
                      marginBottom:"1rem",
                      marginTop:"1rem",
                      overflowY: "hidden",
                    }}>{children}</Paper>
                  )}
                  renderInput={(params) => (
                    <TextField variant="outlined"
                     {...params} />
                  )}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={12}>
              <InputComponent 
                ClassName="editprofile__content__input"
                Maxlength="100" Placeholder="Start typing here..."
                Label="Introduction" Type="text" Value={intro} OnChange={(e) => setIntro(e.target.value)}
              />
            </Grid>
          </Grid>
        </div>
        <div className="editprofile__savebtn">
          <FillButtonComponent 
            displayText={"Save"} 
            handleClick={handleSave}
          />
        </div>
    </div>
  )
}

export {EditProfileComponent as default, RadioBtn}