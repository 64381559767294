import React from 'react'

const dropdownIconStyle = {
    width: "1.5rem",
    height: "1.5rem",
}

export const HelpIcon = (props) => (
    <img src="/images/Help.png" style={dropdownIconStyle} {...props}/>
)

export const ExitIcon = (props) => (
    <img src="/images/Exit.png" style={dropdownIconStyle} {...props}/>
)

export const LogoutIcon = (props) => (
    <img src="/images/Logout.png" style={dropdownIconStyle} {...props}/>
)

export const SettingsIcon = (props) => (
    <img src="/images/Settings.png" style={dropdownIconStyle} {...props}/>
)
export const ShareIcon = (props) => (
    <img src="/images/Share.png" style={dropdownIconStyle} {...props}/>
)

export const EditIcon = (props) => (
    <img src="/images/edit.svg" style={dropdownIconStyle} {...props}/>
)

export const RearrangeIcon = (props) => (
    <img src="/images/rearrange.svg" style={dropdownIconStyle} {...props}/>
)