import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import CustomModalComponent from '../customModalComponent/CustomModalComponent'



const PricingPromptComponent = ({open, handleClose}) => {
  const navigate = useNavigate()
  return (
    <CustomModalComponent open={open} handleClose={handleClose}>
      <div className="selectMultiplayer__modal">
        <h1>Hi There!</h1>
        <p>
          Please send an email to " support@walnuts.co.in " to purchase our premium content
        </p>
        <div className="selectMultiplayer__modalButtonContainer">
            {/* <Button
              variant="contained"
              style={{backgroundColor: "#30b2e7"}}
              className="selectMultiplayer__modalButton"
              onClick={() => navigate('/pricing')}
            >
              Buy
            </Button> */}
        </div>
      </div>
    </CustomModalComponent>
  )
}

export default PricingPromptComponent