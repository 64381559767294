import { Typography } from "@mui/material";
import React from "react";
import "./TitleComponent.scss";
import useMediaQuery from "@mui/material/useMediaQuery";
import { MdOutlineEdit } from "react-icons/md";
import { useTheme } from "@mui/material/styles";

const TitleComponent = ({ title, handleOnClick, isBackReqd = true, isIcon, onIconClick, ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div className="titleComponent">
      {isBackReqd && <img
        src="/images/BackArrow.svg"
        className="titleComponent__backArrow"
        onClick={handleOnClick}
      />}
      <Typography
        {...props}
        sx={{
          fontSize: isMobile ? "2rem" : isTablet ? "3rem" : "4.375rem",
          fontFamily: "CaustenEB",
          letterSpacing: "0.015rem",
          lineHeight: isMobile ? "2rem" : isTablet ? "3rem" : "4.375rem",
        }}
        variaint="h1"
      >
        {title}
      </Typography>      
        { isIcon && (<div 
          className="titleComponent__icon"
          onClick={onIconClick}
        >
          <MdOutlineEdit size={20} />
          <p>Edit Profile</p>
        </div>)}    
    </div>
  );
};

export default TitleComponent;
