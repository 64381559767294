import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import CustomModalComponent from '../customModalComponent/CustomModalComponent'

const LoginPromptComponent = ({open, handleClose, redirectFlag=false}) => {
  const navigate = useNavigate()
  return (
    <CustomModalComponent open={open} handleClose={handleClose}>
      <div className="selectMultiplayer__modal">
        <h1>Hi There!</h1>
        <p>
          Please login/signup to access this!
        </p>
        <div className="selectMultiplayer__modalButtonContainer">
            <Button
              variant="contained"
              style={{backgroundColor: "#30b2e7"}}
              className="selectMultiplayer__modalButton"
              onClick={() => {
                redirectFlag?
                  navigate('/signin', {state:{redirectFlag: redirectFlag}}):
                  navigate('/signin')
              }}
            >
              Login
            </Button>
        </div>
      </div>
    </CustomModalComponent>
  )
}

export default LoginPromptComponent
