import "./CreateMTFComponent.scss";
import React, { useContext, useEffect, useState } from "react";
import BlueInputComponent from "../blueInputComponent/BlueInputComponent";
import { MdOutlineArrowBackIosNew } from "react-icons/md";
import QuestionDetailsComponent from "../questionDetailsComponent/QuestionDetailsComponent";
import DiscardSlideComponent from "../discardSlideComponent/DiscardSlideComponent";
import CreateNonQuestionComponent from "../createNonQuestionComponent/CreateNonQuestionComponent";
import SaveordiscardComponent from "../saveordiscardComponent/SaveordiscardComponent";
import Context from "../../../context/Context";
import { BEURL } from "../../../api/BaseUrl";
import apiEndpoints from "../../../api/EndPoints";
import axios from "axios";
import { get_answer_blanks_with_hint_letter } from "../../../shared/constants";

const MTFAnswer = ({ optionNum, option, setOption, answer, setAnswer }) => {
  const onChangeAnswer = (e) => {
    setAnswer((prevAnswer) => {
      let updatedAnswer = { ...prevAnswer };
      updatedAnswer[e.target.id.split("_")[1]] = e.target.value;
      return updatedAnswer;
    });
  };

  const onChangeOption = (e) => {
    setOption((prevOption) => {
      let updatedOption = { ...prevOption };
      updatedOption[e.target.id.split("_")[1]] = e.target.value;
      return updatedOption;
    });
  };

  return (
    <div className="createMTF__answers">
      <p className="questionTitle">{optionNum}</p>
      <BlueInputComponent
        id={"option_" + optionNum}
        OnChange={onChangeOption}
        Value={option}
        Maxlength="60"
      />
      <MdOutlineArrowBackIosNew
        style={{
          transform: "scaleX(-1)",
          marginLeft: "25px",
          marginRight: "25px",
        }}
        color=" #30B2E7"
        size={22}
      />
      <BlueInputComponent
        id={"answer_" + optionNum}
        OnChange={onChangeAnswer}
        Value={answer}
        Maxlength="60"
      />
    </div>
  );
};

const CreateMTFComponent = ({ questionData, quizid, setComponentInFocus }) => {
  const [question, setQuestion] = useState("");
  const [option, setOption] = useState({ 1: "", 2: "", 3: "", 4: "" });
  const [answer, setAnswer] = useState({ 1: "", 2: "", 3: "", 4: "" });
  const [openQuestionDetails, setOpenQuestionDetails] = useState(false);
  const [time, setTime] = useState(30);
  const [points, setPoints] = useState(100);
  const [openDiscardConfirmation, setOpenDiscardConfirmation] = useState(false);
  const [showAnswerSlide, setShowAnswerSlide] = useState(false);
  const [answerContent, setAnswerContent] = useState("");
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const { setLoadingDisplayFlag, setEditQuestionsList, setEditQuizDetails, setNotificationType, setNotificationMessage, setNotificationDisplayFlag, setQuestionData } = useContext(Context);
  
  const setAlert = (type, message) => {
    setNotificationType(type);
    setNotificationMessage(message);
    setNotificationDisplayFlag(true);
  }
  
  //Add question
  const saveQuestion = (data, isEdit) => {
    const url = isEdit
      ? BEURL + apiEndpoints.editQuestion
      : BEURL + apiEndpoints.addQuestion;
    axios
      .post(url, JSON.stringify(data))
      .then((res) => {
        let editedQuestions = res.data;
        if (isEdit) {
          editedQuestions = editedQuestions.map(question => {
            if (question.questionType === "CNT" || question.questionType === "FIB") {
              question.answerBlanks = get_answer_blanks_with_hint_letter(eval(question.correctAnswer)[0]);
            }
            return question;
          });
        }
        setEditQuestionsList(editedQuestions);
        if (isEdit) {
          let editedQuestion = editedQuestions.find((question) => {
            return data.questionId === question.questionId;
          });
          setQuestionData(editedQuestion); 
        }
        let newQuestionIdList = editedQuestions.map(question=>question.questionId)
        setEditQuizDetails((prevQuiz) => {
          let updatedQuiz = { ...prevQuiz };
          updatedQuiz.questionIds = newQuestionIdList
          return updatedQuiz
        })
        if (res.status === 200) {
          if (isEdit) {
            setAlert("success", "Question Edited Successfully");
          }
          else {
            setAlert("success", "Question Added Successfully");
          }
        }
      })
      .catch((error) => alert(error.response.data))
      .finally(() => {
        setLoadingDisplayFlag(false);
        if (isEdit) {
          //if question was edited dont go to new slide
          setShowAnswerSlide(false); //show the question slide
          setOpenQuestionDetails(false); //close the question details pop up
        } //if new question was added
        else setComponentInFocus("SelectSlideComponent"); //show new slide
      });
  };

  //Shuffle array algo
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  //Save question
  const handleSaveQuestion = () => {
    setLoadingDisplayFlag(true);
    const data = {
      quizId: quizid,
      questionType: "MTF",
      questionTitle: question,
      timer: time,
      maxPoints: points,
      answerContent: answerContent,
    };
    //Add left hand side options
    Object.keys(option).forEach((optionNum) => {
      data["leftoption" + optionNum] = option[optionNum];
    });
    //Add right hand side answers
    Object.keys(answer).forEach((answerNum) => {
      data["answer" + answerNum] = answer[answerNum];
    });
    //Jumble right hand side answers
    shuffleArray(Object.keys(answer)).forEach((answerNum, idx) => {
      data["option" + (idx + 1)] = answer[answerNum];
    });
    //Add questionId for edited question
    if (!!questionData.questionId) data.questionId = questionData.questionId;
    //If the question was already present send a edit request
    //Else add new question
    saveQuestion(data, !!questionData.questionId);
  };
  //Prevent premature form submissions
  useEffect(
    () =>
      setIsSaveDisabled(
        Object.values(answer).indexOf("") !== -1 ||
          Object.values(answer).indexOf("") !== -1 ||
          question === ""
      ),
    [answer, question, option]
  );

  //when navigated after clicking on the miniSlides
  useEffect(() => {
    if (
      Object.keys(questionData).length === 0 ||
      questionData.questionType !== "MTF"
    )
      return; //dont execute if questionData is empty
    questionData.questionTitle && setQuestion(questionData.questionTitle);
    questionData.timeToAnswer && setTime(questionData.timeToAnswer);
    setPoints(questionData.maxPoints??100);
    if (questionData.correctAnswer) {
      const [option, answer] = eval(questionData.correctAnswer);
      const optionData = {};
      const answerData = {};
      option.map((opt, idx) => (optionData[idx + 1] = opt));
      answer.map((ans, idx) => (answerData[idx + 1] = ans));
      setAnswer(answerData);
      setOption(optionData);
    }
    //show question slide
    setShowAnswerSlide(false);
  }, [questionData]);

  return (
    <>
      <QuestionDetailsComponent
        open={openQuestionDetails}
        handleClose={() => setOpenQuestionDetails(false)}
        handleAddQuestion={handleSaveQuestion}
        editQuestonFlag={!!questionData.questionId} // True if questionId exists
        time={time}
        setTime={setTime}
        points={points}
        setPoints={setPoints}
      />
      <DiscardSlideComponent
        open={openDiscardConfirmation}
        handleClose={() => setOpenDiscardConfirmation(false)}
        handleDiscardSlide={() => setComponentInFocus("SelectSlideComponent")}
      />
      <div className="createMCQ createMTF">
        <div className="createMCQ__inner">
          <p className="questionTitle">
            Match the Following Question({question.length}/300 characters)
          </p>
          <BlueInputComponent
            Value={question}
            OnChange={(e) => setQuestion(e.target.value)}
            Maxlength="300"
          />
          <p className="questionTitle createMTF__answerTitle">
            Answers (Fill in the answers in the correct order)
          </p>
          {Object.keys(answer).map((optionNum) => (
            <MTFAnswer
              optionNum={optionNum}
              key={optionNum}
              answer={answer[optionNum]}
              setAnswer={setAnswer}
              option={option[optionNum]}
              setOption={setOption}
            />
          ))}
        </div>
        <SaveordiscardComponent
          isSaveDisabled={isSaveDisabled}
          handleSave={() => setOpenQuestionDetails(true)}
          handleDiscard={() => setOpenDiscardConfirmation(true)}
        />
      </div>
    </>
  );
};

export default CreateMTFComponent;
