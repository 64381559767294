
import React, { useContext, useEffect, useState } from 'react'
import ReactDOMServer from 'react-dom/server'
import { useNavigate, useParams } from 'react-router-dom'
import { FEURL } from '../../api/BaseUrl'
import Context from '../../context/Context'
import FillButtonComponent from '../../shared/fillButtonComponent/FillButtonComponent'
import "./EmbedComponent.scss"

const EmbedComponent = () => {
    const {setNotificationType, setNotificationMessage, setNotificationDisplayFlag} = useContext(Context)
    const setAlert = (type, message) => {
        setNotificationType(type)
        setNotificationMessage(message)
        setNotificationDisplayFlag(true)
    }
    let {qtestId, embedType} = useParams();
    qtestId = qtestId.trim().toLowerCase();
    const navigate = useNavigate()
    
    if (["fullPageEmbed", "popupButton", "redirectButton"].indexOf(embedType) === -1)
        navigate("/")

    const handleGetCode = () => { 
        switch(id){
            case "fullPageEmbed":
                navigator.clipboard.writeText(ReactDOMServer.renderToStaticMarkup(codes["fullPageEmbed"])+ReactDOMServer.renderToStaticMarkup(codes["qsembed_script"]));
                break;
            case "popupButton":
                navigator.clipboard.writeText(ReactDOMServer.renderToStaticMarkup(codes["popupButton"])+ReactDOMServer.renderToStaticMarkup(codes["qsembed_script"]));
                break;
            case "redirectButton":
                navigator.clipboard.writeText(ReactDOMServer.renderToStaticMarkup(codes["redirectButton"])+ReactDOMServer.renderToStaticMarkup(codes["qsembed_script"]));
                break;
        }
        setAlert("success", "Code copied to clipboard")
    }

    const [id, setId] = useState(embedType)

    useEffect(() => {
      let element = document.getElementById(id)
      if (!!element){
        element.style.background = "#fff"
        element.style.color = "#30b2e7" 
        setPreview(codes[id]) 
      }
      //unselect previous selection
      return () => {
        let element = document.getElementById(id)
        if (!!element){
            element.style.background = "#30b2e7"
            element.style.color = "#fff"  
        } 
      }
    }, [id])  

    useEffect(() => {          
      //load script
      const script = document.createElement("script");
      script.src = `${FEURL}/scripts/qsembed.js`;  
      script.id = "qsembed";  
      document.body.appendChild(script); 
    
      return () => {
        const script = document.getElementById("qsembed")
        script.remove()
      }
    }, [])
    

    const [preview, setPreview] = useState()
    const codes = {
        "popupButton" : <button id="embed__qTestPopupButton" className='embed__questButton' name={qtestId}>Start QTest</button>,
        "redirectButton" : <button id="embed__qTestRedirectButton" className='embed__questButton' name={qtestId}>Redirect to QTest</button>,
        "fullPageEmbed" : <div className='embed__qTestfullpage'><iframe src={`${FEURL}/qtest/${qtestId}`} height="600" width="700" frameBorder="0" allowFullScreen/></div>,
        "qsembed_script" : <script src={`${FEURL}/scripts/qsembed.js`}> </script>
    }

    const handleOnFullPage = () => { 
        window.location = `${FEURL}/embed/fullPageEmbed/${qtestId}`
    }

    const handleOnPopup = () => { 
        window.location = `${FEURL}/embed/popupButton/${qtestId}`
    }

    const handleOnRedirect = () => { 
        window.location = `${FEURL}/embed/redirectButton/${qtestId}`
     }

    return (
        <div className='embedComponent'>
            <div className='embedComponent__mode'>
                <h2> Embed in a web page </h2>
                <h4> EMBED MODE </h4>
                <div className='embedComponent__mode__types'>
                    <div className='embedComponent__mode__types__item' id="popupButton" name={qtestId}>
                        <div className='embedComponent__mode__types__item__inner'
                            onClick={handleOnPopup}
                        >
                            Pop Up
                        </div>
                    </div>
                    <div className='embedComponent__mode__types__item' id="redirectButton" name={qtestId}>
                        <div className='embedComponent__mode__types__item__inner'
                            onClick={handleOnRedirect}
                        >
                            Redirect
                        </div>
                    </div>
                    <div className='embedComponent__mode__types__item' id="fullPageEmbed" name={qtestId}>
                        <div className='embedComponent__mode__types__item__inner'
                            onClick={handleOnFullPage}
                        >
                            Full Page
                        </div>
                    </div>                    
                </div>
                <div className='embedComponent__mode__getcode'>
                    <FillButtonComponent displayText="Get the code" handleClick={handleGetCode} />
                </div>
            </div>
            <div className='embedComponent__preview'>   
                {preview}
            </div>
        </div>
    )
}

export default EmbedComponent
