import React, { useEffect } from "react";
import CustomModalComponent from "../customModalComponent/CustomModalComponent";
import { Button } from "@mui/material";
import "./exitWarning.scss"
import { useNavigate } from "react-router-dom";


const ExitWarning = ({modalOpen, setModalOpen}) => {
    const navigate = useNavigate();

    const handleExitButton = () => {
        navigate("/");
        setModalOpen(false);
    }

    return (
        <CustomModalComponent
            open={modalOpen}
            handleClose={() => setModalOpen(false)}
        >
            <div className="exitWarning__modal">
                <h1>
                    Do You Want to
                    <br />
                    Exit the Quiz?
                </h1>
                <p>
                    This action will lead to you leaving the quiz.
                    <br />
                    Click on the button below if you wish to proceed.
                </p>
                <Button
                    variant="contained"
                    onClick={handleExitButton}
                    style={{ backgroundColor: "#30b2e7" }}
                    className="exitWarning__exitQuiz"
                >
                    EXIT QUIZ
                </Button>
            </div>
        </CustomModalComponent>
    );
}

export default ExitWarning;

