import "./ReportsComponent.scss"
import React, { useContext, useEffect, useState } from 'react'
import { reports_welcomebanner_description } from '../../copies'
import TitleComponent from '../../shared/titleComponent/TitleComponent'
import DashboardDrawerComponent from '../dashboardDrawerComponent/DashboardDrawerComponent'
import WelcomeBannerComponent from '../welcomeBannerComponent/WelcomeBannerComponent'
import FillButtonComponent from "../../shared/fillButtonComponent/FillButtonComponent"
import DisplayGridComponent from "../displayGridComponent/DisplayGridComponent"
import HorizonatalTileComponent from "../horizonatalTileComponent/HorizonatalTileComponent"
import CustomModalWithTitleComponent from "../../shared/customModalComponent/CustomModalWithTitleComponent"
import { useNavigate } from "react-router-dom"
import apiEndpoints from "../../api/EndPoints"
import { BEURL } from "../../api/BaseUrl"
import axios from "axios"
import Context from "../../context/Context"
import { Tooltip } from "@mui/material"
import { capitalizeString } from "../../shared/constants"

const ReportGeneratedPopUp = ({open}) => {
  const navigate = useNavigate()
  return(
    <CustomModalWithTitleComponent 
      open={open} title="Reports Generated!" bgColor="#4B9710"
      customStyles={{width:"40%"}}
    >
      <div className="reportPopUp">
        <p>Your report has been downloaded successfully. Please head back to the dashboard or generate more reports by clicking on the button below.</p>
        <FillButtonComponent handleClick={() => window.location.reload()} displayText="GENERATE MORE REPORTS"/>
        <FillButtonComponent handleClick={() => {
            navigate("/dashboard")
          }} displayText="BACK TO DASHBOARD" bgColor="transparent" style={{color:"#FDB913"}}/>
      </div>
    </CustomModalWithTitleComponent>
  )
}

const ReportsComponent = () => {
  const [shortReportQuizList, setShortReportQuizList] = useState([])
  const reportTypes = ["QUIZ OVERVIEW", "ENGAGEMENT RESULTS", "PLAYERWISE ANALYSIS", "QUESTIONWISE ANALYSIS"]
  const [selectedQuizId, setSelectedQuizId] = useState("")
  const [selectedQuizVariant, setSelectedQuizVariant] = useState("")
  const [selectedReportTypes, setSelectedReportTypes] = useState([])
  const [goBack, setGoBack] = useState(false)
  const [activePage, setActivePage] = useState("welcomePage")
  const [reportDownloaded, setReportDownloaded] = useState(false)
  const handleOnClickQuiz = (e) => setSelectedQuizId(e.currentTarget.id)

  const {setNotificationType, setNotificationMessage, setNotificationDisplayFlag, setLoadingDisplayFlag} = useContext(Context)
  const setAlert = (type, message) => {
    setNotificationType(type)
    setNotificationMessage(message)
    setNotificationDisplayFlag(true)
  }

  const handleReportTypeSelection = (e) => {
    let id = e.currentTarget.id
    document.getElementById(id).classList.toggle("report__activeBtn")
    let index = selectedReportTypes.indexOf(id)
    if (index === -1){ //add to array
      setSelectedReportTypes((prev) => [id, ...prev])
    }else{ //remove from array
      setSelectedReportTypes((prev) => [
        ...prev.slice(0, index),
        ...prev.slice(index + 1)
      ])
    }
  }

  useEffect(() => {
    const tile = document.getElementById(selectedQuizId)
    if (!!tile && !goBack)
      tile.classList.toggle("report__activeTile")
    if (goBack)
      setGoBack(false)
    return () => {
      const tile = document.getElementById(selectedQuizId)     
      if (!!tile)
        tile.classList.toggle("report__activeTile")        
    }
  }, [selectedQuizId, activePage])

  const reportList = shortReportQuizList?.map((quiz) => {
    return (
      <Tooltip title={quiz.toolTipText} placement="top" arrow>
        <span>
          <HorizonatalTileComponent 
            key={quiz.key}
            quizId={quiz.quizId}
            quizType={quiz.quizType}
            imageName={quiz.imageName}
            title={quiz.title}
            subtitle1={quiz.subtitle1}
            subtitle2={quiz.subtitle2}
            handleOnClick={handleOnClickQuiz}
            isMenuAvailable={false}
          />
        </span>
      </Tooltip>
    )
  })  
  
  const handleQuizVariantSelection = (quizVariant) => () => {
    setSelectedQuizVariant(quizVariant)
    setActivePage("reportSelectionQuizPage")
    setShortReportQuizList([])
    setLoadingDisplayFlag(true)
    const data = {
      "quizVariant":quizVariant
    }
    axios.post(
      BEURL+apiEndpoints.getShortReport,
      data
    ).then((res) => {
      // Change to common keys for rendering in Horizontal Component
      if(res.data["quizVariant"]=="course"){
        // Need to be rewritten when implementing courses
        return []
      }
      else if(res.data["quizVariant"]=="quiz"){
        return res.data["quizzes"].map((quiz) => {
          let resObj = {}
          resObj["key"]=quiz.quizId
          resObj["quizId"]=quiz.quizId
          resObj["quizType"]=quiz.quizType
          resObj["imageName"]=quiz.quizImage
          resObj["title"]=quiz.quizName
          resObj["toolTipText"]=quiz.quizDesc
          resObj["subtitle1"]=quiz.roomCreatedAt
          resObj["subtitle2"]=quiz.roomId
          return resObj
        })
      }
      else if(res.data["quizVariant"]=="qtest"){
        return res.data["quizzes"].map((quiz) => {
          let resObj = {}
          resObj["key"]=quiz.qtestId
          resObj["quizId"]=quiz.qtestId
          resObj["quizType"]=quiz.qtestType
          resObj["imageName"]=quiz.qtestImage
          resObj["title"]=quiz.qtestName
          resObj["toolTipText"]=quiz.qtestDesc
          resObj["subtitle1"]=quiz.startOn
          resObj["subtitle2"]=quiz.qtestStatus
          return resObj
        })
      }
      else{ // Invalid quizVariant
        return []
      }
    }).then((res)=>{
      setShortReportQuizList(res)
    }).catch((err) => {
      setAlert("error",err.response.data)
    }).finally(() => {
      setLoadingDisplayFlag(false)
    })  
  }

  const handleGenerateReport = () => { 
    setLoadingDisplayFlag(true)
    axios({
      url: BEURL + apiEndpoints.getQuizExcelReportSummary, //excel download url
      method: 'POST',
      data: {
        quizVariant: selectedQuizVariant,
        quizId: selectedQuizId,
        reportTypes: selectedReportTypes
      },
      responseType: 'blob', // important
    }).then((response) => {	
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', selectedQuizVariant+'Summary.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoadingDisplayFlag(false)
        setReportDownloaded(true)
    }).catch((err) => {
      setAlert("error",err.response.data)
    });
    // const alertMsg= `Report will be generated for quizId: ${selectedQuizId} with the following data ${selectedReportTypes}`
    // alert(alertMsg)
  }

  return (
    <div className='dashboard page'>
      <ReportGeneratedPopUp open={reportDownloaded}/>
      <DashboardDrawerComponent selectedView="reports"/>
      <div className='dashboard__content'>
        {activePage === "welcomePage"?
          <>
            <p className='page__title'>Reports</p>
            <div className="dashboard__header">
              <WelcomeBannerComponent 
                description={reports_welcomebanner_description}
                handleOnClick={() => setActivePage("quizVariantSelectionPage")} 
                buttonText="GENERATE NEW REPORT"
              />
              <div className="dashboard__header__welcomebanner__image">
                <img
                  src="/images/QT_Reports.svg"
                  alt="welcome banner"
                  width="400px"
                  height="300px"
                />
              </div>
            </div>
          </>
        :activePage === "quizVariantSelectionPage"?
          <div className='report'>
            <TitleComponent 
              title={`What report would \n you like to generate?`}
              handleOnClick={() => setActivePage("welcomePage")}
            />
            <p className='report__subtitle'>Select one of the following to continue to generate reports</p>
            <div className='report__button'>
              <FillButtonComponent
                displayText="GENERATE COURSE REPORT"
                handleClick={handleQuizVariantSelection("course")}
              />
            </div>
            <p className='report__subtitle report__or'>OR</p> 
            <div className='report__button'>
              <FillButtonComponent
                displayText="GENERATE QUIZ REPORT"
                handleClick={handleQuizVariantSelection("quiz")}
              />
            </div>
            <p className='report__subtitle report__or'>OR</p>           
            <div className='report__button'>
              <FillButtonComponent
                displayText="GENERATE QTEST REPORT"
                handleClick={handleQuizVariantSelection("qtest")}
              />
            </div>
          </div>
        :activePage === "reportSelectionQuizPage"?
          <div className='report'>
            {reportList.length == 0?
            <>
              <TitleComponent 
                title={"No "+(selectedQuizVariant=="qtest"?"QTest":capitalizeString(selectedQuizVariant))+" Exists"}
                handleOnClick={() => {
                  setSelectedQuizId("")
                  setSelectedQuizVariant("")
                  setActivePage("quizVariantSelectionPage")
                }}
              />
              <p className='report__subtitle'>Create one to download report</p>
            </>:<>
              <TitleComponent 
                title={"Select " + (selectedQuizVariant=="qtest"?"QTest":capitalizeString(selectedQuizVariant))}
                handleOnClick={() => {
                  setSelectedQuizId("")
                  setSelectedQuizVariant("")
                  setActivePage("quizVariantSelectionPage")
                }}
              />
              <p className='report__subtitle'>Select a {selectedQuizVariant
              } to generate its report</p>
              <div className="report__grid">
                <DisplayGridComponent childComponentList={reportList} />
              </div>
              <div className="report__proceedButton">
                  <FillButtonComponent displayText={"PROCEED"} isDisabled={selectedQuizId===""} 
                    handleClick={() => setActivePage("reportTypeSelectionPage")}
                  />
              </div>
            </>}
          </div>
        :activePage === "reportTypeSelectionPage"?
          <div className='report'>
            <TitleComponent 
              title="Select Type of Report"
              handleOnClick={() => {                
                setSelectedReportTypes([])
                setActivePage("reportSelectionQuizPage")
                setGoBack(true)
              }}
            />
            <p className='report__subtitle'>Select one or more of the following to download detailed reports </p>
            <div style={{display:"flex", flexDirection:"column", justifyContent:"flex-start", alignContent: "center", height:"100%"}}>
              <div className="report__reportType">
                {reportTypes.map((type) => (
                  <div className="report__reportType__button" id={type} onClick={handleReportTypeSelection} key={type}>
                    {type}
                  </div>
                ))}
              </div>
            </div>
            <div className="report__proceedButton">
                <FillButtonComponent displayText={"PROCEED"} isDisabled={selectedReportTypes.length === 0} 
                  handleClick={handleGenerateReport}
                />
            </div>
          </div>
        :<></>
        }
      </div>
    </div>    
  )
}

export default ReportsComponent