import "./SaveQuizComponent.scss";
import React, { useContext, useEffect, useState } from "react";
import CustomModalWithTitleComponent from "../../../shared/customModalComponent/CustomModalWithTitleComponent";
import InputComponent from "../../../shared/inputComponent/InputComponent";
import FillButtonComponent from "../../../shared/fillButtonComponent/FillButtonComponent";
import axios from "axios";
import { BEURL } from "../../../api/BaseUrl";
import apiEndpoints from "../../../api/EndPoints";
import Context from "../../../context/Context";
import { useLocation, useNavigate } from "react-router-dom";
import MultiSelectChipComponent from "../../../shared/multiSelectChipComponent/MultiSelectChipComponent";

const Member = ({ email, removeQuizEditor }) => {
  return (
    <div className="Member">
      <p className="Member__email">{email}</p>
      <p className="Member__button" id={email} onClick={removeQuizEditor}>
        Remove
      </p>
    </div>
  );
};

const SaveQuizComponent = ({ setOpenSaveModal, openSaveModal }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [openShareQuiz, setOpenShareQuiz] = useState(false);
  const [openAssignQuiz, setOpenAssignQuiz] = useState(false);
  const [editAccessList, setEditAccessList] = useState([]);
  const [assignedTeamsList, setAssignedTeamsList] = useState([]);
  const [organizationTeamsList, setOrganizationTeamsList] = useState([
    "1",
    "2",
    "3",
  ]);
  const [publicCheckFlag, setPublicCheckFlag] = useState(false);
  const {
    setLoadingDisplayFlag,
    setNotificationType,
    setNotificationMessage,
    setNotificationDisplayFlag,
  } = useContext(Context);

  const setAlert = (type, message) => {
    setNotificationType(type);
    setNotificationMessage(message);
    setNotificationDisplayFlag(true);
  };

  useEffect(() => {
    if (assignedTeamsList.length > 0 && !!publicCheckFlag) {
      //if teams are assigned to a public quiz
      setAlert("info", "Quiz made private to  assigned teams");
      setPublicCheckFlag(false);
    }
  }, [assignedTeamsList]);

  useEffect(() => {
    if (openAssignQuiz) {
      setLoadingDisplayFlag(true);
      axios
        .post(BEURL + apiEndpoints.getAssignedTeams, {
          quizId: location.pathname.split("/")[2],
        })
        .then((res) => {
          const teamNames = JSON.parse(res.data.teams).map(({ teamName }) => teamName);
          // if (teamNames.includes("Public Quizzes")) {
          if (res.data.quizAccessStatus=="Public") {
            setPublicCheckFlag(true);
            setAssignedTeamsList([]);
          } else {
            setAssignedTeamsList(teamNames);
          }
        })
        .catch((error) => setAlert("error", error.response.data))
        .finally(() => setLoadingDisplayFlag(false));
      axios
        .get(BEURL + apiEndpoints.getOrganizationTeams)
        .then((res) => setOrganizationTeamsList(res.data))
        .catch((error) => setAlert("error", error.response.data));
    }
  }, [openAssignQuiz]);

  useEffect(() => {
    if (openShareQuiz) {
      setLoadingDisplayFlag(true);
      axios
        .post(BEURL + apiEndpoints.getQuizEditors, {
          quizId: location.pathname.split("/")[2],
        })
        .then((res) => setEditAccessList(res.data))
        .catch((error) => setAlert("error", error.response.data))
        .finally(() => setLoadingDisplayFlag(false));
    }
  }, [openShareQuiz]);

  const saveQuiz = () => {
    const url = BEURL + apiEndpoints.changeQuizStatus;
    let quizid = location.pathname.split("/")[2];
    let data = {
      quizId: quizid,
      status: "published",
    };
    axios
      .post(url, JSON.stringify(data))
      .then((res) => {
        setAlert("success", "Quiz Published");
      })
      .catch((error) => setAlert("error", error.response.data))
      .finally(() => {
        setOpenAssignQuiz(false);
        setLoadingDisplayFlag(false);
      });
  };

  const addEmail = (e) => {
    if (e.key == "Enter") {
      console.log("Added");
      const email = e.target.value.trim();
      if (editAccessList.includes(email)) {
        setAlert("info", "This email already has access to edit this quiz");
      } else addQuizEditor(e, email);
    }
  };

  const addQuizEditor = (element, email) => {
    const url = BEURL + apiEndpoints.addQuizEditor;
    const data = {
      email: email,
      quizId: location.pathname.split("/")[2],
    };
    setLoadingDisplayFlag(true);
    axios
      .post(url, data)
      .then((res) => {
        setAlert("success", `Edit access has been provided to ${email}`);
        setEditAccessList(res.data);
        element.target.value = "";
      })
      .catch((error) => setAlert("error", error.response.data))
      .finally(() => setLoadingDisplayFlag(false));
  };

  const removeQuizEditor = (e) => {
    const email = e.target.id;
    const url = BEURL + apiEndpoints.removeQuizEditor;
    const data = {
      email: email,
      quizId: location.pathname.split("/")[2],
    };
    setLoadingDisplayFlag(true);
    axios
      .post(url, data)
      .then((res) => {
        setAlert("success", `Edit access has been revoked for ${email}`);
        setEditAccessList(res.data);
        e.target.value = "";
      })
      .catch((error) => setAlert("error", error.response.data))
      .finally(() => setLoadingDisplayFlag(false));
  };

  const handleOpenAssignQuiz = () => {
    const email_input = document.getElementById("ShareQuiz_emailInput");
    if (!!email_input && email_input.value.trim() != "") {
      let e = { key: "Enter", target: { value: email_input.value.trim() } };
      addEmail(e);
    }
    setOpenSaveModal(false);
    setOpenShareQuiz(false);
    setOpenAssignQuiz(true);
  };

  const handleAssignQuiz = () => {
    setLoadingDisplayFlag(true);
    let data = {};
    if (publicCheckFlag) {
      data = {
        quizId: location.pathname.split("/")[2],
        teamIds: ["public"],
        quizAccessStatus: "public",
      };
    } else {
      data = {
        quizId: location.pathname.split("/")[2],
        teamIds: assignedTeamsList.map(
          (teamName) => organizationTeamsList[teamName]
        ),
        quizAccessStatus: "private",
      };
    }
    axios
      .post(BEURL + apiEndpoints.assignTeamToQuiz, data)
      .then(() => {
        setAlert("info", "Publishing the quiz, please wait");
        saveQuiz();
      })
      .catch((error) => {
        setLoadingDisplayFlag(false);
        setAlert("error", error.response.data);
      });
  };

  const handleMakeQuizPublic = (e) => {
    if (e.target.checked == true) {
      setPublicCheckFlag(true);
      setAlert("info", "Making a quiz public will allow everyone to play it!");
    } else {
      setPublicCheckFlag(false);
    }
  };

  return (
    <div>
      {
        <>
          <CustomModalWithTitleComponent
            open={openSaveModal}
            handleClose={() => setOpenSaveModal(false)}
            bgColor="#0097D4"
            title="Quiz Edit Access"
          >
            <div className="ShareQuiz">
              <p className="ShareQuiz__subtitle">
                Manage who can edit this quiz. Assign quiz for others to play.
              </p>
              <div className="SaveQuiz__btncontainer">
                <FillButtonComponent
                  // displayText="ASSIGN QUIZ"
                  displayText="PUBLISH QUIZ"
                  handleClick={handleOpenAssignQuiz}
                />
                <FillButtonComponent
                  style={{ border: "2px solid white" }}
                  bgColor="transparent"
                  displayText="PLAY DEMO QUIZ"
                  handleClick={() => {
                    setOpenSaveModal(false)
                    navigate("/previewquiz");
                  }}
                />
              </div>
              <p
                className="SaveQuiz__link"
                onClick={() => {
                  setOpenSaveModal(false);
                  setOpenShareQuiz(true);
                }}
              >
                MANAGE EDIT ACCESS
              </p>
            </div>
          </CustomModalWithTitleComponent>

          <CustomModalWithTitleComponent
            open={openShareQuiz}
            handleClose={() => setOpenShareQuiz(false)}
            bgColor="#0097D4"
            title="Share Quiz"
          >
            <div className="ShareQuiz">
              <p className="ShareQuiz__subtitle">
                By sharing this, members of your team can edit this quiz.
              </p>
              <div className="ShareQuiz__container">
                <div className="ShareQuiz__container__left">
                  <InputComponent
                    ClassName="ShareQuiz__container__left__input"
                    Label="Add members via email"
                    Placeholder="Enter registered email id"
                    OnKeyDown={addEmail}
                    InputId="ShareQuiz_emailInput"
                  />
                </div>
                <div className="ShareQuiz__container__right">
                  <p className="ShareQuiz__container__heading">
                    Members who have access
                  </p>
                  <div>
                    {editAccessList.map((email, idx) => (
                      <Member
                        email={email}
                        key={idx}
                        removeQuizEditor={removeQuizEditor}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="ShareQuiz__proceedbtn">
                <FillButtonComponent
                  displayText="PROCEED"
                  handleClick={handleOpenAssignQuiz}
                />
              </div>
            </div>
          </CustomModalWithTitleComponent>

          <CustomModalWithTitleComponent
            open={openAssignQuiz}
            handleClose={() => setOpenAssignQuiz(false)}
            bgColor="#0097D4"
            // title="Assign and Publish Quiz"
            title="Publish Quiz"
          >
            <div className="ShareQuiz">
              <p className="ShareQuiz__subtitle">
                {/* You can chose to selectively assign this quiz or make it
                available to everyone */}
                Public quizzes can be accessed by anyone to play.
              </p>
              {/* <div className="ShareQuiz__container">
                <div className="ShareQuiz__container__left">
                  <MultiSelectChipComponent
                    selectedOptions={assignedTeamsList}
                    allSelectOptions={Object.entries(organizationTeamsList).map(
                      ([teamName, teamId]) => teamName
                    )}
                    setSelectedOption={setAssignedTeamsList}
                  />
                  <InputComponent
                    ClassName="AssignQuiz__checkbox"
                    Label="Make quiz public"
                    Type="checkbox"
                    OnChange={(e) => handleMakeQuizPublic(e)}
                    Checked={publicCheckFlag}
                  />
                </div>
              </div> */}
              <div className="ShareQuiz__container">
                <div className="ShareQuiz__container__publicOnly">
                  <InputComponent
                    ClassName="AssignQuiz__checkbox"
                    Label="Make quiz public"
                    Type="checkbox"
                    OnChange={(e) => handleMakeQuizPublic(e)}
                    Checked={publicCheckFlag}
                  />
                </div>
              </div>
              <div className="ShareQuiz__proceedbtn">
                <FillButtonComponent
                  // displayText="PUBLISH"
                  displayText="PUBLISH QUIZ"
                  handleClick={handleAssignQuiz}
                />
              </div>
            </div>
          </CustomModalWithTitleComponent>
        </>
      }
    </div>
  );
};

export default SaveQuizComponent;
