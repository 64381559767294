import "./UserAuthComponent.scss"
import React from 'react'

const UserAuthComponent = ({children}) => {
  
  return (
    <div className='userauth'>
      <div className='userauth__image'>
        <img src="/images/SignIn_QT.png" alt="QT" />        
      </div>
      <div className='userauth__details'>
        {children}
      </div>
    </div>
  )
}
export default UserAuthComponent