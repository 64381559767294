import React, { useContext, useEffect, useState } from "react";
import DisplayGridComponent from "../../components/displayGridComponent/DisplayGridComponent";
import HorizonatalTileComponent from "../../components/horizonatalTileComponent/HorizonatalTileComponent";
import TitleComponent from "../titleComponent/TitleComponent";
import "./viewAllTilesComponent.scss";
import PlayQuizModalComponent from "../../shared/playQuizModalComponent/PlayQuizModalComponent";
// For the menu functionality
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Context from "../../context/Context";
import { FEURL, BEURL, WSURL } from "../../api/BaseUrl";
import apiEndpoints from "../../api/EndPoints";
import { copyToClipboardAsync } from "../../shared/constants";
import ScheduleQtestComponent from "../../components/quizSelectionComponent/scheduleQtestComponent/ScheduleQtestComponent";
import ChoicePopupComponent from "../choicePopupComponent/ChoicePopupComponent";
import WarningComponent from "../warningComponent/WarningComponent";
import { dateTimeOptions } from "../../components/tilesCarouselComponent/TilesCarouselComponent";
import SearchComponent from "../searchComponent/searchComponent";

const ViewAllTilesComponent = ({ 
  title, 
  subtitle,
  tilesData, 
  splitData=false,
  enableMenuOptions = false,
  handleOnClickBack = () => {},
  addQuizToList = (quiz, addToList) => {},
}) => {

  const navigate = useNavigate();

  const {
    socket,
    setSocket,
    setRoomId,
    setHost,
    setNoOfCorrectAns,
    setQuestionData,
    setLeaderboardData,
    setAnswerData,
    setPodiumPage,
    setDisconnect,
    setContinueQuizzing,
    setNotificationType,
    setNotificationMessage,
    setNotificationDisplayFlag,
    setLoadingDisplayFlag,
  } = useContext(Context);

  // For tiles initialization. useEffect to filter tiles by searchterm.
  const [tiles, setTiles] = useState();
  const [playQuizModalOpen, setPlayQuizModalOpen] = useState(false);

  // Since tilesData prop is changing after render using useEffect for temp fix. Have to fix this bug.
  useEffect(() => {
      setTiles(tilesData)
  }, [tilesData])

  // For search term and function to handle change
  const handleSearchTermChange = (event) => {
    let searchTermTofilter= event.target.value
    // Setting tiles from tilesData based on searchTermToFilter. Ignore prevTiles
    setTiles((prevTiles)=>{
      return (
                tilesData.filter((tile) => { // filter if search term is in tile name or description
                  return tile.tileName.toLowerCase().includes(searchTermTofilter.toLowerCase()) ||
                            tile.tileDescription.toLowerCase().includes(searchTermTofilter.toLowerCase())
                })
      )
    })
  }

  const [rescheduleQtestModalOpen, setRescheduleQtestModalOpen] = useState(false);
  const [deleteQuizModalOpen, setDeleteQuizModalOpen] = useState(false);
  const [curTileId, setCurTileId] = useState(null);

  // Read current location
  const location = useLocation();

  // Play quiz from dashboard
  const [showPlayQuizPopup, setShowPlayQuizPopup] = useState(false)
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [scheduleQtestModalOpen, setScheduleQtestModalOpen] = useState(false);

  // Function and useEffect for reset to initiate quiz play from this component
  const handleUserExit = (e) => {
    socket.send(
      JSON.stringify({
        action: "userExit",
        playerId: sessionStorage.getItem("sessionId"), //take from local storage later; it's static now
      })
    );
  };

  useEffect(() => {
    // Close and initialize socket, roomId on home page everytime.
    if (socket !== null) {
      handleUserExit();
      socket.close();
      setSocket(null);
      setRoomId(null);
      setHost(null);
      setNoOfCorrectAns(0);
      setQuestionData({});
      setLeaderboardData({});
      setAnswerData({});
      setPodiumPage(false);
      setDisconnect(false);
      setContinueQuizzing(false);
      sessionStorage.removeItem("isHost");
    }
  }, []);

  const handleQtestCreate = (startDate, endDate, showAnswer, showResult, additionalFields) => {
    setShowPlayQuizPopup(false)
    setLoadingDisplayFlag(true);
    const url = BEURL + apiEndpoints.createQtest;
    const data = {
      qtestType: "RM",
      categories: selectedCategories,
      showAnswer: showAnswer,
      showResult: showResult,
      startDate: startDate,
      endDate: endDate,
      additionalFields: additionalFields,
    };
    axios
      .post(url, data)
      .then((res) => {
        if(res.data.status=="Success"){
          setAlert("success", "QTest created successfully");
          navigate("/shareqtest/" + res.data.qtestId, { state: { backToPath: location.pathname } });
        }
        else if(res.data.status=="Error"){
          setAlert("info", res.data.errorMessage);
        }
        else{
          setAlert("error", "Something went wrong. Kindly try again later.");
        }
      })
      .catch((error) => {
        setAlert("error", error.response.data);
      })
      .finally(()=>setLoadingDisplayFlag(false));
  }

  // setAlert to modify notifications before display
  const setAlert = (type, message) => {
    setNotificationType(type);
    setNotificationMessage(message);
    setNotificationDisplayFlag(true);
  };

  const removeCurTileInView = () => {
    const removedTileIndex = tiles.findIndex(tile=>tile.id==curTileId)
    tiles.splice(removedTileIndex, 1)
  }

  const handleDeleteQuiz = () => {
    setLoadingDisplayFlag(true);
    // const url = BEURL + apiEndpoints.deleteQuiz;
    // const data = {
    //   quizId: curTileId
    // }
    const url = BEURL + apiEndpoints.changeQuizStatus;
    const data = {
      quizId: curTileId,
      status: "inactive",
    }
    axios
      .post(url, data)
      .then((res) => {
        if (res.status == 200) {
          setLoadingDisplayFlag(false);
          setAlert("success", res.data);
          removeCurTileInView()
        } else {
          setLoadingDisplayFlag(false);
          setAlert("error", res.data);
        }
      })
      .catch((error) => {
        setLoadingDisplayFlag(false);
        setAlert("error", error.response.data);
      });
  }

  const changeCurTileDatesInView = ({startOn, endOn}) => { // This data is not changed in the parent component. Could be a bug.
    const changedTileIndex = tiles.findIndex(tile=>tile.id==curTileId)
    tiles[changedTileIndex].date1 = new Date(startOn).toLocaleDateString("en-US", dateTimeOptions)
    tiles[changedTileIndex].date2 = new Date(endOn).toLocaleDateString("en-US", dateTimeOptions)
  }

  const handleQtestReschedule = (startDate, endDate, showAnswer, showResult, additionalFields) => {
    setLoadingDisplayFlag(true);
    const url = BEURL + apiEndpoints.rescheduleQtest;
    const data = {
      qtestId: curTileId,
      showAnswer: showAnswer,
      showResult: showResult,
      startDate: startDate,
      endDate: endDate,
      additionalFields: additionalFields,
    };
    axios
      .post(url, data)
      .then((res) => {
        if (res.status == 200) {
          setLoadingDisplayFlag(false);
          setAlert("success", res.data);
          changeCurTileDatesInView({startOn: startDate, endOn:endDate})
        } else {
          setLoadingDisplayFlag(false);
          setAlert("error", res.data);
        }
      })
      .catch((error) => {
        setLoadingDisplayFlag(false);
        setAlert("error", error.response.data);
      });
  };

  const changeQuizStatusInView = ({quizId, addToList, status}) => {
    // Identify the tile and change its status
    const changedTileIndex = tiles.findIndex(tile=>tile.id==quizId)
    if(status=="published"){
      tiles[changedTileIndex].tileType = "PUBQuiz"
    }
    else if(status=="draft"){
      tiles[changedTileIndex].tileType = "DRFQuiz"
    }
    if(splitData){ // Remove tile only if the data is split
      // Remove item from current tile list. Splice returns removed items in an array
      var removedQuizList = tiles.splice(changedTileIndex, 1)
      // Add item to other tile list in the parent component
      addQuizToList(removedQuizList, addToList)
    }
  }

  const changeQuizStatus = ({quizId, status, addToList}) => {
    setLoadingDisplayFlag(true);
    const url = BEURL + apiEndpoints.changeQuizStatus;
    const data = {
      quizId: quizId,
      status: status
    };
    axios
      .post(url, data)
      .then((res) => {
        if (res.status == 200) {
          setLoadingDisplayFlag(false);
          setAlert("success", res.data);
          changeQuizStatusInView({quizId: quizId, addToList: addToList, status: status})
        } else {
          setLoadingDisplayFlag(false);
          setAlert("error", res.data);
        }
      })
      .catch((error) => {
        setLoadingDisplayFlag(false);
        setAlert("error", error.response.data);
      });
  };

  const getMenuItems = ({ menuType, enableMenuOptions, tileId }) => {
    let menuitems = [];
    if (!enableMenuOptions) {
      return menuitems;
    }
    if (menuType == "QTest") {
      menuitems = [
        {
          text: "Copy Link",
          handleClick: (qtestId = tileId) => {
            // Create and copy QTest link
            copyToClipboardAsync(`${FEURL}/qtest/${qtestId}`).then(
              () => {
                // Promise Fullfilled
                setAlert("info", "Link copied!");
              },
              (error) => {
                // Promise Rejected
                setAlert("error", `Error: ${error}`);
              }
            );
          },
        },
        {
          text: "Reschedule",
          handleClick: (qtestId = tileId) => {
            // PopUp schedule QTest Component
            setCurTileId(qtestId);
            setRescheduleQtestModalOpen(true);
          },
        },
      ];
    } else if (menuType == "DRFQuiz") {
      menuitems = [
        {
          text: "Edit",
          handleClick: (quizId = tileId) => {
            window.open(FEURL + "/createquiz/" + quizId);
          },
        },{
          text: "Play",
          handleClick: (quizId = tileId) => {
            // Play Quiz or QTest
            setSelectedCategories([quizId])
            setShowPlayQuizPopup(true)
          },
        },
        {
          text: "Publish",
          handleClick: (quizId = tileId) => {
            // Move draft quiz to published
            changeQuizStatus({quizId: quizId, status: "published", addToList: "published"})
          },
        },
        {
          text: "Delete",
          handleClick: (quizId = tileId) => {
            // Delete quiz code
            setCurTileId(quizId)
            setDeleteQuizModalOpen(true)
          },
        },
      ];
    } else if (menuType == "PUBQuiz") {
      menuitems = [
        {
          text: "Edit",
          handleClick: (quizId = tileId) => {
            window.open(FEURL + "/createquiz/" + quizId);
          },
        },{
          text: "Play",
          handleClick: (quizId = tileId) => {
            // Play Quiz or QTest
            setSelectedCategories([quizId])
            setShowPlayQuizPopup(true)
          },
        },{
          text: "Move to Draft",
          handleClick: (quizId = tileId) => {
            // Move published quiz to draft
            changeQuizStatus({quizId: quizId, status: "draft", addToList: "drafts"})
          },
        },
        {
          text: "Delete",
          handleClick: (quizId = tileId) => {
            // Delete quiz code
            setCurTileId(quizId)
            setDeleteQuizModalOpen(true)
          },
        },
      ];
    }
    return menuitems;
  };

  const allTilesView = tiles?.map((tile) => {
    return (
      <HorizonatalTileComponent
        key={tile.id}
        quizId={tile.id}
        quizType={tile.quizType}
        imageName={tile.tileImage}
        title={tile.tileName}
        subtitle1={tile.tileDescription}
        subtitle2={
          tile.tileType == "QTest"
            ? "Start On: " + tile.date1
            : "Created On: " + tile.date1
        }
        subtitle3={tile.tileType == "QTest" ? "End On: " + tile.date2 : ""}
        isMenuAvailable={enableMenuOptions}
        menuItems={getMenuItems({
          menuType: tile.tileType,
          enableMenuOptions: enableMenuOptions,
          tileId: tile.id,
        })}
      />
    );
  });

  return (
    <>
      <div className="report">
        <TitleComponent title={title} handleOnClick={handleOnClickBack} />
        <p className='report__subtitle'>{subtitle}</p>
        <SearchComponent handleSearchTermChange={handleSearchTermChange} />
        <div className="report__grid">
          <DisplayGridComponent childComponentList={allTilesView} />
        </div>
      </div>
      <ScheduleQtestComponent
        modalOpen={rescheduleQtestModalOpen}
        setModalOpen={setRescheduleQtestModalOpen}
        handleClick={handleQtestReschedule}
        showAdditionalFieldsInput={false}
        title="Reschedule QTest"
        btnText="Reschedule"
      />
      <ScheduleQtestComponent modalOpen={scheduleQtestModalOpen} setModalOpen={setScheduleQtestModalOpen} handleClick={handleQtestCreate} />
      <PlayQuizModalComponent
        modalOpen={playQuizModalOpen} 
        setModalOpen={setPlayQuizModalOpen} 
        readyMadeQuizFlag={true}
        selectedCategories={selectedCategories}
      />
      <ChoicePopupComponent
        showPopup={showPlayQuizPopup}
        popupTitle={"Select Quiz Mode"}
        // popupDescription={"Choose the mode in which you want to play the quiz"}
        popupDescription={""}
        choiceOneText={"Play Quiz"}
        choiceOneDescription="All participants play the quiz at the same time!"
        choiceTwoText={"Schedule Quiz"}
        choiceTwoDescription="Schedule a period within which participants can take the quiz anytime."
        handleChoiceOne={()=>{setPlayQuizModalOpen(true)}}
        handleChoiceTwo={()=>{setScheduleQtestModalOpen(true)}}
        handleClosePopup={()=>{setShowPlayQuizPopup(false)}}
      />
      <WarningComponent 
        buttonText="Confirm"
        modalOpen={deleteQuizModalOpen}
        setModalOpen={setDeleteQuizModalOpen}
        handleClick={handleDeleteQuiz}
      >
        <h1>
            Are you sure you want to
            <br />
            Delete the Quiz?
        </h1>
        <p>
            <span style={{fontWeight: 600, color: "red", fontStyle: "italic"}}>This action is irreversible.</span> Click on the button below if you wish to proceed.
        </p>
      </WarningComponent>
    </>
  );
};

export default ViewAllTilesComponent;
