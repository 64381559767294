import "./SignInComponent.scss"
import {VscEye, VscEyeClosed} from "react-icons/vsc"
import {FcGoogle} from "react-icons/fc"
import React, { useContext, useState } from 'react'
import FillButtonComponent from "../../shared/fillButtonComponent/FillButtonComponent"
import InputComponent from "../../shared/inputComponent/InputComponent"
import {BEURL} from "../../api/BaseUrl"
import apiEndpoints from "../../api/EndPoints"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import Context from "../../context/Context"
import Cookies from 'js-cookie'
import jwt from 'jsonwebtoken'
import UserAuthComponent from "../../shared/userAuthComponent/UserAuthComponent"
import { generateRandom } from "../../shared/constants"

const SignInComponent = ({redirectFlag=false}) => {
  const [inputType, setInputType] = useState("password")
  const [password, setPassword] = useState("")
  const [email_usrnm, setEmail_usrnm] = useState("")
  const { setNotificationType, setNotificationMessage, setNotificationDisplayFlag, setLoadingDisplayFlag, setIsLoggedIn, setUserName, setUserType } = useContext(Context)
  const navigate = useNavigate()

  const setAlert = (type, message) => {
    setNotificationType(type)
    setNotificationMessage(message)
    setNotificationDisplayFlag(true)
  }

  const handleGoogleClick = () => {
    window.location = `https://${process.env.REACT_APP_COGNITO_DOMAIN}/oauth2/authorize?identity_provider=Google&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&response_type=CODE&scope=${process.env.REACT_APP_COGNITO_SCOPE}&redirect_uri=${process.env.REACT_APP_COGNITO_REDIRECT}`
  }

  const handleFacebookClick = () => {
    window.location = `https://${process.env.REACT_APP_COGNITO_DOMAIN}/oauth2/authorize?identity_provider=Facebook&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&response_type=CODE&scope=${process.env.REACT_APP_COGNITO_SCOPE}&redirect_uri=${process.env.REACT_APP_COGNITO_REDIRECT}`
  }
  
  const handleClick = () => {
    if (email_usrnm.length < 1)
      return setAlert("error", "Enter a valid username/email")
    if (password.length < 8)
      return setAlert("error", "Incorrect password")
    const url = BEURL + apiEndpoints.signIn
    const data = {
      "username": email_usrnm.trim(),
      "password": password
    }
    setLoadingDisplayFlag(true)
    axios.post(
      url,
      data
    ).then((res) => {
      setIsLoggedIn(true)
      localStorage.setItem('isLoggedIn', true)
      let qqIdToken = Cookies.get('qq_id_token')   
      let decodedToken = jwt.decode(qqIdToken);
      setUserName(decodedToken['cognito:username']) 
      sessionStorage.setItem('sessionId', decodedToken['sub']) 
      setUserType(res.data.userType) 
      // Set basic details of the user while signing 
      const avtr =  res.data["picture"] || `${process.env.REACT_APP_S3_PROFILE_IMAGE_URL}avatar${generateRandom(0,5)}.svg`;
      
      sessionStorage.setItem("name", res.data["userName"]);
      sessionStorage.setItem("email", res.data["email"]);
      sessionStorage.setItem("ageGroup", res.data["ageGroup"]);
      sessionStorage.setItem("avatar", avtr);
      
      // Set Cookie with basic details
      Cookies.set("name", res.data["userName"])
      Cookies.set("email", res.data["email"])
      Cookies.set("ageGroup", res.data["ageGroup"])
      Cookies.set("avatar", avtr)


      redirectFlag?navigate(-1):navigate("/")   
    }
    ).catch((error) => {
      if(error.response.status==302){
        navigate(`/confirmemail/${email_usrnm}`, {state:{ message: error.response.data}})
      }
      else{
        setAlert("error", error.response.data)
      }
    }
    ).finally(() => setLoadingDisplayFlag(false))
  }

  const handleOnPasswordRevealClick = () => {
    setInputType((prevValue) => {
      if (prevValue === "password")
        return "text"
      else  
        return "password"
    })
  }
  return (
    <UserAuthComponent>
      <p className="signin__title">Log In</p>
      <p className="signin__subtitle">Get started on your Quest to Quriosity</p>
      <div className="signin__social">
        {/* <p>Or sign in using</p> */}
        <div className="signin__social__button" onClick={handleGoogleClick}>
          <FcGoogle />
          <p>GOOGLE</p>
        </div>
        
        <div className="signin__social__button" onClick={handleFacebookClick} >
            <img src="/images/facebook_logo_icon.png"/>
            <p>FACEBOOK</p>
        </div>
      </div>    
      <p>OR</p>
      <InputComponent ClassName="signin__input" 
        Label="USERNAME OR EMAIL" Type="text" Value={email_usrnm} OnChange={(e) => setEmail_usrnm(e.target.value)}
      />
      <InputComponent ClassName="signin__input" 
        Type={inputType} Label="PASSWORD" Value={password} OnChange={(e) => setPassword(e.target.value)}
      >
        {inputType==="password" ? 
          <VscEye className="signin__icon" onClick={handleOnPasswordRevealClick}/> :
          <VscEyeClosed className="signin__icon" onClick={handleOnPasswordRevealClick}/>
        }
        <p align="right" className="signin__forgotPassword">
          <span className="yellow" onClick={() => navigate('/forgotPassword')}>Forgot password?</span>
        </p>
      </InputComponent>
      <div className="signin__savebtn">
        <FillButtonComponent 
          displayText={"Log In"} 
          handleClick={handleClick}
        />
      </div>
      <div className="oneline">
        <p 
          className="signin__subtitle">By Logging in you agree to our&nbsp;
          <span className="signin__subtitle yellow" onClick={() => navigate('/termsandconditions')}>terms and condtions.</span>
        </p>
      </div>  
      <div className="oneline">
        <p 
          className="signin__subtitle">Don't have an account?&nbsp;
          <span className="signin__subtitle yellow" onClick={() => navigate('/signup')}>Sign up here</span>
        </p>
      </div>    
      
    </UserAuthComponent>
  )
}
export default SignInComponent