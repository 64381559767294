import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./index.scss";
import MainComponent from './components/mainComponent/MainComponent';
import Provider from './context/Provider';
import AlertComponent from "./shared/alertComponent/AlertComponent";
import LoadingComponent from "./shared/loadingComponent/LoadingComponent";
import "./axios-config"

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider>
        <AlertComponent />
        <LoadingComponent />
        <MainComponent />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
