import { useTheme } from "@mui/material/styles";
import { Grid, useMediaQuery } from "@mui/material"
import { Box } from "@mui/system"
import axios from "axios"
import React, { useContext, useEffect, useState } from "react"
import { BEURL, S3URL } from "../../api/BaseUrl"
import apiEndpoints from "../../api/EndPoints"
import Context from "../../context/Context"
import QuizTile from "../../shared/quizTile/QuizTile";
import LoginPromptComponent from "../../shared/loginPromptComponent/LoginPromptComponent"
import PricingPromptComponent from "../../shared/pricingPromptComponent/PricingPromptComponent";

const DisplayQuizzesComponent = ({ quiztype, selectedCategories, setSelectedCategories, searchTermTofilter, categoriesToFilter }) => {
    const [filteredReadymadeQuizzes, setFilteredReadymadeQuizzes] = useState([])
    const [allowedIds, setAllowedIds] = useState([])
    const [allowedCategories, setAllowedCategories] = useState([])
    const [lockedCategories, setLockedCategories] = useState([])
    const [allCategories, setAllCategories] = useState([])
    const [openLoginPrompt, setOpenLoginPrompt] = useState(false)
    const [openPricingPrompt, setOpenPremiumPrompt] = useState(false)
    const { isLoggedIn, setNotificationType, setNotificationMessage, setNotificationDisplayFlag, setLoadingDisplayFlag, loadingDisplayFlag } = useContext(Context)

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTablet = useMediaQuery(theme.breakpoints.down("md"));
    const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
    const disabledTooltipText = isLoggedIn ? "Purchase our premium pack to play more than 5000 quizzes with QShala!" : "Please sign up/log in to access more quizzes"

    const setAlert = (type, message) => {
        setNotificationType(type)
        setNotificationMessage(message)
        setNotificationDisplayFlag(true)
    }

    useEffect(() => {
        setLoadingDisplayFlag(true)
        let endpoint = quiztype == "readymade" ? apiEndpoints.readymade : apiEndpoints.categories;
        let [all,allowed] = quiztype == "readymade" ? ["allQuizzes", "allowedQuizzes"] : ["allCategories", "allowedCategories"];
        axios
            .post(BEURL + endpoint,
                JSON.stringify({
                    ageGroup: sessionStorage.getItem("ageGroup")
                }))
            .then((res) => {
                setAllCategories(res.data[all])
                setAllowedIds(new Set(res.data[allowed])) //convert array to set
            })
            .catch((error) => {
                setAlert("error", String(error.message))
            })
    }, [])

    useEffect(() => {
        if (allCategories.length && allowedIds.size  ) { // Stop loading display when categories are fetched
            let id_attr = quiztype == "readymade" ? "quizId" : "categoryId";
            let allowed = []
            let locked = []  
            let categories = []
            //If readymade type use filteredReadymadeQuizzes to get locked and allowed lists
            if (quiztype == "readymade" && (filteredReadymadeQuizzes.length || searchTermTofilter.length || categoriesToFilter.length) ){
                // readymadetype and user has filtered or searched
                categories = filteredReadymadeQuizzes
            }else
                categories = allCategories

            categories.forEach((item) => {
                if (allowedIds.has(item[id_attr])){
                    allowed.push(item)
                }else{
                    locked.push(item)
                }
            })
            setAllowedCategories(allowed)
            setLockedCategories(locked)
            setLoadingDisplayFlag(false)
        }
    }, [allCategories, allowedIds, filteredReadymadeQuizzes])

    const handleCategorySelection = (id) => {
        let temp = [...selectedCategories];
        if (selectedCategories.includes(id)) {
            let index = temp.indexOf(id);
            temp.splice(index, 1);
        } else {
            temp.push(id);
        }
        setSelectedCategories([...new Set(temp)]);
    }

    const handleReadyMadeSelection = (id) => {
        setSelectedCategories([id]);
    }

    useEffect(() => {
        handleReadymadeQuizFilter()
    },[allCategories, searchTermTofilter, categoriesToFilter])

    const handleLockedOnClick = () => isLoggedIn ? setOpenPremiumPrompt(true) : setOpenLoginPrompt(true)
    const handleCloseLoginPrompt = () => setOpenLoginPrompt(false)
    const handleClosePricingPrompt = () => setOpenPremiumPrompt(false)

    const handleReadymadeQuizFilter = () => { // Filter readymade quizzes based on search term and categories
        if(quiztype=="readymade"){
            setSelectedCategories([]); //Clear all selected quizzes before filtering
            let tempReadymadeQuizzes;
        
            //filter for category
            if(categoriesToFilter.length){
                tempReadymadeQuizzes = allCategories.filter((quiz)=>{ //Filter quizzes that has
                    return JSON.parse(quiz.categoryIdList).some((categoryId) => { // any categoryid
                        return categoriesToFilter.some((cat) => { // in the filtered categories
                            return cat.categoryId == categoryId
                        }); 
                    })
                })
            }
            else {
                tempReadymadeQuizzes = Object.create(allCategories)
            }
            
            //filter by search term
            if(searchTermTofilter){
                tempReadymadeQuizzes = Object.create(
                    tempReadymadeQuizzes.filter((quiz) => { // filter if search term is in quiz name or description
                        return quiz.quizName.toLowerCase().includes(searchTermTofilter.toLowerCase()) ||
                                quiz.quizDescription.toLowerCase().includes(searchTermTofilter.toLowerCase())
                    })
                )
                setFilteredReadymadeQuizzes(tempReadymadeQuizzes)
            }
            else{
                setFilteredReadymadeQuizzes(tempReadymadeQuizzes)
            }
        }
    }

    return (
        <>
            <PricingPromptComponent open={openPricingPrompt} handleClose={handleClosePricingPrompt} />
            <LoginPromptComponent open={openLoginPrompt} handleClose={handleCloseLoginPrompt} />
            <Box sx={{ width: isMobile ? "85%" : "90%", margin: "2rem 0" }}>
                {
                    quiztype == "readymade" ? ( //Ready Made
                        (allowedCategories.length || lockedCategories.length)? 
                            <Grid
                                container
                                rowSpacing={isMobile ? "1.875rem" : "2.8438rem"}
                                columnSpacing="1.25rem"
                                direction="row"
                                justifyContent="center"
                            >
                                {allowedCategories?.map((category) => (
                                    <Grid item xs={6} sm={4} key={category.quizId}>
                                        <QuizTile
                                            id={category.quizId}
                                            label={category.quizName}
                                            tooltipText={category.quizDescription}
                                            backgroundImage={`url(${S3URL + "readymade_banner/" + category.quizImage})`}
                                            selectedCategories={selectedCategories}
                                            handleOnClick={() => handleReadyMadeSelection(category.quizId)}
                                        />
                                    </Grid>
                                ))}
                                {lockedCategories?.map((category) => (
                                    <Grid item xs={6} sm={4} key={category.quizId}>
                                        <QuizTile
                                            id={category.quizId}
                                            label={category.quizName}
                                            tooltipText={disabledTooltipText}
                                            backgroundImage={`url(${S3URL + "readymade_banner/" + category.quizImage})`}
                                            selectedCategories={selectedCategories}
                                            isLocked={true}
                                            isPremium={isLoggedIn}
                                            handleOnClick={handleLockedOnClick}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        : !loadingDisplayFlag && <div style={{color:"#ffffff"}}>No quizzes. We will upload soon.</div>
                    ) : ( //Interest Based
                        <Grid
                            container
                            rowSpacing={isMobile ? "1.875rem" : "2.8438rem"}
                            columnSpacing="1.25rem"
                            direction="row"
                            justifyContent="center"
                        >
                            {allowedCategories?.map((category) => (
                                <Grid item xs={6} sm={4} key={category.categoryId}>
                                    <QuizTile
                                        id={category.categoryId}
                                        label={category.categoryName}
                                        tooltipText={`Get questions from ${category.categoryName}`}
                                        backgroundImage={`url("/images/categories/${category.categoryName}.jfif")`}
                                        selectedCategories={selectedCategories}
                                        handleOnClick={() => handleCategorySelection(category.categoryId)}
                                    />
                                </Grid>
                            ))}
                            {lockedCategories?.map((category) => (
                                <Grid item xs={6} sm={4} key={category.categoryId}>
                                    <QuizTile
                                        id={category.categoryId}
                                        label={category.categoryName}
                                        tooltipText={disabledTooltipText}
                                        backgroundImage={`url("/images/categories/${category.categoryName}.jfif")`}
                                        selectedCategories={selectedCategories}
                                        isLocked={true}
                                        isPremium={isLoggedIn}
                                        handleOnClick={handleLockedOnClick}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    )
                }
            </Box >
        </>
    )
}

export default DisplayQuizzesComponent;