import React, { useContext, useEffect, useState } from "react";
import "./HomeComponent.scss";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { HomeQTImage } from "./HomeQTImage";
import NavMenu from "../../shared/navMenuComponent/NavMenuComponent";
import Context from "../../context/Context";
import { Divider, Grid } from "@mui/material";
import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomModalComponent from "../../shared/customModalComponent/CustomModalComponent";
import axios from "axios";
import { BEURL, WSURL } from "../../api/BaseUrl";
import apiEndpoints from "../../api/EndPoints";
import "../selectMultiplayerComponent/SelectMultiplayerComponent.scss";
import LoginPromptComponent from "../../shared/loginPromptComponent/LoginPromptComponent";

const useStyles = makeStyles((theme) => ({
  divider: {
    background: "white",
    flex: "0.45",
    height: "0.5px",
  },
}));

const HomeComponent = () => {
  const navigate = useNavigate();
  const {
    socket,
    setHost,
    setSocket,
    setRoomId,
    setQuestionData,
    setLeaderboardData,
    setAnswerData,
    setPodiumPage,
    setDisconnect,
    setContinueQuizzing,
    setLoadingDisplayFlag,
    setNoOfCorrectAns,
    isLoggedIn,
    setNotificationType,
    setNotificationMessage,
    setNotificationDisplayFlag,
  } = useContext(Context);

  const [openLogin, setOpenLogin] = useState(false);
  const handleOpenLogin = () => setOpenLogin(true);
  const handleCloseLogin = () => setOpenLogin(false);

  const [openQuizModal, setOpenQuizModal] = useState(false);
  const handleOpenQuizModal = () => setOpenQuizModal(true);
  const handleCloseQuizModal = () => setOpenQuizModal(false);

  const [submitFlag, setSubmitFlag] = useState(false);
  const handleRoomCodeChange = (e) => {
    e.target.value ? setSubmitFlag(true) : setSubmitFlag(false);
  };

  // setAlert to modify notifications before display
  const setAlert = (type, message) => {
    setNotificationType(type);
    setNotificationMessage(message);
    setNotificationDisplayFlag(true);
  };

  const handleJoinRoomSubmit = () => {
    sessionStorage.removeItem("isHost");
    var inputRoomId = document.getElementById("roomCode").value;
    inputRoomId = inputRoomId.toLowerCase();
    if (inputRoomId?.length === 6) {
      axios
        .post(
          BEURL + apiEndpoints.checkRoomId,
          JSON.stringify({
            roomId: inputRoomId,
          })
        )
        .then((res) => {
          if (res.data.roomIdExistsFlag) {
            setHost(false);
            setRoomId(inputRoomId);
            if (sessionStorage.getItem("name") && 
                sessionStorage.getItem("email") && 
                sessionStorage.getItem("ageGroup") && 
                sessionStorage.getItem("avatar")
              ) {
              if (socket == null) {
                const ws_socket = new WebSocket(`${WSURL}/ws/quiz/${inputRoomId}/`);
                setSocket(ws_socket);
              }
              navigate("/waitingroom")
            } else {
              navigate("/joinroom", { state: { roomId: inputRoomId } });
            }
          } else {
            setAlert("error", "Invalid Room ID.");
          }
        })
        .catch((error) => {
          setAlert("error", String(error.message));
        });
    } else {
      setAlert("error", "Room ID must be 6 characters in length.");
    }
  };

  const handleHostQuiz = () => {
    setHost(true);
    sessionStorage.setItem("isHost", true);
    if (isLoggedIn) {
      //use details if loggedIn and profile is complete
      setLoadingDisplayFlag(true);
      axios
        .get(BEURL + apiEndpoints.getProfile)
        .then((res) => {
          let username = res.data["username"] || "";
          let email = res.data["email"] || "";
          let age = res.data["custom:age_group"] || "";
          let avatar = res.data["picture"] || "";
          let sessionId = sessionStorage.getItem("sessionId");
          if (![username, age, avatar].includes("")) {
            //if either of them are empty get player details
            //set items in storage
            sessionStorage.setItem("ageGroup", age);
            sessionStorage.setItem("name", username);
            sessionStorage.setItem("email", email);
            sessionStorage.setItem("avatar", avatar);
            setLoadingDisplayFlag(false);
            //navigate to quiz selection
            navigate("/quizselection");
          } else {
            navigate("/playerdetails");
          }
        })
        .catch((err) => console.log(err));
    } //proceed to next page and get details
    else navigate("/playerdetails");
  };

  const handleUserExit = (e) => {
    socket.send(
      JSON.stringify({
        action: "userExit",
        playerId: sessionStorage.getItem("sessionId"), //take from local storage later; it's static now
      })
    );
  };

  const handleProfileClick = () => {
    if (isLoggedIn) {
      navigate("/profile");
    } else {
      handleOpenLogin();
    }
  };

  useEffect(() => {
    // Close and initialize socket, roomId on home page everytime.
    if (socket !== null) {
      handleUserExit();
      socket.close();
      setSocket(null);
      setRoomId(null);
      setHost(null);
      setNoOfCorrectAns(0);
      setQuestionData({});
      setLeaderboardData({});
      setAnswerData({});
      setPodiumPage(false);
      setDisconnect(false);
      setContinueQuizzing(false);
      sessionStorage.removeItem("isHost");
      if(!isLoggedIn){ // Clear session only if not logged in. Logged in user session is cleared only during log out
        sessionStorage.clear();
      }
    }
  }, []);

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const styleBg = {
    backgroundColor: "#fdb913",
    color: "#fff",
    fontFamily: "CaustenEB",
    fontSize: "clamp(0.875rem, 2.36vw , 2.425rem)",
    letterSpacing: isTablet ? "0.5px" : "1px",
    lineHeight: isTablet ? "0.875rem" : "2.125rem",
    textAlign: "center",
    borderRadius: "10px",
    paddingTop: "clamp(1.35rem,9.8% , 2.3rem)",
    paddingRight: "clamp(2.3rem,9.8% , 2.8rem)",
    paddingBottom: "clamp(1.35rem,9.8% , 2.3rem)",
    paddingLeft: "clamp(2.3rem,9.8% , 2.8rem)",
    width: isMobile ? "9rem" : "100%",
  };

  const styleGray = {
    backgroundColor: "#939393",
    color: "#fff",
    fontFamily: "CaustenEB",
    fontSize: "clamp(0.875rem, 2.36vw , 2.125rem)",
    letterSpacing: isTablet ? "0.5px" : "1px",
    lineHeight: isTablet ? "0.875rem" : "2.125rem",
    textAlign: "center",
    borderRadius: "10px",
    cursor: "not-allowed",
    paddingTop: "clamp(1.25rem,9.3% , 2rem)",
    paddingRight: "clamp(2rem,9.3% , 2.5rem)",
    paddingBottom: "clamp(1.25rem,9.3% , 2rem)",
    paddingLeft: "clamp(2rem,9.3% , 2.5rem)",
    width: isMobile ? "9rem" : "100%",
  };

  const classes = useStyles();

  return (
    <div className="home">
      <NavMenu />
      <div className="home__left">
        <div className="home__all-set-to-quiz-today prevent-select">
          All set to Quiz today?
        </div>
        {isTablet && (
          <div
            style={{ width: "120%", paddingLeft: "15%" }}
            className="home__mobile_qtImage"
          >
            <HomeQTImage />
          </div>
        )}
        <div className="home__playButtons">
          <Grid
            container
            spacing={isTablet ? 3 : 5}
            justifyContent={isMobile ? "center" : isTablet ? "center" : "left"}
          >
            <Grid item sm={6}>
              <Button
                className="home__buttons"
                onClick={handleOpenQuizModal}
                style={styleBg}
              >
                Play Quiz
              </Button>
            </Grid>
            {isTablet || ( //Only for tablets and up
              <Grid item sm={6}>
                <Button
                  className="home__buttons"
                  onClick={()=>{
                    navigate("/dashboard")
                  }}
                  style={styleBg}
                >
                  Create Quiz
                </Button>
              </Grid>
            )}
            {
              <Grid item sm={6}>
                <Button
                  className="home__buttons"
                  style={styleBg}
                  onClick={handleProfileClick}
                >
                  PROFILE
                </Button>
              </Grid>
            }
            {isTablet || ( //Only for tablets and up
              <Grid item sm={6}>
                <Button
                  className="home__buttons"
                  onClick={()=>{
                    navigate("/reports")
                  }}
                  style={styleBg}
                >
                  Reports
                </Button>
              </Grid>
            )}
            {/* {isTablet || <Grid item sm={6}>
              <Button disableRipple={false} className="home__buttons" style={styleGray}>LEADERBOARD</Button>
            </Grid>} */}
          </Grid>
        </div>
      </div>
      {isTablet || (
        <div className="home__right">
          <img src="/images/QT.svg" />
        </div>
      )}
      <LoginPromptComponent open={openLogin} handleClose={handleCloseLogin} />
      <CustomModalComponent
        open={openQuizModal}
        handleClose={handleCloseQuizModal}
      >
        <div className="selectMultiplayer__modal">
          <h1>PLAY QUIZ</h1>
          <p>
            To start QUIZing solo or with friends, you can either host a new
            quiz or join one hosted by another user. Select one to proceed.
          </p>
          <div className="selectMultiplayer__modalButtonContainer">
            <Button
              variant="contained"
              style={{ backgroundColor: "#30b2e7" }}
              className="selectMultiplayer__modalButton"
              onClick={handleHostQuiz}
            >
              START A QUIZ
            </Button>
          </div>
          <div className="selectMultiplayer__dividerContainer">
            <Divider classes={{ root: classes.divider }} />
            <p>OR</p>
            <Divider classes={{ root: classes.divider }} />
          </div>
          <p>
            Please enter the room code (the last 6 characters of the link) to
            enter the quiz.
          </p>
          <div className="selectMultiplayer__modalJoin">
            <input
              id="roomCode"
              spellCheck="false"
              placeholder="ENTER ROOM CODE HERE"
              onChange={handleRoomCodeChange}
            />
            <Button
              variant="contained"
              className="selectMultiplayer__modalButton"
              style={{ backgroundColor: submitFlag ? "#30b2e7" : "grey" }}
              disabled={!submitFlag}
              onClick={handleJoinRoomSubmit}
            >
              JOIN QUIZ
            </Button>
          </div>
        </div>
      </CustomModalComponent>
    </div>
  );
};

export default HomeComponent;
