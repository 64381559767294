import "./ConfirmEmailComponent.scss"
import React, { useContext, useEffect, useState } from 'react'
import FillButtonComponent from '../../shared/fillButtonComponent/FillButtonComponent'
import InputComponent from '../../shared/inputComponent/InputComponent'
import UserAuthComponent from '../../shared/userAuthComponent/UserAuthComponent'
import { BEURL } from "../../api/BaseUrl"
import apiEndpoints from "../../api/EndPoints"
import axios from "axios"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import Context from "../../context/Context"
import { checkTypes, checkValidation } from "../../utils/inputCheckValidation"

export const ConfirmEmailComponent = () => {
  const [code, setCode] = useState("")
  const [usrnm, setUsrnm] = useState("")
  const [cnfFlag, setCnfFlag] = useState(false)
  const {setNotificationType, setNotificationMessage, setNotificationDisplayFlag, setLoadingDisplayFlag} = useContext(Context)
  const navigate = useNavigate()

  const setAlert = (type, message) => {
    setNotificationType(type)
    setNotificationMessage(message)
    setNotificationDisplayFlag(true)
  }

  const { username } = useParams();
  const { state } = useLocation();
  
  useEffect(()=>{
    if(username){
        setUsrnm(username)
    }
    else{
      navigate('/home')
    }
  },[])

  useEffect(()=>{
    if(state){
        const { message } = state;
        setAlert("info",message)
        window.history.replaceState({}, document.title) // Clears state and prevents state caching
    }
  },[])

  const handleResendCode = () => {   
    setLoadingDisplayFlag(true)
    const url = BEURL + apiEndpoints.resendConfirmationCode
    const data = {
      "username":usrnm
    }
    axios.post(
      url, data
    ).then((res) => setAlert("success", res.data)                    
    ).catch((error) => setAlert("error",error.response.data)
    ).finally(() => setLoadingDisplayFlag(false))
  }

  const handleConfirmEmail = () => {
    var errorMessage = checkValidation([
      {value: code, fieldName:"Code", checksFor: [checkTypes.NOTEMPTY, checkTypes.NOOFCHARS], charLength: 6, charLengthCheckType: "EQUAL"},
    ])
    if(errorMessage){
      setAlert("error",errorMessage)
    }
    else{
      setLoadingDisplayFlag(true)
      const url = BEURL + apiEndpoints.confirmSignUp
      const data = {
        "username":usrnm,
        "confirmation_code":code
      }
      axios.post(
        url, data
      ).then((res) => {
          if(res.status==200){
            setCnfFlag(true)
            setTimeout(() => {
              navigate("/signin")
            }, 5000);
          }
          else{
            setAlert("error","Something went wrong. Kindly try again later.")
          }          
        } 
      ).catch((error) => setAlert("error",error.response.data)
      ).finally(() => setLoadingDisplayFlag(false))
    }   
  }

  return (
    <UserAuthComponent>
        { cnfFlag?
            <>
              <p className="cnfEmail__title moremargin">Verification Successful</p>
              <p className="cnfEmail__subtitle">Your email was verified successfully. You will be redirected to Sign In shortly.</p>
            </>:
            <>
              <p className="cnfEmail__title moremargin">Email Confirmation</p>
              <p className="cnfEmail__subtitle">Enter the confirmation code sent to your email.</p>
              <p className="cnfEmail__subtitlesmall">Please check your spam folder and don't forget to mark us <b>not spam</b></p>
              <InputComponent ClassName="cnfEmail__input" 
                Type="text" Value={code} OnChange={(e) => setCode(e.target.value)} >
                  <p align="right" className="cnfEmail__resendcode">
                    <span className="yellow" onClick={handleResendCode}>Resend Code</span>
                  </p>
              </InputComponent>
              <div className="cnfEmail__savebtn">
                <FillButtonComponent 
                  displayText={"Confirm Email"} 
                  handleClick={handleConfirmEmail}
                />
              </div>
            </>
        }
           
    </UserAuthComponent>
  )
}
