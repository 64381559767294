import { RadioGroup } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Context from "../../../context/Context";
import CustomModalWithTitleComponent from "../../../shared/customModalComponent/CustomModalWithTitleComponent";
import FillButtonComponent from "../../../shared/fillButtonComponent/FillButtonComponent";
import InputComponent from "../../../shared/inputComponent/InputComponent";
import MediaUploadComponent from "../mediaUploadComponent/MediaUploadComponent";
import DashboardDrawerComponent from "../../dashboardDrawerComponent/DashboardDrawerComponent";
import { RadioBtn } from "../../editProfileComponent/EditProfileComponent";
import { BEURL, S3URL } from "../../../api/BaseUrl";
import apiEndpoints from "../../../api/EndPoints";

const QuizSettingsComponent = ({
  isOpen,
  handleCloseModel,
}) => {
  const {
    editQuizDetails,
    setEditQuizDetails,
    setNotificationType,
    setNotificationMessage,
    setNotificationDisplayFlag,
    setLoadingDisplayFlag,
  } = useContext(Context);

  // setAlert to modify notifications before display
  const setAlert = (type, message) => {
    setNotificationType(type);
    setNotificationMessage(message);
    setNotificationDisplayFlag(true);
  };

  const [quizName, setQuizName] = useState("");
  const [quizDescription, setQuizDescription] = useState("");
  const [ageGroup, setAgeGroup] = useState("");
  const [quizImage, setQuizImage] = useState("");

  useEffect(() => {
    if (editQuizDetails) {
      editQuizDetails.quizName && setQuizName(editQuizDetails.quizName);
      editQuizDetails.quizDescription &&
        setQuizDescription(editQuizDetails.quizDescription);
      editQuizDetails.ageGroup && setAgeGroup(editQuizDetails.ageGroup);
      editQuizDetails.quizImage &&
        setQuizImage(S3URL + "readymade_banner/" + editQuizDetails.quizImage);
    }
  }, [editQuizDetails]);

  const handleEditQuiz = () => {
    setLoadingDisplayFlag(true);
    const url = BEURL + apiEndpoints.editQuizDetails;
    let data = {
      "quizId": editQuizDetails.quizId,
      quizName,
      quizDescription,
      ageGroup,
      quizImage,
    }
    axios
      .post(url, JSON.stringify(data))
      .then((res) => {
        if (res.status == 200) {
          setAlert("success", res.data);
          // Edit Quiz Details in View
          setEditQuizDetails((prevValue)=>{
            prevValue["quizName"] = quizName
            prevValue["quizDescription"] = quizDescription
            prevValue["ageGroup"] = ageGroup
            return prevValue
          })
        } else {
          setAlert("error", res.data);
        }
      })
      .catch((error) => alert(error.response.data))
      .finally(() => {
        handleCloseModel()
        setLoadingDisplayFlag(false);
      });
  }

  return (
    <div className="quizSettingsComponent">
      <CustomModalWithTitleComponent
        title="Quiz Settings"
        open={isOpen}
        handleClose={() => {
          handleCloseModel();
        }}
        bgColor="#4B9710"
      >
        <div className="quizDetails">
          <div className="quizDetails__details">
            <InputComponent
              Type="text"
              Label="Quiz Name (Upto 100 Letters)"
              Value={quizName}
              Maxlength={100}
              OnChange={(e) => setQuizName(e.target.value)}
            />
            <InputComponent
              Type="text"
              Label="Description (Upto 200 Letters)"
              Value={quizDescription}
              Maxlength={200}
              OnChange={(e) => setQuizDescription(e.target.value)}
            />
          </div>
          <div className="quizDetails__bannerUpload">
            <MediaUploadComponent
              imageURL={quizImage}
              setImageURL={setQuizImage}
              placeholderimage="/images/quizItem.svg"
            />
          </div>
        </div>
        <div className="column attachtags">
          <label className="editprofile__content__label">Age</label>
          <RadioGroup
            aria-label="quiz"
            name="quiz"
            value={ageGroup}
            onChange={(e) => setAgeGroup(e.target.value)}
            row
            sx={{ rowGap: "0.5rem", columnGap: "%" }}
          >
            <RadioBtn label={"6-8 YEARS"} value={"6 to 8"} />
            <RadioBtn label={"9-17 YEARS"} value={"9 to 14"} />
            <RadioBtn label={"18+ YEARS"} value={"18+"} />
          </RadioGroup>
          <div className="quizDetails__proceedbtn">
            <FillButtonComponent
              displayText="Edit Quiz"
              handleClick={handleEditQuiz}
            />
          </div>
        </div>
      </CustomModalWithTitleComponent>
    </div>
  );
};

export default QuizSettingsComponent;
