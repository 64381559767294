import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import React, { useContext } from "react";
import QuizMediaComponent from "../quizMediaComponent/quizMediaComponent/QuizMediaComponent";
import "./QuizBasicComponent.scss";
import { S3URL } from "../../../api/BaseUrl";
import Context from "../../../context/Context";
import QuizFIBComponent from "../quizFIBComponent/QuizFIBComponent";
import QuizMCQOptionsComponent from "../quizMCQOptionsComponent/QuizMCQOptionsComponent"
import QuizInfoComponent from "../quizInfoComponent/QuizInfoComponent";

function QuizBasicComponent({
  question,
  selectedAnswer,
  setSelectedAnswer,
  questionCount,
  noOfQuestions,
}) {
  const { socket, roomId, leaderBoardOpen, mobileLeaderBoardOpen, timerProgress} = useContext(Context);
  const { questionId, questionTitle, quizType, questionType, imageName, answerChoices, answerBlanks, quizId } = question || {};

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "lg"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));


  const handleSubmitAnswer = (e) => {
    setSelectedAnswer(e.currentTarget.value);
    socket.send(
      JSON.stringify({
        action: "submitAnswer",
        playerId: sessionStorage.getItem("sessionId"), //take from local storage later; it's static now
        questionId: questionId,
        quizId: quizId,
        roomId: roomId, //roomId, // take from context
        timeTaken: timerProgress,
        playerAnswer: e.currentTarget.value,
        questionType:questionType
      })
    );
  };

  const handleFIBAnswer = (answer) => {
    setSelectedAnswer(answer);
    socket.send(
      JSON.stringify({
        action: "submitAnswer",
        playerId: sessionStorage.getItem("sessionId"), //take from local storage later; it's static now
        questionId: questionId,
        quizId: quizId,
        roomId: roomId, //roomId, // take from context
        timeTaken: timerProgress,
        playerAnswer: answer,
        questionType:questionType
      })
    );
  };

  const answerChoicesList = eval(answerChoices);

  return (
    <div className={leaderBoardOpen ? "quizBasic quizBasic__leaderboardOpen" : "quizBasic"}>

      {isMobile && mobileLeaderBoardOpen || (
        <>
          <div className="quizBasic__question prevent-select">
            <p>{questionTitle}</p>
            <p>{questionCount} / {noOfQuestions}</p>
          </div>
            
          <div className="quizBasic__image">
            <QuizMediaComponent
              // mediaUrl = {S3URL + "test/sampleVideo.mp4"}
              mediaUrl={S3URL + (quizType == "Interest Based" ? "generated/" : "readymade/") + imageName}
            />
          </div>
          
          {
            ["FIB", "CNT"].includes(questionType) ?
            <div className="quizBasic__fib" >
              <QuizFIBComponent 
                handleFIBAnswer={handleFIBAnswer}
                answerBlanks = {answerBlanks}
              />
            </div>
            : questionType =="NQS" ?
              <QuizInfoComponent /> 
            :
              <QuizMCQOptionsComponent
                answerChoicesList={answerChoicesList}
                handleSubmitAnswer={handleSubmitAnswer}
                selectedAnswer={selectedAnswer}
              /> 
          }        
        </>
      )}
    </div>
  );
}

export default QuizBasicComponent;
