import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import "./FillButtonComponent.scss"

const FillButtonComponent = ({ displayText, isDisabled, bgColor="#FDB913", bgHoverColor = "#DB9B00", handleClick, style }) => {
  return (

    <Button
      disabled={isDisabled? true : false}
      onClick={handleClick}
      sx={{
        textTransform: "unset !important",
        color: "white",
        borderColor: "#FDB913",
        padding: "0.8rem 2rem",
        fontFamily: "CaustenR",
        fontWeight: "700",
        letterSpacing: "3px",
        borderRadius: "10px",
        display: "block",
        backgroundColor: `${bgColor}`,
        '&:hover': {
          backgroundColor: `${bgHoverColor}`
        },
        fontSize: "clamp(0.75rem,3vh,1.75rem)",
        "@media screen and (max-width: 1200px)": {
          fontSize: "1rem",
        },
        "@media screen and (max-width: 600px)": {
          fontSize: "0.875rem",
        }
      }}
      style={{ ...style }}
    >
      {displayText}
    </Button>
  );
}

export default FillButtonComponent;