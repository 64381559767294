import React, { useContext, useState } from "react";
import "./QuizFooterComponent.scss";
import { BsTrophy } from "react-icons/bs";
import { BiExit } from "react-icons/bi";
import { FaUserFriends } from 'react-icons/fa';
import Tooltip from '@mui/material/Tooltip';
import Context from "../../../context/Context";
import TimerComponent from "../timerComponent/TimerComponent";
import ExitWarning from "../../../shared/exitWarning/ExitWarning";
import InviteModalComponent from "../../../shared/inviteModalComponent/InviteModalComponent";

const QuizFooterComponent = () => {
 
  const { quizPage, leaderBoardOpen, setLeaderBoardOpen, maxTime, timerProgress, questionData } = useContext(Context);
  const [modalOpen, setModalOpen] = useState(false);
  const [inviteModalOpen, setInviteModalOpen] = useState(false);


  const timerToDisplay = () => {
    const displayValue = maxTime - timerProgress < 0 ? 0 : maxTime - timerProgress;

    //Spliting time to be displayed in minutes and seconds
    const mins = Math.floor(displayValue / 60);
    const secs = displayValue % 60;

    //Adding 0 to all values below 10. Ex: 07, 02 etc..
    if (secs >= 10) {
      return (
        mins + ":" + secs
      );
    } else {
      return (
        mins + ":0" + secs
      );
    }
  };


  return (

    <div className="quizfooter" style={{ width: leaderBoardOpen ? 'calc(100vw - 380px)' : "100vw" }}>
      {(quizPage==="answer" || quizPage==="hostModeStats") || questionData.questionType ==="NQS" || <TimerComponent maximumTime={maxTime} progress={timerProgress} />}
      <div className="quizfooter__iconContainer">
        <div className="quizfooter__left">
          <Tooltip title="Exit Quiz" arrow>
            <div
              className="quizfooter__trophy quizfooter__trophyleft"
              onClick={() => setModalOpen(true)}
              style={{ backgroundColor: modalOpen ? "white" : "#30B2E7" }}
            >
              <BiExit
                size={42}
                style={{ color: modalOpen ? "#30B2E7" : "white" }}
              />              
            </div>
          </Tooltip>
          <Tooltip title="Invite Friends" arrow>
            <div
              className="quizfooter__trophy quizfooter__trophyleft"
              onClick={() => setInviteModalOpen(true)}
              style={{ backgroundColor: inviteModalOpen ? "white" : "#30B2E7" }}
            >              
              <FaUserFriends
                size={42}
                style={{ color: inviteModalOpen ? "#30B2E7" : "white" }}
              />              
            </div>
          </Tooltip>
        </div>

        <InviteModalComponent open={inviteModalOpen} handleClose={() => setInviteModalOpen(false)} displayText={"Invite Friends Via"}/>
        <ExitWarning modalOpen={modalOpen} setModalOpen={setModalOpen} />

        {(quizPage==="answer" || quizPage==="hostModeStats") || questionData.questionType ==="NQS" || <p>{timerToDisplay()}</p>}
        <Tooltip title={leaderBoardOpen? "Hide Leaderboard": "Show Leaderboard"} arrow>
          <div
            className="quizfooter__trophy"
            onClick={() => setLeaderBoardOpen(!leaderBoardOpen)}
            style={{ backgroundColor: leaderBoardOpen ? "white" : "#30B2E7" }}
          >         
            <BsTrophy size={42} style={{ color: leaderBoardOpen ? "#30B2E7" : "white" }} />          
          </div>
        </Tooltip>
      </div>
    </div>
  );
};

export default QuizFooterComponent;
