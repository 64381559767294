import React, { useContext } from 'react';
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import './MobileTimerComponent';
import Context from '../../../context/Context';

function CircularProgressWithLabel(props) {
  let secondsLeft, minutesLeft, fontTextSize
  if(!props.displayValue){
    secondsLeft = props.maxTime - props.timePassed 
    minutesLeft = ~~(secondsLeft / 60) //bitwise operation to get whole number
    secondsLeft = ("0" + secondsLeft % 60).slice(-2) //prepend 0 if single digit
  }
  
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <div>
      <CircularProgress        
        variant="determinate"
        value={props.value}
        style={{ color: `${props.dialColor}`,}}
        size={props.dialSize}
      /></div>
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <p className="mobileTimer__time" style={{color:"white", fontFamily:"CaustenEB", fontSize:props.textSize}}>
          {/* {`${Math.round(props.maxTime - props.value / (100 / props.maxTime))}`} */}
          {props.displayValue? props.displayValue:`${minutesLeft}:${secondsLeft}`}
        </p>
      </Box>
    </Box>
  );
}

//Added displayValue prop and default is null
const MobileTimerComponent = ({maximumTime, progress, displayValue=null, dialSize="3rem", textSize="1rem", dialColor="#FDB913"}) => {
  const maxTime = isNaN(maximumTime) ? 30: maximumTime; //if  max time is  not passed default  to 30s
  const normalize = (value) => (value * 100) / maxTime;

  return (
    <CircularProgressWithLabel dialSize={dialSize} textSize={textSize} displayValue={displayValue} dialColor={dialColor} value={normalize(progress>maxTime?maxTime:progress)} maxTime={maxTime} timePassed= {progress>maxTime?maxTime:progress} /> //prevent timer from running past maxTime
  );
}

export default MobileTimerComponent