import React from "react";
import "./searchComponent.scss";
import { InputAdornment, TextField } from "@mui/material";
import { FaSearch } from "react-icons/fa";

const SearchComponent = ({handleSearchTermChange}) => {
  return (
    <div className="search">
      <TextField
        type='text'
        onInput={handleSearchTermChange}
        // label="Search"
        placeholder="Search"
        variant="filled"
        size="small"
        InputProps={{
            endAdornment: (
                <InputAdornment position="end">
                    <FaSearch />
                </InputAdornment>
            ),
        }}
        sx={{
            "& .MuiFormControl-root": {
              width: "100%",
            },
            // "& .MuiFormLabel-root": {
            //   fontFamily: "CaustenR",
            //   fontSize: "smaller",
            //   color: "lightgrey",
            //   top: "-0.5rem",
            // },
            // "& .MuiFormLabel-root.Mui-focused": {
            //     color: "#888888",
            //     top: "0",
            // },
            "& .MuiFilledInput-root": {
              backgroundColor: "#75B543",
              color: "#FFF",
              fontFamily: "CaustenR",
              fontSize: "medium",
              height: "2rem",
              "@media only screen and (max-width: 600px)": {
                fontSize: "small",
              }
            },
            "& .MuiFilledInput-root.Mui-focused": {
              backgroundColor: "rgb(232, 241, 250)",
              color: "#000",
            },
            "& .MuiFilledInput-root:hover": {
              backgroundColor: "#E0E8FF",
              color: "#000",
              // Reset on touch devices, it doesn't add specificity
              "@media (hover: none)": {
                backgroundColor: "#007fb3",
                color: "#FFF",
              }
            },
            "& .MuiFilledInput-input": {
                paddingTop: "0.25rem",
            },
            '& .MuiFilledInput-underline:after': {
                borderBottomColor: '#007FB3',
                borderWidth: "0.15rem",
            },
        }}
      />
    </div>
  );
};

export default SearchComponent;
