import "./QtestComponent.scss";
import React, { useContext, useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import FillButtonComponent from "../../shared/fillButtonComponent/FillButtonComponent";
import LoginPromptComponent from "../../shared/loginPromptComponent/LoginPromptComponent";
import Context from "../../context/Context";
import axios from "axios";
import { BEURL } from "../../api/BaseUrl";
import apiEndpoints from "../../api/EndPoints";
import QtestMobileFixedHeader from "./qtestMobileFixedHeader/QtestMobileFixedHeader";
import QtestQuestionTypeSelectComponent from "./qtestQuestionTypeSelectComponent/QtestQuestionTypeSelectComponent";
import QtestFooterComponent from "./qtestFooterComponent/QtestFooterComponent";
import { getQtestTypeCode } from "../../shared/constants";
import QtestAnswerComponent from "./qtestAnswerComponent/QtestAnswerComponent";

const QtestComponent = () => {
  
  const navigate = useNavigate();
  const { state } = useLocation();
  const { isLoggedInUser, qtestId, playerName, playerId, additionalFields } = state;
  const [questionData, setQuestionData] = useState(null);
  const [answerData, setAnswerData] = useState(null);
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [showAnswerPage, setShowAnswerPage] = useState(false);
  const [questTimerProgress, setQuestTimerProgress] = useState(0);
  const [questiontimerInterval, setQuestionTimerInterval] = useState(null);
  const [showScoreFlag, setShowScoreFlag] = useState(null);

  // For results
  const [rank, setRank] = useState(0);
  const [totalScore, setTotalScore] = useState(0);
  const [showResult, setShowResult] = useState(false);

  const {
    setNotificationType,
    setNotificationMessage,
    setNotificationDisplayFlag,
    setLoadingDisplayFlag,
  } = useContext(Context);

  // setAlert to modify notifications before display
  const setAlert = (type, message) => {
    setNotificationType(type);
    setNotificationMessage(message);
    setNotificationDisplayFlag(true);
  };

  //Login prompt and redirect to Sign-in page
  const [openLogin, setOpenLogin] = useState(false);
  const handleOpenLogin = () => setOpenLogin(true);
  const handleCloseLogin = () => {
    setOpenLogin(false);
    navigate("/signin");
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const resetForNextQuestion = () => {
    setQuestionData(null);
    setAnswerData(null);
    setSelectedAnswer("");
    setShowAnswerPage(false);
    setQuestTimerProgress(0);
    questiontimerInterval && clearInterval(questiontimerInterval);
    setQuestionTimerInterval(null);
  }

  // Run timer after questionData is set
  useEffect(() => {
    if(questionData && questionData.questionType != "NQS"){
      const timerInterval = setInterval(() => {
        setQuestTimerProgress((prevProgress) => {
          if (prevProgress >= questionData.timeToAnswer) {
            clearInterval(timerInterval);
            submitAnswer({
              qtestType: questionData.qtestType,
              questionId: questionData.questionId,
              questionType: questionData.questionType,
              playerAnswer: "",
              timeTaken: prevProgress,
            });
          }
          return prevProgress + 1;
        });
      }, 1000);
      setQuestionTimerInterval(timerInterval);
    }

    return (()=>{
      clearInterval(questiontimerInterval);
      setQuestTimerProgress(0)
    })

  }, [questionData]);

  const fetchNextQuestion = () => {
    const url = BEURL + apiEndpoints.attendQtest;
    const data = {
        qtestId: qtestId,
        playerId: playerId,
        playerName: playerName,
        additionalFields: additionalFields,
        isLoginUser: isLoggedInUser
    }
    setLoadingDisplayFlag(true);
    axios
      .post(url, data)
      .then((res) => {
        if (res.data.status == "IP") {
          let question = res.data.question;
          setQuestionData(question);
        } else if (res.data.status == "CP") {
          // Redirect to result page
          if (res.data.showResultFlag) {
            setRank(res.data.results.rank)
            setTotalScore(res.data.results.playerTotalScore)
          }
          setShowScoreFlag(res.data.showResultFlag);
          setShowAnswerPage(true)
          setShowResult(true)
        }
      })
      .catch((error) => setAlert("error", error.response.data))
      .finally(() => setLoadingDisplayFlag(false));
  };

  const submitAnswer = ({
    qtestType,
    questionId,
    playerAnswer,
    questionType,
    timeTaken = (questTimerProgress>questionData.timeToAnswer?questionData.timeToAnswer:questTimerProgress),
  }) => {
    // Clear setInterval for TimerComponent
    clearInterval(questiontimerInterval);
    questionType=="NQS" && (timeTaken = -1) // To identify NQS answers
    const url = BEURL + apiEndpoints.submitQtestAnswer;
    const data = {
        qtestId,
        playerId,
        qtestType: getQtestTypeCode(qtestType),
        questionId,
        timeTaken,
        playerAnswer,
        questionType,
      }
    setLoadingDisplayFlag(true);
    axios
      .post(url, data)
      .then((res) => {
        const showAnswerFlag = res.data.showAnswerFlag;
        if(questionType=="NQS" || showAnswerFlag===false){
          handleNextQuestion(); // handle next question once answer is recorded. No answer page.
        }
        else{ // Show answer page for non NQS questions only
          // Set answer data from response
          setAnswerData(res.data.answercontent);
          setShowAnswerPage(true);
        }
      })
      .catch((error) => setAlert("error", error.response.data))
      .finally(() => setLoadingDisplayFlag(false));
  };

  useEffect(() => {
    fetchNextQuestion();
  }, []);

  useEffect(() => {
    document.getElementById("quizComp_content").scrollTo(0, 0);
  }, [showAnswerPage]);

  const handleNextQuestion = () => {
    // Get next question
    resetForNextQuestion() // Reset before next question
    fetchNextQuestion();
  };

  // Block back and reload button in browser
  const [finishStatus, setfinishStatus] = useState(false);
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (window.confirm("Do you want to leave the game?")) {
        setfinishStatus(true);
        navigate("/");
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
      }
    }
  };
  const onReloadButtonEvent = (e) => {
    e.preventDefault();
    return (e.returnValue =
      "Data will be lost if you leave the page, are you sure?");
  };
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    window.addEventListener("beforeunload", onReloadButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
      window.removeEventListener("beforeunload", onReloadButtonEvent);
    };
  }, []);

  return (
    <>
      <div className="qtestComponent">
        <div
          className="qtestComponent__content"
          id="quizComp_content"
          style={{
            width: "100vw",
          }}
        >
          {!showAnswerPage && isTablet && questionData && (
            <QtestMobileFixedHeader
              questionCount={questionData?.questionNumber}
              noOfQuestions={questionData?.totalNoOfQuestions}
              maxTime={questionData?.timeToAnswer}
              timerProgress={questTimerProgress}
            />
          )}

          {showAnswerPage ? (
            <QtestAnswerComponent
              answer={answerData}
              selectedAnswer={selectedAnswer}
              showResult={showResult}
              rank={rank}
              totalScore={totalScore}
              showResultFlag={showScoreFlag}
            />
          ) : (questionData && (
            <QtestQuestionTypeSelectComponent
              question={questionData}
              setSelectedAnswer={setSelectedAnswer}
              selectedAnswer={selectedAnswer}
              questionCount={questionData?.questionNumber}
              noOfQuestions={questionData?.totalNoOfQuestions}
              submitAnswer={submitAnswer}
            />
          ))}

          {showAnswerPage && !showResult && (
            <div className="qtestComponent__nextBtnFooter">
              <FillButtonComponent
                displayText={
                  questionData?.questionNumber ===
                  questionData?.totalNoOfQuestions
                    ? "End Quiz"
                    : "Next Question"
                }
                handleClick={handleNextQuestion}
              />
            </div>
          )}
        </div>
        {questionData && (isTablet || showAnswerPage || (
          <QtestFooterComponent
            showTimer={questionData.questionType != "NQS"}
            maxTime={questionData?.timeToAnswer}
            timerProgress={questTimerProgress}
          />
        ))}
      </div>
      <LoginPromptComponent
        open={openLogin}
        handleClose={handleCloseLogin}
        redirectFlag={true}
      />
    </>
  );
};

export default QtestComponent;
