import React, { useContext } from "react";
import "./HeaderComponent.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { FiMenu } from "react-icons/fi";
import { CgClose } from "react-icons/cg";
import { useState } from "react";
import Collapse from "@mui/material/Collapse";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import ExitWarning from "../../shared/exitWarning/ExitWarning";
import {
  ExitIcon,
  HelpIcon,
  LogoutIcon,
  SettingsIcon,
  ShareIcon,
} from "../../shared/Icons";
import InviteModalComponent from "../../shared/inviteModalComponent/InviteModalComponent";
import LoginPromptComponent from "../../shared/loginPromptComponent/LoginPromptComponent";
import Tooltip from "@mui/material/Tooltip";
import Context from "../../context/Context";
import { BEURL } from "../../api/BaseUrl";
import apiEndpoints from "../../api/EndPoints";
import axios from "axios";
import SaveQuizComponent from "../createQuizComponents/saveQuizComponent/SaveQuizComponent";
import Cookies from "js-cookie";

const CreateQuizHeaderButtons = ({
  handleSaveQuiz,
  isLoggedIn,
  navigate,
  enablePreview,
}) => {
  const {
    setEditQuestionsList,
    setEditQuizDetails,
    setQuestionData,
  } = useContext(Context);
  return (
  <>
    <div className="header__actions">
      <p
        className="header__actions__buttons header__actions__buttons__three"
        style={{color: "#FFFFFF", borderColor: "#FFFFFF"}}
        onClick={() => {
          setEditQuestionsList([]);
          setEditQuizDetails({});
          setQuestionData({});
          navigate("/dashboard");
        }}
      >
        BACK
      </p>
      <Tooltip
        title={enablePreview ? "" : "Add and save questions to preview"}
        arrow
      >
        <p
          className={`header__actions__buttons ${
            enablePreview ? "header__actions__buttons__three" : ""
          }`}
          style={{
            color: enablePreview ? "#FFFFFF" : "grey",
            borderColor: enablePreview ? "#FFFFFF" : "grey",
          }}
          onClick={() => {
            if (enablePreview) {
              navigate("/previewquiz");
            }
          }}
        >
          PREVIEW QUIZ
        </p>
      </Tooltip>
      <p
        className="header__actions__buttons header__actions__buttons__two"
        onClick={handleSaveQuiz}
      >
        PUBLISH QUIZ
      </p>
    </div>
    <LoginPromptComponent
      open={!isLoggedIn}
      handleClose={() => navigate("/")}
    />
  </>
)};

const PreviewQuizHeaderButtons = ({ editQuizId, isLoggedIn, navigate }) => (
  <>
    <div className="header__actions">
      <p
        className="header__actions__buttons header__actions__buttons__three"
        onClick={() => navigate("/createquiz/" + editQuizId)}
      >
        CLOSE PREVIEW
      </p>
    </div>
    <LoginPromptComponent
      open={!isLoggedIn}
      handleClose={() => navigate("/")}
    />
  </>
);

const DefaultHeaderButtons = ({
  handleLogout,
  handleLogin,
  navigate,
  isLoggedIn,
}) => {
  const { userType } = useContext(Context);
  return (
    <div className="header__actions">
      {/* <h3>HELP</h3> */}
      {isLoggedIn && (
        <h3
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/dashboard")}>
          DASHBOARD
        </h3>
      )}
      <h3 style={{ cursor: "pointer" }} onClick={() => navigate("/pricing")}>
        PRICING
      </h3>
      {userType === 'QShala_Admin' && (
        <h3 style={{ cursor: "pointer" }} onClick={() => navigate("/admin")}>
          ADMIN
        </h3>
      )}
      {isLoggedIn ? (
        <h3 style={{ cursor: "pointer" }} onClick={handleLogout}>
          LOGOUT
        </h3>
      ) : (
        <h3 style={{ cursor: "pointer" }} onClick={handleLogin}>
          LOGIN
        </h3>
      )}
    </div>
  );
};

function HeaderComponent() {
  const {
    isLoggedIn,
    editQuestionsList,
    setLoadingDisplayFlag,
    editQuizDetails,
    userType,
  } = useContext(Context);
  const [open, setOpen] = useState(false);
  const [exitModalOpen, setExitModalOpen] = useState(false);
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const handleClickAway = () => {
    open && setOpen(false);
  };

  const handleLogin = () => navigate("/signin");

  const handleLogout = () => {
    Cookies.remove("name");
    Cookies.remove("email");
    Cookies.remove("ageGroup");
    Cookies.remove("avatar");
    window.location = `https://${process.env.REACT_APP_COGNITO_DOMAIN}/logout?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&response_type=code&scope=${process.env.REACT_APP_COGNITO_SCOPE}&logout_uri=${process.env.REACT_APP_COGNITO_LOGOUT_URI}`;
  };

  const isExitWarningPath = () => {
    if (
      location.pathname === "/quiz" ||
      location.pathname === "/waitingroom" ||
      location.pathname === "/quizselection" ||
      location.pathname === "/getreadyroom" ||
      location.pathname === "/podium"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleLogoClick = () => {
    if (isExitWarningPath()) {
      setExitModalOpen(true);
    } else {
      navigate("/");
    }
  };

  const handleInviteClose = () => {
    setInviteModalOpen(false);
  };

  const handleSaveQuiz = () => {
    setOpenSaveModal(true);
  };

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className="header">
          <div className="header__container">
            <Tooltip title="Take me home" arrow>
              <div className="header__logo" onClick={handleLogoClick}>
                <img className="header__logo" src="/images/logoGif.gif" />
              </div>
            </Tooltip>
            {location.pathname.startsWith("/createquiz") ? (
              <CreateQuizHeaderButtons
                handleSaveQuiz={handleSaveQuiz}
                navigate={navigate}
                isLoggedIn={isLoggedIn}
                enablePreview={editQuestionsList.length != 0}
              />
            ) : location.pathname.startsWith("/previewquiz") ? (
              <PreviewQuizHeaderButtons
                editQuizId={editQuizDetails.quizId}
                navigate={navigate}
                isLoggedIn={isLoggedIn}
              />
            ) : (
              <DefaultHeaderButtons
                handleLogout={handleLogout}
                handleLogin={handleLogin}
                navigate={navigate}
                isLoggedIn={isLoggedIn}
              />
            )}
            <div className="header__mobileDropdown">
              {open ? (
                <CgClose size={33} onClick={() => setOpen(false)} />
              ) : (
                <FiMenu size={33} onClick={() => setOpen(true)} />
              )}
            </div>
          </div>

          <Collapse in={open}>
            <ul className="header__menu">
              {/* <li>
              <HelpIcon /> <span>HELP</span>
            </li>
            <li>
              <SettingsIcon /> <span>SETTINGS</span>
            </li> */}
              <li onClick={() => navigate("/pricing")}>
                <span>PRICING</span>
              </li>
              {userType === "QShala_Admin" && (
                <li onClick={() => navigate("/admin")}>
                  <span>ADMIN</span>
                </li>
              )}
              {isExitWarningPath() && (
                <>
                  {/* <li>
                <ShareIcon /> <span>SHARE ROOM CODE</span>
              </li> */}
                  <li onClick={() => setInviteModalOpen(true)}>
                    <ShareIcon /> <span>SHARE ROOM CODE</span>
                  </li>
                  <li onClick={() => setExitModalOpen(true)}>
                    <ExitIcon /> <span>EXIT QUIZ</span>
                  </li>
                </>
              )}
              {isLoggedIn ? (
                <li onClick={handleLogout}>
                  <LogoutIcon /> <span>LOGOUT</span>
                </li>
              ) : (
                <li onClick={handleLogin}>
                  <span>LOGIN</span>
                </li>
              )}
            </ul>
          </Collapse>
          <InviteModalComponent
            open={inviteModalOpen}
            handleClose={handleInviteClose}
          />
          <ExitWarning
            modalOpen={exitModalOpen}
            setModalOpen={setExitModalOpen}
          />
        </div>
      </ClickAwayListener>
      <SaveQuizComponent
        setOpenSaveModal={setOpenSaveModal}
        openSaveModal={openSaveModal}
      />
    </>
  );
}

export default HeaderComponent;
