import axios from "axios";
import axiosRetry from 'axios-retry';
axios.defaults.withCredentials = true

axiosRetry(axios, {
    retries: 2, // number of retries
    retryDelay: (retryCount) => {
      return retryCount * 2000; // time interval between retries
    },
    retryCondition: (error) => {
      // if retry condition is not specified, by default idempotent requests are retried
      return error.response.status === 511;
    },
});

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status == 401 && error.response.data === " "){
      alert("Unauthorized access please login to proceed")      
      window.location = `https://${process.env.REACT_APP_COGNITO_DOMAIN}/login?client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&response_type=code&scope=${process.env.REACT_APP_COGNITO_SCOPE}&redirect_uri=${process.env.REACT_APP_COGNITO_LOGIN_URI}`
    }  
    return Promise.reject(error);
  }
);