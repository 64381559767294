import React, { useContext, useEffect, useState } from "react";
import "./DisplayCardComponent.scss";
import TitleComponent from "../titleComponent/TitleComponent";
import { useNavigate } from "react-router-dom";
import { copyToClipboardAsync } from "../constants";
import { Button } from "@mui/material";
import Context from "../../context/Context";

const DisplayCardComponent = ({data}) => {
  const navigate = useNavigate();

  const {
    setNotificationType,
    setNotificationMessage,
    setNotificationDisplayFlag,
  } = useContext(Context);

  const setAlert = (type, message) => {
    setNotificationType(type);
    setNotificationMessage(message);
    setNotificationDisplayFlag(true);
  };
  
  const handleCopy = () => {
    const textToCopy = Object.entries(data)
      .map(([key, value]) => `${key}: ${value}`)
      .join("\n");
    copyToClipboardAsync(textToCopy).then(
      () => {
        setAlert("info", "Data copied to clipboard!");
      },
      (error) => {
        setAlert("error", `Error: ${error}`);
      }
    );
  };

  const cardItemsList = Object.entries(data).map(([key, value]) => (
    <p key={key}>
      {key}: {value}
    </p>
  ));

  return (
    <div className="displayCard">
      <div className="displayCard__container">
        <div className="displayCard__info">
          {cardItemsList}
        </div>
        <div className="displayCard__copyButtonContainer">
          <Button onClick={handleCopy}>
            <span
              style={{
                color: "#fff",
                fontFamily: "CaustenB",
                marginLeft: "auto",
              }}
              className="displayCard__copyButton"
            >
              copy
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DisplayCardComponent;
