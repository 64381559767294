import React, { useRef } from 'react'
import "./ShareQtestComponent.scss"
import { Button } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Context from "../../context/Context";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import { FEURL } from "../../api/BaseUrl";
import TitleComponent from '../../shared/titleComponent/TitleComponent';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import InviteModalWrapper from '../../shared/inviteModalComponent/InviteModalWrapper';
import ExitWarning from '../../shared/exitWarning/ExitWarning';
import { isStateValid } from '../../shared/constants';
import QRCode from 'react-qr-code';
import { FaDownload } from 'react-icons/fa';

const useStyles = makeStyles({
  button: {
    padding: "0.5rem 1.5rem",
    fontFamily: "CaustenR",
    fontSize: "1.5rem",
    fontWeight: "700",
    color: "white",
    borderRadius: "10px",
    // "@media only screen and (max-width: 1536px)": {
    //   fontSize: "1.4rem",
    //   fontWeight: "400",
    //   fontFamily: "CaustenEB",
    //   padding: "1rem 1.5rem",
    // },
    "@media only screen and (max-width: 900px)": {
      fontSize: "1rem",
      fontWeight: "400",
      fontFamily: "CaustenEB",
      padding: "0.4rem 2rem",
    }
    // ,
    // "@media only screen and (max-width: 600px)": {
    //   fontSize: "0.875rem",
    //   fontWeight: "400",
    //   fontFamily: "CaustenR",
    //   padding: "0.4rem 1.2rem",
    // }
  }
})

const ShareQtestComponent = () => {
  const {
    setNotificationType,
    setNotificationMessage,
    setNotificationDisplayFlag
  } = useContext(Context);
  const navigate = useNavigate()
  const classes = useStyles()
  const [exitModalOpen, setExitModalOpen] = useState(false);
  let {qtestId} = useParams();
  // Default back
  let backToPath = "/quizselection";
  qtestId = qtestId.trim().toLowerCase();

  // If directed from TilesCarousel or viewAllTiles Component, backToPath is passed as a state.
  const {state} = useLocation();
  if(isStateValid(state)){ // Check if state is valid
    // Read values passed on state if present
    if(state.hasOwnProperty("backToPath")){
      backToPath = state.backToPath;
    }
  }

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  // setAlert to modify notifications before display
  const setAlert = (type, message) => {
    setNotificationType(type);
    setNotificationMessage(message);
    setNotificationDisplayFlag(true);
  };

  const selectInviteLink = () => {
    // Select the Invite Link text
    var range = document.createRange();
    var selection = window.getSelection();
    range.selectNodeContents(document.getElementById("inviteLink"));
    selection.removeAllRanges();
    selection.addRange(range);
  }

  var shareLinkClipboard = new window.ClipboardJS(".copyBtn")
  shareLinkClipboard.on('success', function (e) {
    setTimeout(() => {
      setAlert("info", "Link copied to clipboard");
      selectInviteLink();
    }, 100)
  })

  const handleBackClick = () => {
      navigate(backToPath);
  }

  useEffect(()=>{
    if(!localStorage.getItem("isLoggedIn")){
      navigate("/")
    }
  },[])

  // Download QR Code as a png
  const qrCodeSVGRef = useRef(null);
  const downloadQRCodeAsPng = ({imageName='qrcode'}) => {
    // Fetch element from reference, return if null
    const svgElement = qrCodeSVGRef.current;
    if (!svgElement) return;

    // Serialize SVG into string. The string is converted into a Blob, which is then turned into an Object URL.
    const svgString = new XMLSerializer().serializeToString(svgElement);
    const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' });
    const svgUrl = URL.createObjectURL(svgBlob);
    
    // Initialize canvas and img element
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    const borderWidth = 15

    img.onload = () => {
      // Prep canvas to include a border
      canvas.width = img.width + borderWidth;
      canvas.height = img.height + borderWidth;
      // Fill the background with white color
      ctx.fillStyle = 'white';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      
      // Draw the image on the canvas
      ctx.drawImage(img, borderWidth/2, borderWidth/2);

      // Convert the canvas into a data url, for the 'a' element.
      const pngFile = canvas.toDataURL('image/png');
      
      // Create an 'a' ele,ent, assign href and simulate trigger to download
      const downloadLink = document.createElement('a');
      downloadLink.href = pngFile;
      downloadLink.download = imageName+'.png';
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Clean up
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(svgUrl);
    };

    // This will trigger onLoad on img
    img.src = svgUrl;
  };

  return (
    <div className="shareqtest">
      <TitleComponent title="Your QTest is Ready" handleOnClick={handleBackClick} align="left" style={{ marginRight: "auto", marginLeft: isTablet ? "14%" : '10%' }} />
      <div className="shareqtest__hostActions">
        <div className="shareqtest__linkAndShare">
          <div className="shareqtest__link">
            <p id="inviteLink">{`${FEURL}/qtest/${qtestId}`}</p>
            <Button
              className="copyBtn"
              data-clipboard-text={`${FEURL}/qtest/${qtestId}`}
            >
              <span style={{ marginLeft: "auto" }} className="shareqtest__copyButton">copy</span>
            </Button>
          </div>
          <InviteModalWrapper waitingRoom={true} qtestId={qtestId}/>
        </div>
        <p className="shareqtest__infoText" style={{ padding: "1.5rem 0", }}>
          Copy the link above and share it with your friends for them to participate in
          the QTest, or scan the QR Code below.
        </p>
      </div>
    
      <div className="shareqtest__noPlayers">
        {/* <img src="/images/qTNoPlayers.png" alt="qTNoPlayers" /> */}
        <div style={{ height: "auto", margin: "0 auto", maxWidth: 200, width: "100%", background: 'white', padding:"0.5rem 0.5rem 0.25rem 0.5rem" }}>
          <QRCode
            ref={qrCodeSVGRef}
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={`${FEURL}/qtest/${qtestId}`}
            viewBox={`0 0 256 256`}
          />
        </div>
      </div>

      <div className="shareqtest__buttonContainer">
        <Button
          onClick= {() => {
            navigate("/")
          }}
          className={classes.button}
          style={{
            backgroundColor: "#fdb913",
          }}
        >
          Home
        </Button>
        <Button
          onClick= {()=>downloadQRCodeAsPng({imageName: `qr_code_${qtestId}`})}
          className={classes.button}
          style={{
            backgroundColor: "#fdb913",
          }}
        >
          <FaDownload /> &nbsp; QR Code
        </Button>
      </div>

      <ExitWarning modalOpen={exitModalOpen} setModalOpen={setExitModalOpen} />
    </div>
  );
};

export default ShareQtestComponent;
