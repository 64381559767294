import React from 'react';
import { Divider } from "@mui/material";
import { useState } from "react";
import {BsTrophy} from "react-icons/bs"
import {CgProfile} from "react-icons/cg"
import {MdOutlineQuiz} from "react-icons/md"
import "./NavMenuComponent.scss"

function NavMenuComponent() {
    const [selectedTab, setSelectedTab] = useState("Quizzing");
    
    return (
        <div className="navMenu">
            {/* <div className="navMenu__buttonContainer" onClick={() => setSelectedTab("Quizzing")} style={{backgroundColor: selectedTab === "Quizzing" ? "#ECA904" : "transparent"}}>
                <MdOutlineQuiz size={22}/>
                {selectedTab === "Quizzing" && <p>Quizzing</p>}
            </div>
            <div className="navMenu__buttonContainer" onClick={() => setSelectedTab("Leaderboard")} style={{backgroundColor: selectedTab === "Leaderboard" ? "#ECA904" : "transparent"}}>
                <BsTrophy size={22}/>
                {selectedTab === "Leaderboard" && <p>Leaderboard</p>}
            </div>
            <div className="navMenu__buttonContainer" onClick={() => setSelectedTab("Profile")} style={{backgroundColor: selectedTab === "Profile" ? "#ECA904" : "transparent"}}>
                <CgProfile size={22}/>
                {selectedTab === "Profile" && <p>Profile</p>}
            </div> */}
        </div>
    )
}

export default NavMenuComponent
