import React from 'react'
import "./LoadingComponent.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import Backdrop from '@mui/material/Backdrop'
import Context from "./../../context/Context"
import { useContext } from 'react'

const LoadingComponent = ({loadingText='Loading'}) => {
    const {loadingDisplayFlag} = useContext(Context)
    return ( 
        <>
        <Backdrop
        sx={{ color: '#FDB913', zIndex: (theme) => theme.zIndex.drawer + 500 }}
        open={loadingDisplayFlag}
        >
            <FontAwesomeIcon className="fa-spin fa-3x" icon={faSpinner} />
            {/* <h2 style={{marginLeft: "0.25rem",
                        fontSize: "0.75rem"}}>{loadingText}...</h2> */}
        </Backdrop>
        </>
     );
}
 
export default LoadingComponent;