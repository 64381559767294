import React from "react";
import "./QuizAudioComponent.scss";
import { FaMusic } from "react-icons/fa";

const QuizAudioComponent = ({ audioUrl, width, height, slideType="normal", onMediaClick=()=>{} }) => {
    return (
            <div className="audioMedia">
                {slideType=="normal" && (<>
                    <FaMusic size="10rem"/>
                    <audio width={width} height={height} onClick={onMediaClick} autoPlay={false} controls>
                        <source src={audioUrl} />
                        Your browser does not support the audio element.
                    </audio>
                </>)}
            {slideType=="mini" && (<FaMusic size="3rem"/>)}
            </div>
    );
};

export default QuizAudioComponent;
