import React from 'react'
import "./CustomModalComponent.scss"
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useMediaQuery, useTheme } from "@mui/material";


function CustomModalComponent({ open, handleClose, children, bgColor="#FDB913" }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isTablet ? "90%" : "60%",
    bgcolor: bgColor,
    color: "white",
    borderRadius: "1rem",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    p: 4,
    "& > img": {
      cursor: "pointer",
      // height: isMobile ? "1.125rem" : "2rem",
      // width: isMobile ? "1.125rem" : "2rem",
      height: isMobile ? "1.125rem" : isTablet? "1.5rem" : "2rem",
      width: isMobile ? "1.125rem" : isTablet? "1.5rem" : "2rem",
      marginLeft: "auto",
    },
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <img
          src="/images/Cross.png"
          alt="Cross"
          onClick={handleClose}
        />
        {children}
      </Box>
    </Modal>
  );
}

export default CustomModalComponent;
