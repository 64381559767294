import "./MiniSlideComponent.scss";

import React, { useContext } from "react";
import Context from "../../../../context/Context";
import { S3URL } from "../../../../api/BaseUrl";
import { MdClose } from "react-icons/md";
import { IconContext } from "react-icons";
import { Tooltip } from "@mui/material";
import { RearrangeIcon } from "../../../../shared/Icons";
import { shortenText } from "../../../../shared/constants";
import QuizMediaComponent from "../../../quizComponent/quizMediaComponent/quizMediaComponent/QuizMediaComponent";

const MiniOptions = ({ correctOption, option }) => {
  return (
    <div className="miniOptions" key={option}>
      {correctOption === option && (
        <span className="miniOptions__correctOption"></span>
      )}
    </div>
  );
};

const MiniSlideComponent = ({
  questionData,
  isImage = true,
  handleDeleteClick,
  children,
}) => {
  const { activeSlides, setActiveSlides, setQuestionData, dragFlag } =
    useContext(Context);

  const handleSlideMouseDown = (e) => {
    // Code with control key for multiple slide selection and drag
    // if (!e.ctrlKey && !e.metaKey) {
    //   //if ctrl or cmd key is not pressed set the active slide to the clicked slide
    //   setActiveSlides([questionData.questionId]);
    // } else {
    //   //if ctrl or cmd key is pressed

    //   //if the clicked slide is not already active add it to the active slides
    //   if (activeSlides.indexOf(questionData.questionId) === -1) {
    //     setActiveSlides([...activeSlides, questionData.questionId]);
    //   }
    //   //if the clicked slide is already active remove it from the active slides
    //   else {
    //     setActiveSlides(
    //       activeSlides.filter((slide) => slide !== questionData.questionId)
    //     );
    //   }
    // }

    setActiveSlides([questionData.questionId]);

    // setQuestionData for mouse downed slide
    setQuestionData(questionData);
  };

  return (
    <div
      className={
        activeSlides.indexOf(questionData.questionId) > -1
          ? "miniSlide miniSlide__active"
          : "miniSlide"
      }
      id={questionData.questionId}
      onMouseDown={handleSlideMouseDown}
    >
      {dragFlag &&
      activeSlides.length != 1 &&
      activeSlides.includes(questionData.questionId) ? ( // During drag and more than 1 active slide exists and current question id is in active slides
        activeSlides[0] === questionData.questionId && ( // Display slide count view for the first question in active slides. Dont display the rest of the active slides except for the first one
          <div className="miniSlide__dragging">
            {" "}
            {activeSlides.length} slides selected
          </div>
        ) // Default miniSlide view
      ) : (
        <div className="miniSlide__container">
          {questionData.questionId && (
            <RearrangeIcon
              style={{ width: "1rem", height: "1rem" }}
              className="miniSlide__rearrangeIcon"
            />
          )}

          <div className="miniSlide__content">
            <div className="miniSlide__header">
              <p className="miniSlide__header__title">
                {(questionData.questionTitle &&
                  shortenText({
                    stringElement: questionData.questionTitle,
                    charCount: 25,
                  }))}
              </p>
              <Tooltip title="Delete Question" placement="top" arrow>
                <div className="miniSlide__header__icon__container">
                  <IconContext.Provider
                    value={{ className: "miniSlide__header__icon" }}
                  >
                    <MdClose
                      style={{
                        display: questionData.questionTitle ? "inline" : "none",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleDeleteClick(
                          questionData.quizId,
                          questionData.questionId
                        );
                      }}
                    />
                  </IconContext.Provider>
                </div>
              </Tooltip>
            </div>
            <div
              className="miniSlide__image"
              style={{ display: isImage ? "flex" : "none" }}
            >
              {isImage && <QuizMediaComponent
                mediaUrl={questionData.s3ImageName && S3URL + "readymade/" + questionData.s3ImageName}
                slideType="mini"
              />}
            </div>
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export { MiniSlideComponent as default, MiniOptions };
