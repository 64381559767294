import "./InputComponent.scss"
import React from 'react'

const InputComponent = ({Type, Label, Value, OnChange, OnBlur, 
  OnKeyDown, Disabled, Maxlength, Placeholder, ClassName, Checked, 
  InputId, children
}) => {
  return (
    <div className={`InputComponent ${ClassName}`}>
      {Label && <label>{Label}</label>}
      <input id={InputId} type={Type} value={Value} 
        onChange={OnChange} onBlur={OnBlur} maxLength={Maxlength} 
        placeholder={Placeholder} disabled={Disabled} 
        onKeyDown={OnKeyDown} checked={Checked}
      />
      {children}
    </div>
  )
}

export default InputComponent