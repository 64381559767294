import React, { useContext } from 'react';
import { FaCrown } from 'react-icons/fa';
import { VscDebugDisconnect } from "react-icons/vsc";
import { IconContext } from "react-icons";
import Context from '../../context/Context';
import "./leaderboardList.scss"
import { style } from '@mui/system';

const LeaderboardList = () => {

    const { leaderboardData, playerAnswerCount, podiumPage } = useContext(Context);
    const sortedPlayerNameAndScoreList = () => {
        return leaderboardData?.playerStats?.sort((a, b) => a.rank - b.rank);
    };

    const displayRank = (rank) => {
        return (rank === 0 
            ? "-" // replace rank 0 with hyphen, and rank 1 with crown in podium page 
            : (rank === 1 && podiumPage) ? <FaCrown size={32} color={"#FDB913"}/> : `#${rank}`) 
            
    }

    const getCurrentPlayer = () => {
        let currPlayer = leaderboardData.playerStats.find(o => o.playerId === sessionStorage.getItem("sessionId"));
        if (currPlayer) {
            return (
                <div className={`lbd__playerYou ${currPlayer.rank ===1 && podiumPage && "lbd__winner"}`}>
                    <p className="lbd__rank">{displayRank(currPlayer.rank)}</p>
                    <div className="lbd__player lbd__playerAvatarName" >
                        <img src={currPlayer.playerAvatar} alt="" />                        
                        <div className="lbd__playerNameRole">
                            <p className="lbd__player">{`You`}</p>
                            {currPlayer.playerId === leaderboardData.hostId ?
                                (<p className="lbd__playerRole">{`Host`}</p>) : (``)}
                        </div>
                        {currPlayer.hasAnsweredCurrentQuestion && 
                            playerAnswerCount !== 0 && 
                            <img src={'/images/Tick_Icon.svg'} alt="tick icon" style={{height:'30px'}}/>
                        }{/* display the tick mark only if atleast one person has answered a question */}
                    </div>
                    <p className="lbd__pts">{currPlayer.totalScore}
                    </p>
                </div>
            )
        }
    }

    return (
        <div className="lbd__container">
            <div className="lbd__header">
                <div className="lbd__title lbd__rank">RANK</div>
                <div className="lbd__title lbd__player">PLAYER</div>
                <div className="lbd__title lbd__pts">PTS</div>
            </div>
            <div className="lbd__content">
                {
                    leaderboardData?.playerStats?.length !== 0 ? (
                        <div>
                            <div className="lbd__playerYou__container">
                                {getCurrentPlayer()}
                            </div>
                            {
                                sortedPlayerNameAndScoreList().map((player, id) => (
                                    <React.Fragment key={id}>
                                        {(player.playerId != sessionStorage.getItem("sessionId")) && (
                                            <div className={`lbd__stats ${player.rank ===1 && podiumPage && "lbd__winner"}`} >
                                                <p className="lbd__rank">{displayRank(player.rank)}</p>
                                                <div className="lbd__player lbd__playerAvatarName" >
                                                    <img src={player.playerAvatar} alt="" />
                                                    <div className="lbd__playerNameRole">
                                                        <p className="lbd__player">
                                                            {(player.playerId === sessionStorage.getItem("sessionId")) ? `You` : player.playerName} 
                                                        </p>
                                                          
                                                        {player.playerId === leaderboardData.hostId ? (<p className="lbd__playerRole">{`Host`}</p>) : (``)}
                                                    </div>
                                                    <>&nbsp;</>
                                                    {player.isActive?"":
                                                            <IconContext.Provider value={{ color: "#fa9f98" ,style:{strokeWidth:'0.8',height:'50px'}}}>
                                                            <VscDebugDisconnect/>
                                                            </IconContext.Provider>}
                                                    {player.hasAnsweredCurrentQuestion  && 
                                                        playerAnswerCount !== 0 && 
                                                        <img src={'/images/Tick_Icon.svg'} alt="tick icon" style={{height:'30px'}}/>
                                                    } {/* display the tick mark only if atleast one person has answered a question */}
                                                </div>
                                                <p className="lbd__pts">{player.totalScore}</p>
                                                
                                            </div>
                                        )}
                                    </React.Fragment>
                                ))
                            }
                        </div>
                    ) : (
                        <p className="lbd__noScoreText">No Players have answered</p>
                    )
                }
            </div>

        </div>

    );
}

export default LeaderboardList;