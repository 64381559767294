import React from "react";
import "./QtestQuestionTypeSelectComponent.scss";
import { useEffect } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import QuizDragArrangeComponent from "../../quizComponent/quizTypeComponent/quizDragArrangeComponent/QuizDragArrangeComponent";
import QuizMTFComponent from "../../quizComponent/quizTypeComponent/quizMTFComponent/QuizMTFComponent";
import QtestQuestionBasicComponent from "../qtestQuestionBasicComponent/QtestQuestionBasicComponent";

const QtestQuestionTypeSelectComponent = ({
  question,
  setSelectedAnswer,
  selectedAnswer,
  questionCount,
  noOfQuestions,
  submitAnswer
}) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleAnswerSubmit = (submittedAnswer=null) => {
    submittedAnswer && setSelectedAnswer(submittedAnswer);
    submitAnswer({
      qtestType: question.qtestType,
      questionId: question.questionId,
      playerAnswer: submittedAnswer,
      questionType: question.questionType,
    });
  };

  useEffect(() => {
    document.getElementById("main").scrollTo(0, 0);
  }, []);

  return (
    <div className="qtestQuestionType">
      {question?.questionType === "DND" ? (
        <QuizDragArrangeComponent
          question={question}
          setSelectedAnswer={setSelectedAnswer}
          selectedAnswer={selectedAnswer}
          questionCount={questionCount}
          noOfQuestions={noOfQuestions}
          leaderBoardOpen={false}
          handleAnswerSubmit={handleAnswerSubmit}
        />
      ) : question?.questionType === "MTF" ? (
        <QuizMTFComponent
          question={question}
          setSelectedAnswer={setSelectedAnswer}
          selectedAnswer={selectedAnswer}
          questionCount={questionCount}
          noOfQuestions={noOfQuestions}
          leaderBoardOpen={false}
          handleAnswerSubmit={handleAnswerSubmit}
        />
      ) : (
        <QtestQuestionBasicComponent
          question={question}
          setSelectedAnswer={setSelectedAnswer}
          selectedAnswer={selectedAnswer}
          questionCount={questionCount}
          noOfQuestions={noOfQuestions}
          handleAnswerSubmit={handleAnswerSubmit}
        />
      )}
    </div>
  );
};

export default QtestQuestionTypeSelectComponent;
