import React, { useEffect, useState } from 'react';
import "./QuizBlanksComponent.scss";

const QuizBlanksComponent = ({answerBlanks, setAnswerBlanks, answerSubmitted, setAllowSubmission, wordIndexes}) => {
  
  const [uneditableIndices, setUneditableIndices] = useState([])
  useEffect(() => {
    let indices = [];
    let first_blank_index = 0
    for(let i=0; i<answerBlanks.length;i++) {
        if (answerBlanks[i] !== ""){
          indices.push(i);
          first_blank_index = first_blank_index === i ? i+1 : first_blank_index //if the first blank is a hint character move to next
        } 
    }
    setUneditableIndices(indices) //indices of non empty blanks   
    let element = document.getElementById(`Blank_${first_blank_index}`)
    if (element){
      element.focus() //focus on first blank
    }
      
  }, [wordIndexes])

  useEffect(() => {
    if (answerBlanks.includes("")){
      setAllowSubmission(false) 
    }else{
      setAllowSubmission(true) // Allow submission only if all the blanks are filled
    }
  }, [answerBlanks])
  

  const shiftFocusForward = (e) => { 
    let blank_index =Number(e.target.name)
    
    while (blank_index < answerBlanks.length-1){
      let nextElement = document.getElementById(`Blank_${blank_index+1}`)
      if (!nextElement.disabled) {       //focus on next element if it is not disabled 
        nextElement.focus()
        break
      }
      else blank_index += 1
    }    
  }

  const shiftFocusBackward = (e) => { 
    let blank_index =Number(e.target.name)

    while (blank_index > 0){
      let prevElement = document.getElementById(`Blank_${blank_index-1}`)
      if (!prevElement.disabled) {  //focus on next element if it is not disabled
        prevElement.focus()
        break
      }
      else blank_index -= 1
    }  
  }
  
  const handleOnChange = (e) => {
    const {name, value} = e.target
    setAnswerBlanks(existingItems => existingItems.map((letter, idx) => Number(name) === idx ? value.substring(value.length - 1) : letter))    
    if (value.substring(value.length - 1) !== ""){ //dont shift focus forward on deletion      
      shiftFocusForward(e)
    }
  }

  const handleOnKeyDown = (e) => {
    if ((e.key === "Delete") && (e.target.value != "")){ // wehn you press delete button on a blank with text
      e.target.value = "" // delete the text
      const {name, value} = e.target
      setAnswerBlanks(existingItems => existingItems.map((letter, idx) => Number(name) === idx ? value.substring(value.length - 1) : letter))    
    }
    else if ((e.key === "Delete" || e.key === "Backspace" ) && (e.target.value == "")) { //shift focus back if deleted on empty textbox
      shiftFocusBackward(e)        
    }
  }

  const createWordBlanks = ([startingIdx, endingIdx]) => ( 
    <div  className='quizFIB__blanks' key={startingIdx}>
    {answerBlanks?.map((letter, index) => {
      if (index >= startingIdx && index <= endingIdx)
        return(
          <input 
            value = {answerBlanks[index].toUpperCase()}
            id = {`Blank_${index}`}
            key = {`Blank_${index}`}
            name = {index}
            type = "text"
            maxLength="2"
            disabled = {uneditableIndices.includes(index) || answerSubmitted}  //disable if it isn't a blank or if answer has been submitted
            autoComplete="off"
            className='answerBlanks'
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
          />
        )
      else 
          return undefined
    })}
    </div>
  )

  return (   
    wordIndexes.map(createWordBlanks)
  )
}

export default QuizBlanksComponent