import { Button } from '@mui/material'
import React, { useContext, useState } from 'react'
import Context from '../../../context/Context'
import QuizMediaComponent from '../../quizComponent/quizMediaComponent/quizMediaComponent/QuizMediaComponent'
import { mediaTypeFromMime } from "./../../../shared/constants"

const MediaUploadComponent = ({placeholderimage, imageURL, setImageURL}) => {
  const [isMediaUploaded, setIsMediaUploaded] = useState(false)
  const [mediaFileName, setMediaFileName] = useState("")
  const [mediaType, setMediaType] = useState(null)

  const {setNotificationType, setNotificationMessage, setNotificationDisplayFlag} = useContext(Context)
  const setAlert = (type, message) => {
    setNotificationType(type)
    setNotificationMessage(message)
    setNotificationDisplayFlag(true)
  }

  const handleMediaClick = () => {
    document.getElementById("media_upload").click()
  }

  const handleFileChange = (e) => {
    let reader = new FileReader();
    let file = e.target.files[0];
    
    if (file.size> 20000000){
      setAlert("error","File too big, max file size is 20MB")
      return
    }
    setMediaType(mediaTypeFromMime(file.type))
    setMediaFileName(file.name)
    reader.readAsDataURL(file)

    reader.onloadend = () => {
      console.log(reader.result)
      setImageURL(reader.result)
      setIsMediaUploaded(true)
    }
  }
  return (
    <>
      <label htmlFor="media_upload" style={{
            fontFamily: "CaustenR",
      }}>
        <Button
          variant="contained"
          style={{
            backgroundColor: "#30b2e7",
            borderRadius: "10px",
            height: "1.5rem",
            width: "9rem",
            paddingTop: "0.5rem",
            marginRight: "1rem",
            fontSize: "1rem",
            fontWeight: 700,
            fontFamily: "CaustenR",
          }}
          onClick={handleMediaClick}
        >
          Choose File
        </Button>
        {mediaFileName}
        <input 
          type="file" id="media_upload"
          accept="image/*, audio/*, video/*"
          style={{display: "none"}}
          onChange={handleFileChange}
        />
      </label>
      
      {imageURL===""?<img 
        src= {placeholderimage} 
        onClick={handleMediaClick} 
      />:<QuizMediaComponent 
            width="auto" 
            height="auto" 
            mediaUrl={imageURL}
            mediaTypeParam={mediaType}
            onMediaClick={handleMediaClick} 
      />}
    </> 
  )
}

export default MediaUploadComponent