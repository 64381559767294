import React from 'react'
import MiniSlideComponent from '../miniSlideComponent/MiniSlideComponent'

const MiniNonQuestionComponent = ({questionData, handleDeleteClick}) => {
  return (
    <MiniSlideComponent questionData={questionData} handleDeleteClick={handleDeleteClick} isImage={questionData.s3ImageName?true:false}>
      <div className="miniOptions"  style={{height:"2rem"}}/>
    </MiniSlideComponent>
  )
}

export default MiniNonQuestionComponent