import "./QuestionDetailsComponent.scss"

import React, { useState } from 'react'
import CustomModalWithTitleComponent from "../../../shared/customModalComponent/CustomModalWithTitleComponent"
import FillButtonComponent from "../../../shared/fillButtonComponent/FillButtonComponent"
import {BsPlusLg} from 'react-icons/bs'
import {FiMinus} from 'react-icons/fi'

const QuantityIncrementButtons = ({value, setValue, incrementByValue=10, decrementByValue=10}) => {
  const decrementValue = () => {
    if (value >= decrementByValue) {
      setValue((prevValue) => prevValue - decrementByValue);
    }
  };
  const incrementValue = () => { setValue((prevValue) => (prevValue + incrementByValue)) }
  return(
    <div className="quantityIncrementButtons">
      <FiMinus color="#FDB913" onClick={decrementValue} />
      <p>{value}</p>
      <BsPlusLg size="1rem" color="#FDB913" onClick={incrementValue} />
    </div>
  )
}

const QuestionDetailsComponent = ({open, handleClose, handleAddQuestion, editQuestonFlag=false, time, setTime, points, setPoints}) => {
  return (
    <CustomModalWithTitleComponent
      title="Question Details" open={open} handleClose={handleClose}
      bgColor="#0097D4" width="40%"
      >
      <div className="questionDetails">
        <div className="questionDetails__item">
          <h2>
            Points earned if answered correctly
          </h2>
          <QuantityIncrementButtons value={points} setValue={setPoints}/>
        </div>
        <div className="questionDetails__item">
          <h2>
            Time limit for question in seconds
          </h2>
          <QuantityIncrementButtons value={time} setValue={setTime}/>
        </div>
        <FillButtonComponent
          displayText={editQuestonFlag? "EDIT QUESTION" : "ADD QUESTION"}
          handleClick={handleAddQuestion}
        />
      </div>
    </CustomModalWithTitleComponent>
  )
}

export default QuestionDetailsComponent