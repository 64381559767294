import React from "react";
import MiniSlideComponent from "../miniSlideComponent/MiniSlideComponent";

const MiniFIBComponent = ({ questionData, handleDeleteClick }) => {
  return (
    <MiniSlideComponent
      questionData={questionData}
      isImage={true}
      handleDeleteClick={handleDeleteClick}>
      <div className="miniFIB"><div className="miniOptions" /></div>
    </MiniSlideComponent>
  );
};

export default MiniFIBComponent;