import React from "react";
import "./InviteModalComponent.scss";
import CustomModalComponent from "../customModalComponent/CustomModalComponent";
import { useContext, useState } from "react";
import Context from "./../../context/Context";
import { Button } from "@mui/material";
import configData from "../../config.json";
import {ImEmbed2} from "react-icons/im"

import {
  WhatsappShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappIcon,
  TwitterIcon,
  FacebookIcon,
} from "react-share";
import { FEURL } from "../../api/BaseUrl";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

function InviteModalComponent({
  open,
  handleClose,
  displayText = "Share Link Via",
  qtestId=null
}) {
  const {
    roomId,
    setNotificationType,
    setNotificationMessage,
    setNotificationDisplayFlag,
  } = useContext(Context);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  var displayURL, copyURL;

  // setAlert to modify notifications before display
  const setAlert = (type, message) => {
    setNotificationType(type);
    setNotificationMessage(message);
    setNotificationDisplayFlag(true);
  };

  // Select the Invite Link text
  const selectInviteLink = () => {
    var range = document.createRange();
    var selection = window.getSelection();
    range.selectNodeContents(document.getElementById("inviteLinkModal"));
    selection.removeAllRanges();
    selection.addRange(range);
  };

  if(qtestId){
    displayURL=`${qtestId}`
    copyURL=`${FEURL}/qtest/${qtestId}`
  }
  else{
    displayURL=`${FEURL.split(/\/\/(.+)/)[1]}/${roomId}`
    copyURL=`${FEURL}/${roomId}?utm_source=share`
  }

  // Clipboard code
  var inviteLinkClipboard = new window.ClipboardJS(".copyBtnModal");
  inviteLinkClipboard.on("success", function (e) {
    setTimeout(() => {
      setAlert("info", "Link copied to clipboard");
      selectInviteLink();
    }, 100);
  });

  const navigate = useNavigate()

  return (
    <>
      <CustomModalComponent
        open={open}
        handleClose={handleClose}
      >
        <div className="inviteModal__container">
          <h1>{displayText}</h1>
          <div className="inviteModal__link">
            <p id="inviteLinkModal">{displayURL}</p>
            <Button
              className="copyBtnModal"
              data-clipboard-text={copyURL}
            >
              <span
                style={{
                  color: "#fff",
                  fontFamily: "CaustenEB",
                  marginLeft: "auto",
                }}
                className="inviteModal__copyButton"
              >
                copy
              </span>
            </Button>
          </div>
          <div className="inviteModal__shareContainer">
            <WhatsappShareButton
              url={qtestId?`${FEURL}/qtest/${qtestId}`:`${FEURL}/${roomId}?utm_source=whatsapp`}
              title={configData.shareInviteText_WA}
              separator={"   ---->   "}
            >
              <div className="inviteModal__shareIconContainer">
                <WhatsappIcon
                  size={isMobile ? 42 : 74}
                  style={{ borderRadius: "10px" }}
                />
                <p>Whatsapp</p>
              </div>
            </WhatsappShareButton>
            <FacebookShareButton
              url={qtestId?`${FEURL}/qtest/${qtestId}`:`${FEURL}/${roomId}?utm_source=facebook`}
              quote={`${configData.shareInviteText_FB} ` + qtestId?`${FEURL}/qtest/${qtestId}`:`${FEURL}/${roomId}`}
            >
              <div className="inviteModal__shareIconContainer">
                <FacebookIcon
                  size={isMobile ? 42 : 74}
                  style={{ borderRadius: "10px" }}
                />
                <p>Facebook</p>
              </div>
            </FacebookShareButton>
            <TwitterShareButton
              url={qtestId?`${FEURL}/qtest/${qtestId}`:`${FEURL}/${roomId}?utm_source=twitter`}
              title={configData.shareInviteText_TWTR}
            >
              <div className="inviteModal__shareIconContainer">
                <TwitterIcon
                  size={isMobile ? 42 : 74}
                  style={{ borderRadius: "10px" }}
                />
                <p>Twitter</p>
              </div>
            </TwitterShareButton>
            {qtestId && <div  className="inviteModal__shareIconContainer">
              <ImEmbed2
                size={isMobile ? 42 : 74}
                onClick={() => navigate(`/embed/popupButton/${qtestId}`)}
                style={{border:"3px solid #FFF", borderRadius:"10px", background:"#30B2E7", cursor:"pointer"}}
              />
              <p>Embed</p>
            </div>}
          </div>
        </div>
      </CustomModalComponent>
    </>
  );
}

export default InviteModalComponent;
