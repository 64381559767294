import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import CustomModalComponent from '../../../shared/customModalComponent/CustomModalComponent'

const AccessPromptComponent = ({open}) => {
  const navigate = useNavigate()  
  return (
    <CustomModalComponent open={open} handleClose={() => navigate("/")}>
      <div className="selectMultiplayer__modal">
        <h1>Hi There!</h1>
        <p>
          You don't have access to edit this quiz!
        </p>
        <div className="selectMultiplayer__modalButtonContainer">
            <Button
              variant="contained"
              style={{backgroundColor: "#30b2e7"}}
              className="selectMultiplayer__modalButton"
              onClick={() => navigate('/')}
            >
              OK
            </Button>
        </div>
      </div>
    </CustomModalComponent>
    
  )
}

export default AccessPromptComponent
