import './RedirectComponent.scss'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Context from '../../context/Context';
import Cookies from 'js-cookie';
import jwt from 'jsonwebtoken';
import axios from 'axios';
import { BEURL } from '../../api/BaseUrl';
import apiEndpoints from '../../api/EndPoints';

const RedirectComponent = () => {
  const { setLoadingDisplayFlag, isLoggedIn, setIsLoggedIn, setUserName} = useContext(Context);
  const navigate = useNavigate()
  let {action} = useParams()
  const [message, setMessage] = useState("...")
  const [searchParams, setSearchParams] = useSearchParams()
  setLoadingDisplayFlag(true)

  const checkSignUp = () => {
    const url = BEURL + apiEndpoints.signUp
    axios.get(url)
  }

  useEffect(() => {
    if (action=="logout"){
      setMessage("Logging you out...")
      const url = BEURL + apiEndpoints.signOut
      axios.get(url).then(()=>{
        setIsLoggedIn(false)
        localStorage.setItem('isLoggedIn', false)
        sessionStorage.removeItem("sessionId")
        sessionStorage.removeItem("name")
        sessionStorage.removeItem("email")
        sessionStorage.removeItem("ageGroup")
        sessionStorage.removeItem("avatar")
        navigate('/')
      }).catch((err)=>{
        console.error(err.response?.data)
      })      
    }

    if(action=="login"){
      setMessage("Logging you in...")
      let authorization_code = searchParams.get('code')
      if (authorization_code === null)
        return  

      axios.get(
        BEURL + apiEndpoints.getToken,
        {
          params:{ code : authorization_code}
        }
      ).then(() => { 
        setIsLoggedIn(true)
        localStorage.setItem('isLoggedIn', true)
        let qqIdToken = Cookies.get('qq_id_token')   
        let decodedToken = jwt.decode(qqIdToken);
        setUserName(decodedToken['cognito:username']) 
        sessionStorage.setItem('sessionId', decodedToken['sub']) 
        navigate('/')       
      }).catch((error) => {
          alert(String(error.message))
          navigate('/') 
      })
    }

    return () => {
      setLoadingDisplayFlag(false)
    } 
  }, [])  

  //remove this when you implement own sign up page, add custom sign up redirect to optimize
  // useEffect(() => {
  //   if (isLoggedIn)
  //     checkSignUp() 
  // }, [isLoggedIn])  
  
  return (    
    <div className="redirect">
      <p>{message}</p>
    </div>
  )
}

export default RedirectComponent