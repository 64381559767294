import React, { useRef, useState } from "react";
import "./HorizonatalTileComponent.scss";
import { S3URL } from "../../api/BaseUrl";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const HorizonatalTileComponent = ({
  quizId,
  quizType,
  imageName,
  title,
  subtitle1,
  subtitle2,
  subtitle3="",
  handleOnClick = () => {},
  isMenuAvailable,
  menuItems = [
    { text: "Edit", handleClick: () => {} },
    { text: "Delete", handleClick: () => {} },
  ],
}) => {
  let imageURL = "";
  const menuRef = useRef();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const menuOpen = Boolean(menuAnchorEl);

  if (quizType == "RM" || quizType == "Ready Made") { // image in server
    imageURL = S3URL + "readymade_banner/" + imageName;
  } else if (quizType == "IB" || quizType == "Interest Based") { // image in local
    imageURL = "/images/categories/"+imageName+".jfif"
  } else { // default image
    imageURL = "/images/quizItem.svg";
  }

  const handlefallbackImage = (e) => {
    e.target.src = "/images/quizItem.svg"
  };

  return (
    <div className="horizonatalTile" onClick={handleOnClick} id={quizId} style={{height: subtitle3?"7.75rem":"6.25rem"}}>
      <div className="horizonatalTile__image">
        <img 
          src={imageURL}
          onError={handlefallbackImage}
        />
      </div>
      <div className="horizonatalTile__description">
        <div className="horizonatalTile__description__titleContainer">
          <p className="horizonatalTile__description__title">{title}</p>
          {isMenuAvailable && (
            <img
              ref={menuRef}
              onClick={()=>{
                setMenuAnchorEl(menuRef.current)
              }}
              className="menuOption"
              src="/images/Menu.svg"
              width="18px"
              height="18px"
            />
          )}
        </div>
        <p className="horizonatalTile__description__subtitle">{subtitle1}</p>
        <p className="horizonatalTile__description__status">{subtitle2}</p>
        {subtitle3 && <p className="horizonatalTile__description__status">{subtitle3}</p>}
      </div>
      <Menu
        key={quizId}
        classes={{ paper: "menuOptions", list: "menuOptionsList" }}
        anchorEl={menuRef.current}
        open={menuOpen}
        onClose={()=>{
          setMenuAnchorEl(null);
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        {/* Display all menu items */}
        {menuItems.map((menuItem, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              setMenuAnchorEl(null);
              menuItem.handleClick();
            }}
          >
            {menuItem.text}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default HorizonatalTileComponent;
