import "./DashboardDrawerComponent.scss"
import React from 'react';
import Drawer from '@mui/material/Drawer';
import { List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { useNavigate } from "react-router-dom";

const drawerWidth = "20%";

const DashboardDrawerComponent = ({selectedView}) => {
  const drawerItems = ["dashboard", "library", "reports"]
  // const drawerItems = ["reports"]
  const navigate = useNavigate()
  return (
    <div className="drawerComponent">
    <Drawer
      sx={{
        width: drawerWidth,
        minWidth:"200px",
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          minWidth:"200px",
          boxSizing: 'border-box',
          paddingTop: "12vh",
          background: "#ECA904",
          border: "none",
          gap:"2rem",
        },
        '& .MuiDrawer-paper::-webkit-scrollbar': {
          width: "7px",
        },
        '& .MuiDrawer-paper::-webkit-scrollbar-track': {
          backgroundColor: "#ECA904",
        },
        '& .MuiDrawer-paper::-webkit-scrollbar-thumb': {
          background: "#DB9B00",
          borderRadius: "7px",
        },
      }}
      variant="permanent"
      anchor="left"
    > 

      <List>
        {drawerItems.map((item, idx) => (
          <ListItem  disablePadding key={idx}>
            <ListItemButton
              selected={selectedView === item}
              onClick={() => navigate("/"+item.replace(/\s/g,''))}
            >
              <ListItemText disableTypography>
                {item}
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>

    </Drawer>
    </div>
  )
}

export default DashboardDrawerComponent