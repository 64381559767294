const apiEndpoints = {
  createInvite: "qq/v1/createinvite/",
  categories: "qq/v1/getcategories/",
  readymade: "qq/v1/getreadymadequizzes/",
  checkRoomId: "qq/v1/checkroomid/",
  getToken: "qq/v1/gettoken",
  updateProfile: "qq/v1/updateprofile/",
  getProfile: "qq/v1/getprofile/",
  getDashboard: "qq/v1/getdashboard/",
  getMyLibrary: "qq/v1/getmylibrary/",
  getShortReport:"qq/v1/getshortreport/",
  getQuizExcelReportSummary:"qq/v1/getquizexcelreportsummary/",
  signUp: "qq/v1/signup/",
  confirmSignUp: "qq/v1/confirmsignup/",
  checkUsername: "qq/v1/checkusername/",
  resendConfirmationCode: "qq/v1/resendconfirmationcode/",
  signOut: "qq/v1/signout/",
  signIn: "qq/v1/signin/",
  getUserStats: "qq/v1/getuserstats",
  updateUserStats: "qq/v1/updateuserstat",
  forgotPassword: "qq/v1/forgotpassword/",
  confirmForgotPassword: "qq/v1/confirmforgotpassword/",
  termsAndConditions:"qq/v1/termsandconditions/",
  createOrder:"qq/v1/createorder/",
  confirmPayment:"qq/v1/confirmpayment/",
  getExcelSummary:"qq/v1/getexcelsummary/",
  createSubscription:"qq/v1/createsubscription/",
  confirmPayment:"qq/v1/confirmpayment/",
  cancelSubscription:"qq/v1/cancelsubscription/",
  createQtest:"qq/v1/qtest/createqtest/",
  submitQtestAnswer:"qq/v1/qtest/submitanswer/",
  attendQtest:"qq/v1/qtest/attendqtest/",
  checkQtest:"qq/v1/qtest/checkqtest/",
  rescheduleQtest:"qq/v1/qtest/rescheduleqtest/",
  createQuiz: "qq/v1/createquiz/createquiz/",
  addQuestion: "qq/v1/createquiz/addquestion/",
  deleteQuestion: "qq/v1/createquiz/deletequestion/",
  editQuestion: "qq/v1/createquiz/editquestion/",
  getQuizDetails : "qq/v1/createquiz/getquiz/",
  deleteQuiz : "qq/v1/createquiz/deletequiz/",
  editQuizDetails : "qq/v1/createquiz/editquiz/",
  changeQuizStatus: "qq/v1/createquiz/changequizstatus/",
  addQuizEditor: "qq/v1/createquiz/addquizeditor/",
  getQuizEditors: "qq/v1/createquiz/getuserswitheditaccess/",
  removeQuizEditor: "qq/v1/createquiz/removequizeditor/",
  getOrganizationTeams: "qq/v1/createquiz/getorgteams/",
  getAssignedTeams: "qq/v1/createquiz/getquizteams/",
  assignTeamToQuiz: "qq/v1/createquiz/assignquiztoteams/",
  createUser: "qq/v1/createuser/"
};

export default apiEndpoints;
