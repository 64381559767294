export const QUIZ_MODE = {
  QUIZ: "QZ",
  QTEST: "QT",
};

export const getQtestTypeCode = (readbleQtestType) => {
  if (readbleQtestType == "Ready Made") {
    return "RM";
  } else if (readbleQtestType == "Private") {
    return "PV";
  } else if (readbleQtestType == "Interest Based") {
    return "IB";
  }
};

export const validateEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const generateRandom = (min, max) => Math.floor(Math.random() * (max - min)) + min; // Include min and exclude max value

export const checkArrayEqual = (a, b) => {
  return Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index]);
}

export const get_answer_blanks_with_hint_letter = (answer) => {
  let blanks = Array(answer.length).fill("");
  let letter_position = 0;
  let letter = ' ';
  while(letter == ' '){ // Get hint letter that is not a "space"
    letter_position = generateRandom(0,answer.length);
    letter = answer[letter_position]
  }
  blanks[letter_position] = letter;
  
  // Add spaces to blanks as per answer
  let answerCharArray = [...answer];
  answerCharArray.forEach((letter, ind)=>{
      (letter == ' ') && (blanks[ind] = ' ');
  })

  return blanks;
}

export const copyToClipboardAsync = str => {
  if (navigator && navigator.clipboard && navigator.clipboard.writeText)
    return navigator.clipboard.writeText(str);
  return Promise.reject('Copy Failed! Clipboard API is not available.');
};

export const shortenText = ({charCount, stringElement}) => {
  if(stringElement.length<=charCount){
    return stringElement
  }
  else{
    return stringElement.slice(0,charCount)+"..."
  }
}

export const isStateValid = (state) => {
  if (!state) return false; // Makes sure it's not null
  if (typeof state !== "object") return false;
  return true;
}

export const capitalizeString = (str) => {
  return str?str.charAt(0).toUpperCase() + str.slice(1):"";
}

export const  capitalizeSentence = (sentence) => {
  // Split the sentence into words
  const words = sentence.split(' ');
  // Capitalize the first letter of each word
  const capitalizedWords = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
  });
  // Join the words back into a sentence
  const capitalizedSentence = capitalizedWords.join(' ');
  return capitalizedSentence;
}

export const isAtleastOneKeyEmpty = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if(typeof value === 'string' && value.trim() === '')
      {
        return true;
      }
    }
  }
  return false;
}

// Split the string before first occurence of '/', trim and lowercase for media type
export const mediaTypeFromMime = (mimeString) => {
  return mimeString.split(/\/(.*)/s)[0].trim().toLowerCase()
}

// Compare function for array sort
export const compareForArraySortWrapper = (fieldName, order) => (a, b) => {
  if(order=="decend"){
    if ( b[fieldName] < a[fieldName] ){
      return -1;
    }
    if ( b[fieldName] > a[fieldName] ){
      return 1;
    }
    return 0;  
  }
  else{ // Code for ascend
    if ( a[fieldName] < b[fieldName] ){
      return -1;
    }
    if ( a[fieldName] > b[fieldName] ){
      return 1;
    }
    return 0;
  }
}

export const getSlideInfoFromRoomId = (roomId) => [
  {
    src: "/images/howToJoin1.png",
    label: "Step 1",
    text: "Step 1: Go to quiz.qshala.com and click on play quiz",
  },
  {
    src: "/images/howToJoin2.png",
    label: "Step 2",
    text: `Step 2: Enter the room code - <span class="slider__slide__roomCode">${roomId}</span> and click on join quiz`,
  },
  {
    src: "/images/howToJoin3.png",
    label: "Step 3",
    text: "Step 3: Enter you name, email, age, select an avatar and click on proceed",
  },
  {
    src: "/images/howToJoin4.png",
    label: "Step 4",
    text: "Step 4: Wait in the waiting room for your host to start the quiz",
  },
];