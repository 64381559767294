import * as React from 'react'
import "./AlertComponent.scss"
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Context from './../../context/Context'
import Slide from '@mui/material/Slide'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
})

function SlideTransition(props) {
    return <Slide {...props} direction="up" />
}

const AlertComponent = () => {
    const {notificationDisplayFlag, setNotificationDisplayFlag,
        notificationType, notificationMessage, notificationDuration, setNotificationDuration} = React.useContext(Context)

    const handleClose = (event, reason) => {
        // if(reason==="clickaway"){
        //     return // Do nothing if clicked anywhere else
        // }
        setNotificationDisplayFlag(false);
        setNotificationDuration(5000);
    }

    return(
        <Snackbar sx={{ zIndex: "1400" }} open={notificationDisplayFlag} autoHideDuration={notificationDuration} TransitionComponent={SlideTransition} onClose={handleClose}>
            <Alert onClose={handleClose} severity={notificationType} sx={{ width: '100%' }}>
                <AlertTitle style={{fontWeight:700}}>{String(notificationType).toUpperCase()}</AlertTitle>
                {notificationMessage}
            </Alert>
        </Snackbar>
    )
}

export default AlertComponent