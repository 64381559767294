import "./PremiumModalComponent.scss"
import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material'
import React, { useState } from 'react'
import { TiTick } from "react-icons/ti"
import { useNavigate } from 'react-router-dom'
import CustomModalComponent from '../../shared/customModalComponent/CustomModalComponent'
import { PremiumModalCopy } from "../../copies"

const RadioBtn = ({value, label}) => (
  <FormControlLabel sx = {{
    '.MuiFormControlLabel-label':{
        color:"#FFF",
        fontFamily:"CaustenM",
        fontWeight: 700,
        fontSize:"clamp(1rem, 2vw, 1.875rem)",
        lineHeight:"clamp(1rem, 2vw, 1.875rem)",
      }
    }} 
  value={value} 
  control={ <Radio 
              sx={{
                color: "#FFF",
                '&.Mui-checked': {
                  color: "#30B2E7",
                },                                    
              }}
          />} 
  label={label} />
)

const PremiumModalComponent = ({open, handleClose, handleOnClickBuy}) => {
  const [plan, setPlan] = useState("Premium_Yearly")
  const handlePlanChange = (event) => {
    setPlan(event.target.value)
  }
  const navigate = useNavigate()
  return (
    <CustomModalComponent open={open} handleClose={handleClose}>
      <div className="selectMultiplayer__modal">
      <h2 className="pricingTile__title">Choose your plan</h2>
        <div className='premiumModal'>
          <RadioGroup
            aria-label="quiz"
            name="quiz"
            onChange={handlePlanChange}
            value={plan}
            column
            >                      
            <RadioBtn label={PremiumModalCopy.yearly.price} value={"Premium_Yearly"}/> 
              {
                PremiumModalCopy.yearly.details.map((detail) => (
                  <p className="pricingTile__description premiumModal__details"><TiTick className="pricingTile__icon"/>{detail}</p>
                ))
              }
            <>&nbsp;</>
            <RadioBtn label={PremiumModalCopy.monthly.price} value={"Premium_Monthly"}/> 
              {
                PremiumModalCopy.monthly.details.map((detail) => (
                  <p className="pricingTile__description premiumModal__details"><TiTick className="pricingTile__icon"/>{detail}</p>
                ))
              }
          </RadioGroup>
        </div> 
        <div className="selectMultiplayer__modalButtonContainer">
            <Button
              variant="contained"
              style={{backgroundColor: "#30b2e7"}}
              className="selectMultiplayer__modalButton"
              onClick={() => handleOnClickBuy(plan)}
            >
              BUY
            </Button>
        </div>
      </div>
    </CustomModalComponent>
  )
}

export default PremiumModalComponent