import React, { useContext } from 'react'
import "./TimerComponent.scss"
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { makeStyles } from '@mui/styles'
import Context from '../../../context/Context';

const LinearProgressWithLabel = (props) => {

  const { leaderBoardOpen } = useContext(Context);

  const useStyles = makeStyles(theme => ({
    colorPrimary: {
      backgroundColor: '#FDB913',
      borderRadius: '5px',
      height: "10px"

    },
    barColorPrimary: {
      backgroundColor: '#0097D4',
      height: "10px"
    }
  }));

  const classes = useStyles();

  return (
    <Box sx={{ display: "flex", width: props.getReadyRoom? 'calc(100vw - 380px)' : leaderBoardOpen ? 'calc(100vw - 380px)' : "100vw", alignItems: "center", backgroundColor: "#30B2E7", justifyContent: "center" }}>
      <div style={{ width: props.getReadyRoom? 'calc(100vw - 380px)' : leaderBoardOpen ? 'calc(100vw - 380px)' : "100vw", mr: 1, transform: "rotateY(-180deg)"}}>
        <LinearProgress variant="determinate" value={props.value} classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }} />
      </div>
      {/* <Box sx={{ minWidth: 35 }}>
        <p style={{color:"#FFF", fontSize:"24px"}}>{`${Math.round(props.maxTime -(props.value/(100/props.maxTime)))}`}</p> 
      </Box>  */}
    </Box>
  );
}

const TimerComponent = ({ maximumTime, progress, getReadyRoom=false }) => {
  const maxTime = isNaN(maximumTime) ? 30 : maximumTime; //if  max time is  not passed default  to 30s
  const normalize = (value) => ((value) * 100) / (maxTime);
  return (
    <Box sx={{ width: "100%" }} className='mcqTimer'>
      <LinearProgressWithLabel getReadyRoom={getReadyRoom} value={normalize(progress>maxTime?maxTime:progress)} maxTime={maxTime} /> 
    </Box>
  );
}

export default TimerComponent
