import React from "react";
import { CgArrowLongRight } from "react-icons/cg";

const QuizAnswerMTF = ({ correctAnswer }) => {
    const correctAnswerList = eval(correctAnswer);
    // console.log(correctAnswerList);

    return (
        <div className="quizMTFComponent__matchWrapper" style={{ width: "80%", paddingTop: "2rem" }}>
            {correctAnswerList[0].map((element, index) => (
                <div key={index} className="quizMTFComponent__wrapper">

                    <p className="quizMTF__matchQuestion">{element}</p>

                    <CgArrowLongRight className="quizMTF__arrow" size={44} style={{ color: "white" }} />
                    <div className="quizMTF__matchAnswerWrapper">
                        <p className="quizMTF__matchAnswer"> {correctAnswerList[1][index]}</p>
                    </div>
                </div>

            ))}
        </div>
    );
}

export default QuizAnswerMTF;