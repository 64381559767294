export const FreeTierCopy = {
    title:"Free",
    price:"₹0/month",
    features:[
        "Upto 10 members playing the quiz simultaneously",
        "25 Curated Quizzes"
    ],
    buttonText:"Start Quizzing"
}

export const PremiumTierCopy = {
    title:"Premium",
    price:"₹999/month",
    features:[
        "Upto 100 members playing the quiz simultaneously",
        "300+ Curated Quizzes",
        "Unlimited data reports of quizzes played",
        "1 Admin Login"
    ],
    buttonText:"Get"
}

export const EnterpriseTierCopy = {
    title:"Enterprise",
    price:"Get in touch",
    features:[
        "More than 100+ members playing the quiz simultaneously",
        "Customize Quizzes",
        "300+ Curated Quizzes",
        "Unlimited data reports of quizzes played",
        "Single Sign on"
    ],
    buttonText:"Contact Us"
}

export const PremiumModalCopy = {
    yearly:{
        price:"₹10,000/year",
        details:[
           "Save over 15%"
        ]
    },
    monthly:{
        price:"₹999/month",
        details:[
           "Pay as you go",
           "Cancel Anytime!" 
        ]
    }
}

export const dashboard_welcomebanner_description = `Get started on creating engaging quizzes  which revolutionize the process of learning!`
export const reports_welcomebanner_description = "Get started on generating reports to gather \n valuable insights on your quizzes and courses."