import React, { useEffect } from "react";
import CustomModalComponent from "../customModalComponent/CustomModalComponent";
import { Button } from "@mui/material";
import "./WarningComponent.scss"
import { useNavigate } from "react-router-dom";


const WarningComponent = ({modalOpen, setModalOpen, handleClick, buttonText, children}) => {

    const handleBtnClick = () => {
        handleClick()
        setModalOpen(false)
    }

    return (
        <CustomModalComponent
            open={modalOpen}
            handleClose={() => setModalOpen(false)}
        >
            <div className="warning__modal">
                {children}
                <Button
                    variant="contained"
                    onClick={handleBtnClick}
                    className="warning__button"
                >
                    {buttonText}
                </Button>
            </div>
        </CustomModalComponent>
    );
}

export default WarningComponent;

