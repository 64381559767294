import React from "react";
import "./NameAgeComponent.scss";
import { Button } from "@mui/material";
import { useEffect, useState, useContext } from "react";
import Context from "./../../../context/Context";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import TitleComponent from "../../../shared/titleComponent/TitleComponent";
import NavMenuComponent from "../../../shared/navMenuComponent/NavMenuComponent";
import { BEURL } from "../../../api/BaseUrl";
import apiEndpoints from "../../../api/EndPoints";
import axios from "axios";
import { validateEmail } from "../../../shared/constants";

const RadioBtn = ({ value, label }) => (
  <FormControlLabel
    sx={{
      ".MuiFormControlLabel-label": {
        color: "#FFF",
        fontFamily: "CaustenR",
        // fontSize:"1.5625rem",
        fontWeight: "700",
        // lineHeight:"1.5625rem",
        fontSize: "clamp(0.9375rem, 3vw, 1.5625rem)",
        lineHeight: "clamp(0.9375rem, 3vw, 1.5625rem)",
        "@media screen and (max-width:600px)": {
          // fontSize: '0.9375rem',
          // fontWeight: '700',
          // lineHeight: '0.9375rem',
        },
      },
    }}
    value={value}
    control={
      <Radio
        sx={{
          color: "#FFF",
          "&.Mui-checked": {
            color: "#fdb913",
          },
        }}
      />
    }
    label={label}
  />
);

const NameAgeComponent = ({ proceedCallback }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [age, setAge] = useState("");
  const [avatar, setAvatar] = useState("");
  const [avatarPrevId, setAvatarPrevId] = useState(-1);
  const [nameReadOnlyFlag, setNameReadOnlyFlag] = useState(false);
  const [emailReadOnlyFlag, setEmailReadOnlyFlag] = useState(false);
  const [buttonEnableFlag, setButtonEnableFlag] = useState(true);
  const { setRoomId, setLoadingDisplayFlag, isLoggedIn, setNotificationType, setNotificationMessage, setNotificationDisplayFlag } = useContext(Context);
  const setAlert = (type, message) => {
    setNotificationType(type)
    setNotificationMessage(message)
    setNotificationDisplayFlag(true)
  }
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const styledButton = {
    padding: isMobile ? "0.8rem 2rem" : "2.2rem 7rem",
    color: "#fff",
    marginTop: "auto",
    // fontSize:isMobile? "0.875rem" : "2.125rem",
    fontSize: "clamp(0.875rem, 2.36vw, 2.125rem)",
    letterSpacing: isMobile ? "0.5px" : "1px",
    // lineHeight: isMobile? "2.125rem" : "0.875rem",
    lineHeight: "clamp(0.875rem, 2.36vw, 2.125rem)",
    textAlign: "center",
    fontFamily: "CaustenR",
    fontWeight: "700",
    borderRadius: "10px",
    marginBottom: "2.5rem",
  };

  useEffect(() => {
    // Enable Proceed button when age and name fields are not empty
    if (
      age.trim() !== "" &&
      name.trim() !== "" &&
      email.trim() !== "" &&
      validateEmail(email.trim()) &&
      (avatarPrevId !== -1 || avatar !== "")
    )
      setButtonEnableFlag(false);
    else setButtonEnableFlag(true);
  }, [age, name, email, avatarPrevId]);

  useEffect(() => {
    //set name for logged in users
    if (isLoggedIn) {
      const url = BEURL + apiEndpoints.getProfile;
      setLoadingDisplayFlag(true);
      axios
        .get(url)
        .then((res) => {
          setName(res.data["username"] || "");
          res.data["username"] && setNameReadOnlyFlag(true) // Make name input field readonly
          setEmail(res.data["email"] || "");
          res.data["email"] && setEmailReadOnlyFlag(true) // Make email input field readonly
          setAge(res.data["custom:age_group"] || "");
          setAvatar(res.data["picture"] || "");
          setLoadingDisplayFlag(false);
        })
        .catch((err) => console.log(err));
    }
    //scroll bar style
    let main = document.getElementById("main");
    main.classList.add("main-green");
    return () => {
      //scroll bar style
      let main = document.getElementById("main");
      main.classList.remove("main-green");
    };
  }, []);

  const handleAgeChange = (event) => {
    setAge(event.target.value);
  };

  const handleNameInput = (event) => {
    setName(event.target.value);
  };

  const handleEmailInput = (event) => {
    setEmail(event.target.value);
  };

  const handleOnClick = () => {
    setRoomId(null);
    navigate(-1);
  };

  const handleSelectAvatar = (e) => {
    let id = e.target.id;
    setAvatarPrevId((prevId) => {
      if (prevId !== -1) {
        //first time there's no selected avatar; so don't have to remove anything
        let prevElement = document.getElementById(prevId);
        prevElement.classList.remove("nameAge__avatar__selected");
      }
      let currElement = document.getElementById(id);
      currElement.classList.add("nameAge__avatar__selected");
      return id;
    });
  };

  return (
    <div className="nameAge">
      <TitleComponent title="Play Quiz" handleOnClick={handleOnClick} />
      <NavMenuComponent />
      <div className="nameAge__details">
        <label style={{ fontWeight: "700" }}>NAME</label>
        <input
          name="name"
          type="text"
          value={name}
          readOnly={nameReadOnlyFlag}
          onChange={handleNameInput}
        />
      </div>
      <div className="nameAge__details">
        <label style={{ fontWeight: "700" }}>EMAIL</label>
        <input
          name="name"
          type="text"
          value={email}
          readOnly={emailReadOnlyFlag}
          onChange={handleEmailInput}
        />
        <span className="nameAge__errorMessage" style={{display: (validateEmail(email.trim()))?"none":"inline"}}>Enter a valid email</span>
      </div>
      <div className="nameAge__details marginTop">
        <label style={{ fontWeight: "700" }}>AGE</label>
        <RadioGroup
          aria-label="quiz"
          name="quiz"
          onChange={handleAgeChange}
          value={age}
          row
          sx={{ marginTop: "0.8rem", rowGap: "1rem", columnGap: "10%" }}
        >
          <RadioBtn label={"6-8 YEARS"} value={"6 to 8"} />
          <RadioBtn label={"9-17 YEARS"} value={"9 to 14"} />
          <RadioBtn label={"18+ YEARS"} value={"9 to 14 "} />
          {/* need to change the value for 18+  */}
        </RadioGroup>
      </div>
      {(!isLoggedIn || avatar === "") && (
        <div className="nameAge__details marginTop">
          <label style={{ fontWeight: "700" }}>SELECT AVATAR</label>
          <div className="nameAge__avatar">
            {[0, 1, 2, 3, 4].map((num) => (
              <img
                id={num}
                key={num}
                onClick={handleSelectAvatar}
                src={`/images/avatar${num}.svg`}
              />
            ))}
          </div>
        </div>
      )}

      <div className="nameAge__button">
        <Button
          onClick={() => {
            if (!isLoggedIn || avatar === "") {
              setAvatar(
                `${process.env.REACT_APP_S3_PROFILE_IMAGE_URL}avatar${avatarPrevId}.svg`
              );
              proceedCallback(name, email, age, avatar);
            }
            proceedCallback(
              name,
              email,
              age,
              `${process.env.REACT_APP_S3_PROFILE_IMAGE_URL}avatar${avatarPrevId}.svg`
            );
          }}
          disabled={buttonEnableFlag}
          style={{
            ...styledButton,
            backgroundColor: buttonEnableFlag ? "grey" : "#fdb913",
          }}
        >
          PROCEED
        </Button>
      </div>
    </div>
  );
};

export default NameAgeComponent;
