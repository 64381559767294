import * as React from 'react';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import Context from '../../../context/Context';
import "./QuizNavBarComponent.scss";

const QuizNavBar = ({pageName}) => {

  const {mobileLeaderBoardOpen, setMobileLeaderBoardOpen, quizPage } = React.useContext(Context);
  const handleLeaderBoard = () =>{
    setMobileLeaderBoardOpen(true)
  }

  const handleQuizPage = () => {
    setMobileLeaderBoardOpen(false)
  }

  const navBarButton = {    
    flexGrow: '1',
    flexBasis: '0',     
    fontFamily: "CaustenEB",
    fontSize: '14px',
    letterSpacing: '0.031rem',
    "&:focus": {
      backgroundColor: "#007FB3",
      color: "#FDB913",
    }, 
    "@media only screen and (min-height: 900px)": {
      fontSize: '20px',
    },    
  }

  return (
    <Box component="div"  className='quizNavBar'
    sx={{
      display: 'flex', 
      marginTop:'0.5rem',
      marginBottom:'1rem',   
      maxHeight:"2.4rem" ,
      // width: pageName==="QUIZ" ? "80%" : "auto"  
      width:pageName? "90%" : "auto"
    }}> 
      <Button className="quizNavBar_button"
      sx ={{
        ...navBarButton,
        backgroundColor: mobileLeaderBoardOpen? "#0097D4" : "#007FB3",
        color:mobileLeaderBoardOpen? "#fff" : "#FDB913",
        borderRadius: "0.625rem 0rem 0rem 0.625rem"/* top left, top right, bottom right, bottom left */  

      }} 
      onClick={handleQuizPage} >
        {pageName? pageName: (quizPage==="answer")? `ANSWER` : `QUESTION`}
      </Button>  

      <Button className="quizNavBar_button"
      sx={{
        ...navBarButton,
        backgroundColor: mobileLeaderBoardOpen? "#007FB3" : "#0097D4",
        color:mobileLeaderBoardOpen? "#FDB913" : "#fff",
        borderRadius: "0rem 0.625rem 0.625rem 0rem"/* top left, top right, bottom right, bottom left */,
        fontFamily: "CaustenEB"
      }} 
      onClick={handleLeaderBoard}>
        Scores
      </Button>
    </Box>
  );
}

export default QuizNavBar