import { RadioGroup } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BEURL } from "../../api/BaseUrl";
import apiEndpoints from "../../api/EndPoints";
import Context from "../../context/Context";
import CustomModalWithTitleComponent from "../../shared/customModalComponent/CustomModalWithTitleComponent";
import FillButtonComponent from "../../shared/fillButtonComponent/FillButtonComponent";
import InputComponent from "../../shared/inputComponent/InputComponent";
import MediaUploadComponent from "../createQuizComponents/mediaUploadComponent/MediaUploadComponent";
import DashboardDrawerComponent from "../dashboardDrawerComponent/DashboardDrawerComponent";
import { RadioBtn } from "../editProfileComponent/EditProfileComponent";
import WelcomeBannerComponent from "../welcomeBannerComponent/WelcomeBannerComponent";
import { dashboard_welcomebanner_description } from "../../copies";
import "./DashboardComponent.scss";
import { transformQuizData, TilesCarouselComponent } from "../tilesCarouselComponent/TilesCarouselComponent";
import ViewAllTilesComponent from "../../shared/viewAllTilesComponent/viewAllTilesComponent";

const DashboardComponent = () => {
  const navigate = useNavigate();

  const {
    setLoadingDisplayFlag,
    setNotificationType,
    setNotificationMessage,
    setNotificationDisplayFlag,
  } = useContext(Context);
  const setAlert = (type, message) => {
    setNotificationType(type);
    setNotificationMessage(message);
    setNotificationDisplayFlag(true);
  };
  const [dashboardData, setDashboardData] = useState({
    myQuizzes: [],
  });
  const [openCreateNewQuiz, setOpenCreateNewQuiz] = useState(false);
  const [openQuizDetails, setOpenQuizDetails] = useState(false);
  const [openAttachTags, setOpenAttachTags] = useState(false);
  const [quizName, setQuizName] = useState("");
  const [description, setDescription] = useState("");
  const [ageGroup, setAgeGroup] = useState("6 to 8");
  const [isDisabled, setIsDisabled] = useState(false);
  const [imageURL, setImageURL] = useState("");
  const [activePage, setActivePage] = useState("welcomePage")

  const handleOnClickViewChange = (viewName) => () => {
    setActivePage(viewName)
  }

  const goToQuizDetails = () => {
    setOpenCreateNewQuiz(false);
    setOpenAttachTags(false);
    setOpenQuizDetails(true);
  };
  useEffect(() => {
    setLoadingDisplayFlag(true);
    const url = BEURL + apiEndpoints.getDashboard;
    axios
      .post(url)
      .then((res) => {
        setDashboardData({ myQuizzes: transformQuizData(res.data.myQuizzes) });
      })
      .catch((error) => setAlert("error", error.response.data))
      .finally(() => setLoadingDisplayFlag(false));
  }, []);

  useEffect(() => {
    setIsDisabled([quizName, description].indexOf("") > -1)
  }, [quizName, description])

  const goToAttachTags = () => {
    setOpenAttachTags(true);
    setOpenQuizDetails(false);
  };

  const createQuiz = () => {
    setLoadingDisplayFlag(true);
    const url = BEURL + apiEndpoints.createQuiz;
    const data = {
      quizName: quizName,
      quizDescription: description,
      ageGroup: ageGroup,
      quizImage: imageURL,
    };
    axios
      .post(url, data)
      .then((res) => navigate(`/createquiz/${res.data["quizId"]}`))
      .catch((error) => {
        alert(error.response.data);
        setLoadingDisplayFlag(false);
      })
      .finally(() => {
        console.log("finally");
        setLoadingDisplayFlag(false);
      });
  };

  const placeholder_image = "/images/upload_media_green_placeholder_image.png";

  return (
    <div className="dashboard">
      <DashboardDrawerComponent selectedView="dashboard" />
      <div className="dashboard__content">
        {/* Display dashboard home page  */}
        {activePage === "welcomePage"? (<><div className="dashboard__header">
          <WelcomeBannerComponent
            description={dashboard_welcomebanner_description}
            buttonText="CREATE NEW QUIZ"
            handleOnClick={() => setOpenCreateNewQuiz(true)}
          />
          <div className="dashboard__header__welcomebanner__image">
            <img
              src="/images/QT_Dashboard.svg"
              alt="welcome banner"
              width="400px"
              height="300px"
            />
          </div>
        </div>

        <div className="dashboard__body">
          <TilesCarouselComponent
            title="My Quizzes"
            tilesData={dashboardData.myQuizzes}
            splitData={false}
            placeholder="You don't have any quizzes, try creating one!"
            enableMenuOptions={true}
            handleOnClickViewAll={handleOnClickViewChange("viewAllTiles")}
          />
        </div>

        <CustomModalWithTitleComponent
          title="Create new quiz"
          open={openCreateNewQuiz}
          handleClose={() => setOpenCreateNewQuiz(false)}
          bgColor="#75B543"
          customStyles={{ overflow: "hidden", width: "auto" }}
        >
          <div className="createNewQuiz">
            <h5 className="createNewQuiz__subtitle">
              Select one of the following to get started
            </h5>
            <div className="createNewQuiz__options">
              <div
                className="createNewQuiz__options__left"
                onClick={goToQuizDetails}
              >
                <p className="createNewQuiz__options__title">Start Fresh</p>
                <p className="createNewQuiz__options__description">
                  Start from scratch and customise everything to your wants and
                  needs.
                </p>
              </div>
              <div className="createNewQuiz__options__right" onClick={() => alert("Feature under development. Coming soon!")}>
                <p className="createNewQuiz__options__title">
                  Build On A Template
                </p>
                <p className="createNewQuiz__options__description">
                  Select from a universe of quizzes and build on an existing
                  template. 
                  <br />
                  (Under Construction)
                </p>
              </div>
            </div>
          </div>
        </CustomModalWithTitleComponent>

        <CustomModalWithTitleComponent
          title="Quiz Details"
          open={openQuizDetails}
          handleClose={() => setOpenQuizDetails(false)}
          bgColor="#4B9710"
        >
          <div className="quizDetails">
            <div className="quizDetails__details">
              <InputComponent
                Type="text"
                Label="Quiz Name (Upto 100 Letters)"
                Value={quizName}
                Maxlength={100}
                OnChange={(e) => setQuizName(e.target.value)}
              />
              <InputComponent
                Type="text"
                Label="Description (Upto 200 Letters)"
                Value={description}
                Maxlength={200}
                OnChange={(e) => setDescription(e.target.value)}
              />
              {/* <div className='quizDetails__details__timelimit'>
              <label >Set quiz time limit?</label>        
              <RadioGroupx
                aria-label="quiz"
                name="quiz"
                value={isTimeLimit}
                onChange={(e) => setIsTimeLimit(e.target.value)}
                row
                sx = {{rowGap: "0.5rem", columnGap: "%"}}
              >                       
                <RadioBtn label={"Yes"} value={true} />
                <RadioBtn label={"No"} value={false}/>        
              </RadioGroup>  
            </div> */}
            </div>
            <div className="quizDetails__bannerUpload">
              <MediaUploadComponent
                imageURL={imageURL}
                setImageURL={setImageURL}
                placeholderimage={placeholder_image}
              />
            </div>
          </div>
          <div className="quizDetails__proceedbtn">
            <FillButtonComponent
              isDisabled={isDisabled}
              displayText="PROCEED"
              handleClick={goToAttachTags}
            />
          </div>
        </CustomModalWithTitleComponent>

        <CustomModalWithTitleComponent
          title="Attach Tags"
          open={openAttachTags}
          handleClose={() => setOpenAttachTags(false)}
          handleBackButton={goToQuizDetails}
          bgColor="#4B9710"
        >
          <div className="column attachtags">
            <label className="editprofile__content__label">Age</label>
            <RadioGroup
              aria-label="quiz"
              name="quiz"
              value={ageGroup}
              onChange={(e) => setAgeGroup(e.target.value)}
              row
              sx={{ rowGap: "0.5rem", columnGap: "%" }}
            >
              <RadioBtn label={"6-8 YEARS"} value={"6 to 8"} />
              <RadioBtn label={"9-17 YEARS"} value={"9 to 14"} />
              <RadioBtn label={"18+ YEARS"} value={"9 to 14 "} />
            </RadioGroup>
            <div className="quizDetails__proceedbtn">
              <FillButtonComponent
                displayText="CONTINUE BUILDING QUIZ"
                handleClick={createQuiz}
              />
            </div>
          </div>
        </CustomModalWithTitleComponent></>)
        :<ViewAllTilesComponent 
              title="My Quizzes"
              subtitle="List of published and draft quizzes."
              tilesData={dashboardData.myQuizzes} 
              splitData={false}
              enableMenuOptions={true}
              handleOnClickBack={handleOnClickViewChange("welcomePage")} />}
      </div>
    </div>
  );
};

export default DashboardComponent;
