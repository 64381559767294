import "./CreateFIBComponent.scss";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { BEURL, S3URL } from "../../../api/BaseUrl";
import apiEndpoints from "../../../api/EndPoints";
import Context from "../../../context/Context";
import BlueInputComponent from "../blueInputComponent/BlueInputComponent";
import CreateNonQuestionComponent from "../createNonQuestionComponent/CreateNonQuestionComponent";
import DiscardSlideComponent from "../discardSlideComponent/DiscardSlideComponent";
import MediaUploadComponent from "../mediaUploadComponent/MediaUploadComponent";
import QuestionDetailsComponent from "../questionDetailsComponent/QuestionDetailsComponent";
import SaveordiscardComponent from "../saveordiscardComponent/SaveordiscardComponent";
import { get_answer_blanks_with_hint_letter } from "../../../shared/constants";

const CreateFIBComponent = ({
  questionData,
  quizid,
  setComponentInFocus,
  questionType,
}) => {
  const media_upload_placeholder_image =
    "/images/upload_media_placeholder_image.png";
  //states
  const [question, setQuestion] = useState("");
  const [questionImageURL, setQuestionImageURL] = useState("");
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [openQuestionDetails, setOpenQuestionDetails] = useState(false);
  const [time, setTime] = useState(30);
  const [points, setPoints] = useState(100);
  const [openDiscardConfirmation, setOpenDiscardConfirmation] = useState(false);
  const [showAnswerSlide, setShowAnswerSlide] = useState(false);
  const [answerImageURL, setAnswerImageURL] = useState("");
  const [answerContent, setAnswerContent] = useState("");
  const [answer, setAnswer] = useState("");
  //when navigated after clicking on the miniSlides
  useEffect(() => {
    if (Object.keys(questionData).length === 0) return; //dont execute if questionData is empty
    questionData.questionTitle && setQuestion(questionData.questionTitle);
    questionData.correctAnswer &&
      setAnswer(eval(questionData.correctAnswer)[0]);
    questionData.s3ImageName &&
      setQuestionImageURL(S3URL + "readymade/" + questionData.s3ImageName);
    questionData.timeToAnswer && setTime(questionData.timeToAnswer);
    setPoints(questionData.maxPoints??100);
    questionData.s3AnswerImageName &&
      setAnswerImageURL(S3URL + "readymade/" + questionData.s3AnswerImageName);
    questionData.answerContent ? setAnswerContent(questionData.answerContent) : setAnswerContent("");
    //show question slide
    setShowAnswerSlide(false);
  }, [questionData]);

  const { setLoadingDisplayFlag, editQuestionsList, editQuizDetails, setEditQuestionsList, setEditQuizDetails, setNotificationType, setNotificationMessage, setNotificationDisplayFlag, setQuestionData } = useContext(Context);
  
  const setAlert = (type, message) => {
    setNotificationType(type);
    setNotificationMessage(message);
    setNotificationDisplayFlag(true);
  }
  
  //Add question
  const saveQuestion = (data, isEdit) => {
    const url = isEdit
      ? BEURL + apiEndpoints.editQuestion
      : BEURL + apiEndpoints.addQuestion;
    axios
      .post(url, JSON.stringify(data))
      .then((res) => {
        let editedQuestions = res.data;
        editedQuestions = editedQuestions.map(question => {
          if (question.questionType === "CNT" || question.questionType === "FIB") {
            question.answerBlanks = get_answer_blanks_with_hint_letter(eval(question.correctAnswer)[0]);
          }
          return question;
        });
        setEditQuestionsList(editedQuestions);
        if (isEdit) {
          let editedQuestion = editedQuestions.find((question) => {
            return data.questionId === question.questionId;
          });
          setQuestionData(editedQuestion); 
        }
        let newQuestionIdList = editedQuestions.map(question=>question.questionId)
        setEditQuizDetails((prevQuiz) => {
          let updatedQuiz = { ...prevQuiz };
          updatedQuiz.questionIds = newQuestionIdList
          return updatedQuiz
        })
        if (res.status === 200) {
          if (isEdit) {
            setAlert("success", "Question Edited Successfully");
          }
          else {
            setAlert("success", "Question Added Successfully");
          }
        }
      })
      .catch((error) => alert(error.response.data))
      .finally(() => {
        setLoadingDisplayFlag(false);
        if (isEdit) {
          //if question was edited dont go to new slide
          setShowAnswerSlide(false); //show the question slide
          setOpenQuestionDetails(false); //close the question details pop up
        } //if new question was added
        else setComponentInFocus("SelectSlideComponent"); //show new slide
      });
  };

  //Save question
  const handleSaveQuestion = () => {
    setLoadingDisplayFlag(true);
    const data = {
      quizId: quizid,
      questionType: questionType,
      questionTitle: question,
      timer: time,
      questionImage: questionImageURL,
      answerImage: answerImageURL,
      correctAnswer: [answer],
      maxPoints: points,
      answerContent: answerContent,
    };
    //Add questionId for edited question
    if (!!questionData.questionId) data.questionId = questionData.questionId;
    //If the question was already present send a edit request
    //Else add new question
    saveQuestion(data, !!questionData.questionId);
  };
  //Add answer content to states
  const handleSubmitAnswer = (imageURL, answerContent) => {
    setAnswerImageURL(imageURL);
    setAnswerContent(answerContent);
    setOpenQuestionDetails(true);
  };
  //Prevent premature form submissions
  useEffect(
    () =>
      setIsSaveDisabled(
        answer === "" || question === ""
      ),
    [answer, question]
  );

  return (
    <>
      <QuestionDetailsComponent
        open={openQuestionDetails}
        handleClose={() => setOpenQuestionDetails(false)}
        handleAddQuestion={handleSaveQuestion}
        editQuestonFlag={!!questionData.questionId} // True if questionId exists
        time={time}
        setTime={setTime}
        points={points}
        setPoints={setPoints}
      />
      <DiscardSlideComponent
        open={openDiscardConfirmation}
        handleClose={() => setOpenDiscardConfirmation(false)}
        handleDiscardSlide={() => setComponentInFocus("SelectSlideComponent")}
      />
      {showAnswerSlide ? (
        <CreateNonQuestionComponent
          questionTitle={question}
          questionImageURL={questionImageURL}
          info={answerContent}
          handleSubmitAnswer={handleSubmitAnswer}
          setComponentInFocus={setComponentInFocus}
        />
      ) : (
        <div className="createMCQ createFIB">
          <div className="createMCQ__inner">
            {questionType === "CNT" && (
              <p className="questionTitle">
                Connect Question({question.length}/300 characters)
              </p>
            )}
            {questionType === "FIB" && (
              <p className="questionTitle">
                Fill in the blanks Question({question.length}/300 characters)
              </p>
            )}
            <BlueInputComponent
              Value={question}
              OnChange={(e) => setQuestion(e.target.value)}
              Maxlength="300"
            />
            <div className="createMCQ__inner__image">
              <MediaUploadComponent
                placeholderimage={media_upload_placeholder_image}
                imageURL={questionImageURL}
                setImageURL={setQuestionImageURL}
              />
            </div>
            <p className="questionTitle">
              Answer({answer.length}/25 characters)
            </p>
            <div className="createFIB__answer">
              <BlueInputComponent
                Value={answer}
                OnChange={(e) => setAnswer(e.target.value)}
                Maxlength="25"
              />
            </div>
          </div>
          <SaveordiscardComponent
            isSaveDisabled={isSaveDisabled}
            handleSave={() => setShowAnswerSlide(true)}
            handleDiscard={() => setOpenDiscardConfirmation(true)}
          />
        </div>
      )}
    </>
  );
};

export default CreateFIBComponent;
