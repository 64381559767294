import React from 'react'
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useMediaQuery, useTheme } from "@mui/material";
import {MdArrowBackIosNew} from "react-icons/md";


function CustomModalWithTitleComponent({ open, handleClose, title, handleBackButton, bgColor, children, width="60%", customStyles={} }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isTablet ? "90%" : width,
    bgcolor: bgColor|| "#FDB913",
    color: "white",
    borderRadius: "1rem",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    p: 4,    
    outline: "none",
    ...customStyles,
    "& > .outer":{
        width: "100%",    
        "& > .heading": {        
            display:"flex",
            flexDirection: "row",
            justifyContent:"space-between",
            alignItems: "center",
            width:"100%",
            height: isMobile ? "1.125rem" : "2rem",
            "& > .clickable": {
                cursor: "pointer",
                height: isMobile ? "1.125rem" : "2rem",
                width: isMobile ? "1.125rem" : "2rem",
            },
            "& > img": {
                cursor: "pointer",
                height: "80%",
                width: "auto",
            },
        },
    },
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className='outer'>
        <div className='heading'>
            <MdArrowBackIosNew className="clickable"
                style={{visibility: handleBackButton? "visible": "hidden"}} 
                size={22} 
                onClick={handleBackButton}
            />
            <h1>{title}</h1>
            <img
                style={{visibility: handleClose? "visible": "hidden"}}
                src="/images/Cross.png"
                alt="Cross"
                onClick={handleClose}
            />            
        </div>    
        {children}   
        </div>
      </Box>
    </Modal>
  );
}

export default CustomModalWithTitleComponent;
