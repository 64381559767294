import React, { useRef } from 'react'
import "./GetReadyRoomComponent.scss"
import { Button, CircularProgress } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import Context from "./../../context/Context";
import { useNavigate } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import TitleComponent from '../../shared/titleComponent/TitleComponent';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import TimerComponent from '../quizComponent/timerComponent/TimerComponent';
import MobileTimerComponent from '../quizComponent/mobileTimerComponent/MobileTimerComponent';
import ExitWarning from '../../shared/exitWarning/ExitWarning';
import { BEURL } from '../../api/BaseUrl';
import apiEndpoints from '../../api/EndPoints';
import axios from 'axios';

const useStyles = makeStyles({
  button: {
    padding: "1rem 3.5rem",
    fontFamily: "CaustenR",
    fontSize:"1.8rem",
    color: "white",
    borderRadius: "10px",
    "@media only screen and (max-width: 900px)": {
      fontSize: "1rem",
      fontFamily: "CaustenEB",
      padding: "0.8rem 1.2rem",
    },
    "@media only screen and (max-width: 600px)": {
      fontSize: "0.875rem",
      fontFamily: "CaustenEB",
      padding: "0.8rem 2rem",
    }
  },

  disableButton: {
    padding: "1rem 3.5rem",
    border: "1px solid grey",
    fontFamily: "CaustenR",
    fontSize:"1.8rem",
    color: "grey",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#d3d3d3",
      color: "white",
      borderColor: "#d3d3d3",
    },
    cursor: "not-allowed",
    "@media only screen and (max-width: 900px)": {
      fontSize: "1rem",
      fontFamily: "CaustenEB",
      padding: "0.8rem 1.2rem",
    },
    "@media only screen and (max-width: 600px)": {
      fontSize: "0.875rem",
      fontFamily: "CaustenEB",
      borderRadius: "10px",
      padding: "0.8rem 2rem",
    }
  }
})

const GetReadyRoomComponent = () => {
  const {
    userName,
    questionData,
    answerData,
    quizPage,
    host,
    podiumPage,
    isLoggedIn
  } = useContext(Context);
  const maxCounterTime = 3
  const [counter, setCounter] = useState(maxCounterTime);
  const navigate = useNavigate()

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));  

  const initialRender = useRef(true);

  const [exitModalOpen, setExitModalOpen] = useState(false);

//******** To handle browser default actions for back and reload buttons - Start *******/
  const [finishStatus, setfinishStatus] = useState(false);

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
        if (window.confirm("Do you want to leave the game?")) {
            setfinishStatus(true)
            navigate("/");
        } else {
            window.history.pushState(null, null, window.location.pathname);
            setfinishStatus(false)
        }
    }
  }

  // Assumption: player lands on this page then player has begun playing a game
  useEffect(() => {
    isLoggedIn && axios.get(
      BEURL + apiEndpoints.updateUserStats,
      {
        params:{ action : "play"}
      }
    )    
  }, [])
  

  const onReloadButtonEvent = (e) => {
    e.preventDefault();
    return e.returnValue = "Data will be lost if you leave the page, are you sure?";        
  };

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', onBackButtonEvent);
    window.addEventListener('beforeunload', onReloadButtonEvent);
    return () => {
      window.removeEventListener('popstate', onBackButtonEvent);  
      window.removeEventListener('beforeunload', onReloadButtonEvent);
    };
  }, []);

  //******** To handle browser default actions for back and reload buttons - End *******/

  const goToPodium = () => {
    if (podiumPage) {
      navigate("/podium");
    }
  }

  useEffect(() => {
    goToPodium()
  }, [podiumPage]);
  
  // Send to home page if username is not set
  const goToHome = ()=> {
    if (userName === ""){
      navigate("/")
    }
  }

  useEffect(() => {
    goToHome()
  },[questionData, answerData])


  // Send to quiz page if questionData or answerData exists
  const goToQuiz = () => {
    if ((!(Object.keys(questionData).length === 0 && questionData.constructor === Object) || !(Object.keys(answerData).length === 0 && answerData.constructor === Object))) {
      navigate('/quiz')
    }
  }

  //Run countdown
  useEffect(() => {
    if (quizPage==="question") {
      const timer = setTimeout(() => {
        if (counter > 0)
        setCounter(prevState => prevState-1);
        else if (counter == 0)
        goToQuiz()
      }, 1000);
      return () => {
        clearTimeout(timer);
      };
    }
  },[counter])

  useEffect(() => {
    if (quizPage==="question" && !initialRender.current) {
      goToQuiz();
    }
    initialRender.current = false;
  }, [quizPage])

  const handleBackClick = () => {
    if (host) {
      navigate("/quizselection");
    } else {
      setExitModalOpen(true);
    }
  }

  return (
    <div className="getreadyroom">      
      <TitleComponent title="Get Ready!" handleOnClick={handleBackClick} align="left" style={{marginRight: "auto", marginLeft:isTablet ? "14%" : '10%'}}/>
      <div className="getreadyroom__countDown">
        <img src="/images/qTNoPlayers.png" alt="qTNoPlayers" />
        {(quizPage==="question") && <div className="getreadyroom__linearTimer">
          <TimerComponent maximumTime={maxCounterTime} 
                          progress={maxCounterTime-counter} 
                          getReadyRoom={true} />
        </div>}
        {(quizPage==="question") && <div className="getreadyroom__circularTimer">
          <MobileTimerComponent maximumTime={maxCounterTime} 
                              progress={maxCounterTime-counter}  
                              displayValue={counter?counter:"GO"} 
                              dialSize="7rem"
                              textSize='2rem' />
        </div>}
        {(quizPage==="answer") && <CircularProgress size="7rem" style={{ color: "#FDB913" }} />}
        {(quizPage==="question") && <p className='getreadyroom__countDownText'>{counter ? counter : "GO"}</p>}
        {(quizPage==="answer") && <p className='getreadyroom__waitingText'>{"Thanks for joining! Please wait for the host to share the next question."}</p>}
      </div>

      {/* <div className="getreadyroom__buttonContainer">
        <Button
          onClick={startQuiz}
          className={classes.button}
          style={{
            backgroundColor:"#fdb913",
          }}
        >
          START QUIZZING
        </Button>
      </div> */}
      <ExitWarning modalOpen={exitModalOpen} setModalOpen={setExitModalOpen} />
    </div>
  );
};

export default GetReadyRoomComponent;
