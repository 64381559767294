import React, { useContext, useEffect, useState } from "react";
import "./quizSelectionComponent.scss";
import FillButtonComponent from "../../shared/fillButtonComponent/FillButtonComponent";
import NavMenuComponent from "../../shared/navMenuComponent/NavMenuComponent";
import TitleComponent from "../../shared/titleComponent/TitleComponent";
import DisplayQuizzesComponent from "./DisplayQuizzesComponent";
import { useNavigate } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import Context from "../../context/Context";
import ExitWarning from "../../shared/exitWarning/ExitWarning";
import SearchFilterComponent from "./searchFilterComponent/SearchFilterComponent";
import axios from "axios";
import { BEURL, WSURL } from "../../api/BaseUrl";
import apiEndpoints from "../../api/EndPoints";
import ScheduleQtestComponent from "./scheduleQtestComponent/ScheduleQtestComponent";
import ChoicePopupComponent from "../../shared/choicePopupComponent/ChoicePopupComponent";
import PlayQuizModalComponent from "../../shared/playQuizModalComponent/PlayQuizModalComponent";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    marginBottom: "1rem",
    "@media only screen and (max-width: 900px)": {
      marginBottom: "0rem",
    },
  }
}));

const QuizSelectionComponent = () => {
  const [readyMade, setReadyMade] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [playQuizModalOpen, setPlayQuizModalOpen] = useState(false);
  const [interestBased, setInterestBased] = useState(false);
  const [buttonEnableFlag, setButtonEnableFlag] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const navigate = useNavigate();
  const {
    socket,
    setHost,
    setUserName,
    setRoomId,
    setSocket,
    isLoggedIn,
    setNotificationType,
    setNotificationMessage,
    setNotificationDisplayFlag,
    setLoadingDisplayFlag,
  } = useContext(Context);
  const [exitModalOpen, setExitModalOpen] = useState(false);
  const [scheduleQtestModalOpen, setScheduleQtestModalOpen] = useState(false);
  const [showPlayQuizPopup, setShowPlayQuizPopup] = useState(false);

  const setAlert = (type, message) => {
    setNotificationType(type);
    setNotificationMessage(message);
    setNotificationDisplayFlag(true);
  };

  const classes = useStyles()

  // Handle host if refreshed in this page. Set host to true if sessionId and isHost exists in 
  useEffect(() => {
    if(sessionStorage.getItem("sessionId") && sessionStorage.getItem("isHost") && sessionStorage.getItem("name")){
      setHost(true)
      setUserName(sessionStorage.getItem("name"))
      if(localStorage.getItem('isLoggedIn')==="false"){ // Set new session id for players who have non logged in.
        sessionStorage.setItem("sessionId", Math.random().toString(36).slice(-10))
      }
    }
  }, [])
  

  const handleBackArrowClick = () => {
    if (interestBased || readyMade) {
      setReadyMade(false);
      setInterestBased(false);
      setSelectedCategories([]);
    } else {
      setExitModalOpen(true);
    }
  };
  const handleReadyMadeClick = () => {
    setReadyMade(true);
    setInterestBased(false);
    setSelectedCategories([]);
  };
  const handleInterestBasedClick = () => {
    setReadyMade(false);
    setInterestBased(true);
    setSelectedCategories([]);
  };

  useEffect(() => {
    if (readyMade) {
      if (selectedCategories.length == 1) {
        setShowPlayQuizPopup(true);
      } else {
        setShowPlayQuizPopup(false);
      }
    }
    if (interestBased) {
      if (selectedCategories.length > 0) {
        setButtonEnableFlag(false);
      } else {
        setButtonEnableFlag(true);
      }
    }

  }, [selectedCategories]);

  const handlePlayQtestClick = () => {
    setScheduleQtestModalOpen(true);
  }
  const handleClosePlayQuizPopup = () => {
    setSelectedCategories([])
    setShowPlayQuizPopup(false);
  }

  const handleQtestCreate = (startDate, endDate, showAnswer, showResult, additionalFields) => {
    let qtestType = readyMade ? "RM" : "IB";
    //Private quiz id is uuid so length is 36
    // if (selectedCategories[0].toString().length > 30) qtestType = "PV";
    setLoadingDisplayFlag(true);
    const url = BEURL + apiEndpoints.createQtest;
    const data = {
      qtestType: qtestType,
      categories: selectedCategories,
      showAnswer: showAnswer,
      showResult: showResult,
      startDate: startDate,
      endDate: endDate,
      additionalFields: additionalFields,
    };
    axios
      .post(url, data)
      .then((res) => {
        if(res.data.status=="Success"){
          setAlert("success", "QTest created successfully");
          navigate("/shareqtest/" + res.data.qtestId);
        }
        else if(res.data.status=="Error"){
          setAlert("info", res.data.errorMessage);
        }
        else{
          setAlert("error", "Something went wrong. Kindly try again later.");
        }
      })
      .catch((error) => {
        setAlert("error", error.response.data);
      })
      .finally(()=>setLoadingDisplayFlag(false));
  }

  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!finishStatus) {
      if (window.confirm("Do you want to leave the game?")) {
        setfinishStatus(true);
        navigate("/");
      } else {
        window.history.pushState(null, null, window.location.pathname);
        setfinishStatus(false);
      }
    }
  };

  const onReloadButtonEvent = (e) => {
    e.preventDefault();
    return (e.returnValue =
      "Data will be lost if you leave the page, are you sure?");
  };

  const [finishStatus, setfinishStatus] = useState(false);

  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    window.addEventListener("beforeunload", onReloadButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
      window.removeEventListener("beforeunload", onReloadButtonEvent);
    };
  }, []);

  return (
    <div className="quizSelectionComponent">
      {readyMade || interestBased || <NavMenuComponent />}
      {readyMade || interestBased || (
        <TitleComponent
          title="Select Quiz"
          handleOnClick={handleBackArrowClick}
        />
      )}
      <div className="quizSelectionComponent__quizChoice">
        <div
          className={`quizSelectionComponent__readyMade ${
            readyMade
              ? "quizSelectionComponent__selected"
              : interestBased
              ? "quizSelectionComponent__unselected"
              : ""
          } `}
        >
          {readyMade || interestBased || (
            <div className="quizSelectionComponent__wrapper">
              <div className="quizSelectionComponent__buttonWrapper">
                <FillButtonComponent
                  displayText={"Ready-Made"}
                  handleClick={handleReadyMadeClick}
                />
              </div>
              <div className="quizSelectionComponent__description">
                <p>
                  Choose from a host of ready-made quizzes on different topics
                  to kickstart the quizzing fever
                </p>
              </div>
            </div>
          )}
          {interestBased && (
            <div
              className="quizSelectionComponent__sidewaysText"
              onClick={handleReadyMadeClick}
            >
              Ready-Made
            </div>
          )}
          {readyMade && (
            <>
              <div className="quizSelectionComponent__container">
                <TitleComponent
                  title="Ready-Made"
                  handleOnClick={handleBackArrowClick}
                />
                <SearchFilterComponent
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  filteredCategories={filteredCategories}
                  setFilteredCategories={setFilteredCategories}
                />
                <div className="quizSelectionComponent__quizList" classes={classes}>
                  <DisplayQuizzesComponent
                    quiztype={"readymade"}
                    selectedCategories={selectedCategories}
                    setSelectedCategories={setSelectedCategories}
                    searchTermTofilter={searchTerm}
                    categoriesToFilter={filteredCategories}
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <div
          className={`quizSelectionComponent__interestBased ${
            interestBased
              ? "quizSelectionComponent__selected"
              : readyMade
              ? "quizSelectionComponent__unselected"
              : ""
          } `}
        >
          {readyMade || interestBased || (
            <div className="quizSelectionComponent__wrapper">
              <div className="quizSelectionComponent__buttonWrapper">
                <FillButtonComponent
                  displayText={"Interest-Based"}
                  handleClick={handleInterestBasedClick}
                />
              </div>
              <div className="quizSelectionComponent__description">
                <p>
                  Select one or more topics from a bank of categories to create
                  a unique quiz that caters to your interests
                </p>
              </div>
            </div>
          )}
          {readyMade && (
            <div
              className="quizSelectionComponent__sidewaysText"
              onClick={handleInterestBasedClick}
            >
              Interest-Based
            </div>
          )}
          {interestBased && (
            <>
              <div className="quizSelectionComponent__container">
                <TitleComponent
                  title="Interest-Based"
                  handleOnClick={handleBackArrowClick}
                />
                <div className="quizSelectionComponent__quizList">
                  <DisplayQuizzesComponent
                    selectedCategories={selectedCategories}
                    setSelectedCategories={setSelectedCategories}
                  />
                </div>
              </div>
              <div className="quizSelectionComponent__proceedButton">
                <FillButtonComponent
                  isDisabled={buttonEnableFlag}
                  displayText={"Play Quiz"}
                  handleClick={() => setPlayQuizModalOpen(true)}
                />
                {(socket == null) && ( // Don't display QTest if user comesback to change quiz in readymade
                  <Tooltip title={isLoggedIn? "" : "You must be logged in to create a QTest"} arrow>
                    <span>
                      <FillButtonComponent
                        isDisabled={buttonEnableFlag || !isLoggedIn}
                        displayText={"Schedule Quiz"}
                        handleClick={()=>{setScheduleQtestModalOpen(true)}}
                      />
                    </span>
                  </Tooltip>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <ExitWarning modalOpen={exitModalOpen} setModalOpen={setExitModalOpen} />
      <ScheduleQtestComponent modalOpen={scheduleQtestModalOpen} setModalOpen={setScheduleQtestModalOpen} handleClick={handleQtestCreate} />
      <PlayQuizModalComponent 
        modalOpen={playQuizModalOpen} 
        setModalOpen={setPlayQuizModalOpen} 
        readyMadeQuizFlag={readyMade}
        selectedCategories={selectedCategories}
      />
      <ChoicePopupComponent
        showPopup={showPlayQuizPopup}
        popupTitle={"Select Quiz Mode"}
        // popupDescription={"Choose the mode in which you want to play the quiz"}
        popupDescription={""}
        choiceOneText={"Play Quiz"}
        choiceOneDescription="All participants play the quiz at the same time!"
        choiceTwoText={"Schedule Quiz"}
        choiceTwoDescription="Schedule a period within which participants can take the quiz anytime."
        handleChoiceOne={()=>{setPlayQuizModalOpen(true)}}
        handleChoiceTwo={handlePlayQtestClick}
        handleClosePopup={handleClosePlayQuizPopup}
      />
    </div>
  );
};

export default QuizSelectionComponent;
