import React from "react";
import "./QtestFooterComponent.scss";
import TimerComponent from "../../quizComponent/timerComponent/TimerComponent";

const QtestFooterComponent = ({maxTime=30, timerProgress, showTimer=true}) => {
 
  const timerToDisplay = () => {
    const displayValue = maxTime - timerProgress < 0 ? 0 : maxTime - timerProgress;

    //Spliting time to be displayed in minutes and seconds
    const mins = Math.floor(displayValue / 60);
    const secs = displayValue % 60;

    //Adding 0 to all values below 10. Ex: 07, 02 etc..
    if (secs >= 10) {
      return (
        mins + ":" + secs
      );
    } else {
      return (
        mins + ":0" + secs
      );
    }
  };

  return (
    <div className="qtestfooter" style={{ width: "100vw" }}>
      {showTimer && 
        <>
          <TimerComponent maximumTime={maxTime} progress={timerProgress} />
          <div className="qtestfooter__iconContainer">
            <p>{timerToDisplay()}</p>
          </div>
        </>
      }
    </div>
  );
};

export default QtestFooterComponent;
