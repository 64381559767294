import "./SearchFilterComponent.scss"
import React, { useContext, useEffect, useState } from 'react'
import { Autocomplete, Chip, Grid, Paper, selectClasses, TextField } from "@mui/material"
import { makeStyles } from "@mui/styles";
import InputComponent from "../../../shared/inputComponent/InputComponent";
import { BEURL } from "../../../api/BaseUrl";
import apiEndpoints from "../../../api/EndPoints";
import Context from "../../../context/Context";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
    inputRoot: {
      color: "white",
      fontFamily: "CaustenM",
      fontSize: "1.375rem",
      
      "& .MuiChip-root": {
        fontFamily: "CaustenR",
        color: "#FFF",
        backgroundColor: "#75B543",
        height: "1.2rem",
        "& .MuiChip-label": {
            paddingLeft: "8px",
        }
      },
      "& .MuiSvgIcon-root": {
        color: "#FFCBD1",
        fontSize: "15px",
        "&:hover": {
            color: "#FDB913"
        }
      },
      "& .MuiAutocomplete-input": { // input field size
        width: "clamp(8rem, 12rem, 16rem) !important",
        height: "0.65rem",
        // "@media only screen and (max-width: 1200px)": {
        //     width: "15rem !important",
        // },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "10px",
        border: "1px solid #FFF",
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderRadius: "10px",
        border: "1px solid #FFF",
      },
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderRadius: "10px",
        border: "1px solid #FFF",
      }
    }
  }));

const SearchFilterComponent = ({searchTerm, setSearchTerm, filteredCategories, setFilteredCategories}) => {
    const classes = useStyles();
    const [dbCategories, setDbCategories] = useState([])
    
    const { setNotificationType, setNotificationMessage, setNotificationDisplayFlag, setLoadingDisplayFlag } = useContext(Context)

    const setAlert = (type, message) => {
        setNotificationType(type)
        setNotificationMessage(message)
        setNotificationDisplayFlag(true)
    }

    useEffect(() => {
        setLoadingDisplayFlag(true)
        axios
            .post(BEURL + apiEndpoints.categories)
            .then((res) => {
                // console.log(res.data);
                setDbCategories(res.data.allCategories) 
            })
            .catch((error) => {
                setAlert("error", String(error.message))
            })
    }, [])

    return(
        <div className="searchfilter">
            <div className="searchfilter__content">
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md:4}}>
                    <Grid item xs={12} lg={6}>
                        <div className="searchfilter__content__item">
                            <label className="searchfilter__content__label">Search </label>
                            <InputComponent 
                                ClassName="searchfilter__content__input"
                                Maxlength="20"                      
                                Type="text" 
                                Value={searchTerm} 
                                OnChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <div className="searchfilter__content__item">
                            <label className="searchfilter__content__label" >Categories</label>
                            <Autocomplete   
                            classes={classes}                              
                            options={dbCategories}
                            getOptionLabel={(option) => option.categoryName}
                            autoComplete
                            multiple
                            limitTags={2}
                            value={filteredCategories}
                            onChange={(e, newValue) => {
                                setFilteredCategories(newValue)
                            }}
                            PaperComponent={({ children }) => (
                                <Paper 
                                    style={{ 
                                    background: "#75B543", 
                                    color: "#FFF", 
                                    fontFamily: "CaustenM",
                                    fontSize: "1.375rem",
                                    letterSpacing: "0.0313rem",
                                    lineHeight: "1.375rem", 
                                    borderRadius: "10px",
                                    border: "1px solid #FFF",
                                    marginBottom:"1rem",
                                    marginTop:"1rem",
                                    overflowY: "hidden",
                                    }}>{children}</Paper>
                            )}
                            renderTags={(tagValue, getTagProps) =>
                                tagValue.map((option, index) => (
                                  <Chip
                                    style={{
                                        backgroundColor:"75B543",
                                        color:"ffffff"
                                    }}
                                    label={option.categoryName}
                                    {...getTagProps({ index })}
                                  />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField variant="outlined"
                                {...params} />
                            )}
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default SearchFilterComponent;