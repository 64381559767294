import React, { useContext,useEffect } from 'react'
import "./MainComponent.scss"
import HeaderComponent from "../headerComponent/HeaderComponent"
import HomeComponent from "../homeComponent/HomeComponent";
import PlayerDetailsComponent from "../playerDetailsComponent/playerDetailsComponent/PlayerDetailsComponent";
import WaitingRoomComponent from "../waitingRoomComponent/WaitingRoomComponent";
import GetReadyRoomComponent from "../getReadyRoomComponent/GetReadyRoomComponent";
import QuizComponent from "../quizComponent/quizComponent/QuizComponent";
import PodiumComponent from "../podiumComponent/PodiumComponent";
import { Routes, Route, useParams, Navigate, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import {BEURL, WSURL} from "./../../api/BaseUrl";
import apiEndpoints from "./../../api/EndPoints";
import Context from "../../context/Context";
import posthog from 'posthog-js';
import QuizSelectionComponent from '../quizSelectionComponent/QuizSelectionComponent';
import ProfileComponent from '../profileComponent/ProfileComponent';
import EditProfileComponent from '../editProfileComponent/EditProfileComponent';
import RedirectComponent from '../redirectComponent/RedirectComponent';
import SignInComponent from '../signInComponent/SignInComponent';
import SignUpComponent from '../signUpComponent/SignUpComponent';
import { ForgotPasswordComponent } from '../forgotPasswordComponent/ForgotPasswordComponent';
import { ConfirmEmailComponent } from '../confirmEmailComponent/ConfirmEmailComponent';
import { StaticComponent } from '../staticComponent/StaticComponent';
import PricingComponent from '../pricingComponent/PricingComponent';
import NameEmailComponent from '../qtestComponent/nameEmailComponent/NameEmailComponent';
import ShareQtestComponent from '../shareQtestComponent/ShareQtestComponent';
import QtestComponent from '../qtestComponent/QtestComponent';
import EmbedComponent from '../embedComponent/EmbedComponent'
import DashboardComponent from '../dashboardComponent/DashboardComponent';
import CreateQuizComponent from '../createQuizComponents/createQuizComponent/CreateQuizComponent';
import MyLibraryComponent from '../myLibraryComponent/MyLibraryComponent';
import ReportsComponent from '../reportsComponent/ReportsComponent';
import QuizPreviewComponent from '../quizPreviewComponent/QuizPreviewComponent';
import CreateUserComponent from '../adminComponent/createUserComponent/CreateUserComponent';

const WithRoomId = () => {
    
    const [searchParams,setSearchParams] = useSearchParams();
    if(searchParams.get("utm_source")=="share"){
        posthog.init('phc_yXBbeJ5no5BsmwcVgAIApBT3lcAlWDg91nQqX91woIv', { api_host: 'https://app.posthog.com' });
    }
    else if(searchParams.get("utm_source")=="whatsapp"){
        posthog.init('phc_yXBbeJ5no5BsmwcVgAIApBT3lcAlWDg91nQqX91woIv', { api_host: 'https://app.posthog.com' });
    }
    else if(searchParams.get("utm_source")=="facebook"){
        posthog.init('phc_yXBbeJ5no5BsmwcVgAIApBT3lcAlWDg91nQqX91woIv', { api_host: 'https://app.posthog.com' });
    }
    else if(searchParams.get("utm_source")=="twitter"){
        posthog.init('phc_yXBbeJ5no5BsmwcVgAIApBT3lcAlWDg91nQqX91woIv', { api_host: 'https://app.posthog.com' });
    }

    const { socket, setSocket, setLoadingDisplayFlag, setHost, setRoomId, setNotificationType, setNotificationMessage, setNotificationDisplayFlag} = useContext(Context);
    // setAlert to modify notifications before display
    const setAlert = (type, message) => {
        setNotificationType(type)
        setNotificationMessage(message)
        setNotificationDisplayFlag(true)
    }
    const navigate = useNavigate();
    sessionStorage.removeItem("isHost");
    let {roomId} = useParams();
    roomId = roomId.trim().toLowerCase();

    const isUserProfileAvailable = () => {
        axios.get(BEURL + apiEndpoints.getProfile).then((res) => { 
            setLoadingDisplayFlag(true)
            let username = res.data['username'] || ""
            let email = res.data['email'] || ""
            let age = res.data['custom:age_group'] || ""
            let avatar = res.data['picture'] || ""
            if (![username, age, avatar].includes("")) { //if either of them are empty get player details
              //set items in storage
              sessionStorage.setItem("ageGroup", age);
              sessionStorage.setItem("name", username);
              sessionStorage.setItem("email", email);
              sessionStorage.setItem("avatar", avatar);
              //else createinvite
              if (socket == null) {
                const socket = new WebSocket(`${WSURL}/ws/quiz/${roomId}/`)
                setSocket(socket)
              }  
              return true
            }else{
                return false
            } 
          }).catch((err) => console.log(err)).finally(() => setLoadingDisplayFlag(false))  
    }

    if(roomId?.length !== 6){
        setAlert("error", "Room ID must be 6 characters in length. Quiz link is invalid.")
        return <Navigate replace to="/home" />
    }
    else {
        setLoadingDisplayFlag(true)
        axios
            .post(
                BEURL + apiEndpoints.checkRoomId,
                JSON.stringify({
                    roomId: roomId
                })
            )
            .then((res) => {
                setLoadingDisplayFlag(false)
                if(res.data.roomIdExistsFlag){
                    setHost(false)
                    setRoomId(roomId)
                    if (isUserProfileAvailable()){
                        navigate("/quizselection") //pre-fill user data and move to quiz selecyion
                    }else{
                        navigate('/joinroom', {state:{roomId: roomId}}) //else get user data in join room
                    }                       
                    return 0
                }else{
                    setAlert("error", "Invalid Quiz link.")
                    return <Navigate replace to="/home" />
                }
            })
            .catch((error) => {
                setLoadingDisplayFlag(false)
                setAlert("error", String(error.message))
                return <Navigate replace to="/home" />
            })
        return <Navigate replace to="/home" />
    }
}

const SignInWrapperComponent = () => {
    // let {roomId} = useParams();
    // roomId = roomId.trim().toLowerCase();
    const { state } = useLocation();
    // const { redirectFlag } = state;
    if(state?.redirectFlag){
        return <SignInComponent redirectFlag={true} />
    }
    else{
        return <SignInComponent />
    }
}

const JoinRoom = () => {
    const { state } = useLocation();
    if(state){
        const { roomId } = state;
        return <PlayerDetailsComponent urlRoomId={roomId} />
    }
    else{
        return <Navigate replace to="/home" />
    }
}

function MainComponent(){
    const {answerData, questionData} = useContext(Context)

    useEffect(() => {
      posthog.init('phc_yXBbeJ5no5BsmwcVgAIApBT3lcAlWDg91nQqX91woIv', { api_host: 'https://app.posthog.com' });
      
    },[])
    
    return(
        <div className='main' id="main">
            <HeaderComponent />
            <Routes>
                <Route exact path ="/home" element={<HomeComponent />} />
                <Route exact path ="/" element={<HomeComponent />} />
                <Route exact path ="/playerdetails" element={<PlayerDetailsComponent urlRoomId="" />} />
                <Route exact path ="/joinroom" element={<JoinRoom />} />
                <Route exact path ="/quizselection" element={<QuizSelectionComponent />} />
                <Route exact path ="/waitingroom" element={<WaitingRoomComponent />} />
                <Route exact path ="/shareqtest/:qtestId" element={<ShareQtestComponent />} />
                <Route exact path ="/qtest/:qtestId" element={<NameEmailComponent />} />
                <Route exact path ="/qtest" element={<QtestComponent />} />
                <Route exact path ="/getreadyroom" element={<GetReadyRoomComponent />} />
                <Route exact path ="/quiz" element={((!(Object.keys(questionData).length === 0 && questionData.constructor === Object) || !(Object.keys(answerData).length === 0 && answerData.constructor === Object))) ? <QuizComponent /> : <Navigate replace to="/" />} />                
                <Route exact path ="/podium" element={<PodiumComponent />} />
                <Route exact path ="/confirmemail/:username" element={<ConfirmEmailComponent />} />
                <Route exact path ="/confirmemail" element={<ConfirmEmailComponent />} />                              
                <Route exact path ="/profile" element={< ProfileComponent />} /> 
                <Route exact path ="/editprofile" element={< EditProfileComponent />} /> 
                <Route exact path ="/redirect/:action" element={<RedirectComponent />} />
                <Route exact path ="/signin" element={<SignInWrapperComponent />} />
                <Route exact path ="/signup" element={<SignUpComponent />} />
                <Route exact path ="/forgotpassword" element={<ForgotPasswordComponent />} />
                <Route exact path ="/termsandconditions" element={<StaticComponent />} />
                <Route exact path ="/:roomId" element={<WithRoomId />} />  
                <Route exact path ="/pricing" element={<PricingComponent />} />
                <Route exact path ="/embed/:embedType/:qtestId" element={<EmbedComponent />} />
                <Route exact path ="/dashboard" element={<DashboardComponent />} />
                <Route exact path ="/library" element={<MyLibraryComponent />} />
                <Route exact path ="/reports" element={<ReportsComponent />} />
                <Route exact path ="/createquiz/:quizid" element={<CreateQuizComponent />} />
                <Route exact path ="/previewquiz" element={<QuizPreviewComponent />} />
                <Route exact path ="/admin" element={<CreateUserComponent />} />
            </Routes>
        </div>
    )
}

export default MainComponent