import React, { useState } from "react";
import InviteModalComponent from "./InviteModalComponent";
import { AiOutlineShareAlt } from "react-icons/ai";
import { IconButton } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const InviteModalWrapper = ({ waitingRoom, qtestId }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const styleIconButton = {
        border: "2px solid #fff",
        color: "white",
        width: isMobile ? "2.5rem" : "3.8rem",
        borderRadius: "10px",
    };
    return (
        <>
            {waitingRoom ? (
                <IconButton style={styleIconButton} onClick={handleOpen}>
                    <AiOutlineShareAlt size={35} />
                </IconButton>
            ) : (
                <div className="podium__iconText" onClick={handleOpen}>
                    <img src="/images/InviteIcon.png" />
                    <p>INVITE FRIENDS</p>
                </div>
            )}

            <InviteModalComponent open={open} handleClose={handleClose} qtestId={qtestId} />
        </>

    );
}

export default InviteModalWrapper;