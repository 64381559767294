import React, { useContext, useState } from "react";
import CustomModalComponent from "../customModalComponent/CustomModalComponent";
import { Button, Switch } from "@mui/material";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import { BEURL, WSURL } from "../../api/BaseUrl";
import apiEndpoints from "../../api/EndPoints";
import Context from "../../context/Context";
import { useLocation, useNavigate } from "react-router-dom";

import "./PlayQuizModalComponent.scss";

const useStyles = makeStyles({
  button: {
    padding: "0.5rem 1.5rem",
    fontFamily: "CaustenB",
    fontSize: "1.2rem",
    fontWeight: "700",
    color: "white",
    borderRadius: "10px",
    "@media only screen and (max-width: 900px)": {
      fontSize: "1rem",
      fontWeight: "400",
      fontFamily: "CaustenEB",
      padding: "0.8rem 1.2rem",
    },
    "@media only screen and (max-width: 600px)": {
      fontSize: "0.875rem",
      fontWeight: "400",
      fontFamily: "CaustenB",
      padding: "0.8rem 2rem",
    },
  },

  disableButton: {
    padding: "1rem 3.5rem",
    border: "1px solid grey",
    fontFamily: "CaustenR",
    fontSize: "1.2rem",
    fontWeight: "700",
    color: "grey",
    borderRadius: "10px",
    "&:hover": {
      backgroundColor: "#d3d3d3",
      color: "white",
      borderColor: "#d3d3d3",
    },
    cursor: "not-allowed",
    "@media only screen and (max-width: 900px)": {
      fontSize: "1rem",
      fontFamily: "CaustenEB",
      padding: "0.8rem 1.2rem",
    },
    "@media only screen and (max-width: 600px)": {
      fontSize: "0.875rem",
      fontWeight: "400",
      fontFamily: "CaustenR",
      padding: "0.8rem 2rem",
    },
  },
});

const PlayQuizModalComponent = ({
  modalOpen,
  setModalOpen,
  readyMadeQuizFlag,
  selectedCategories,
}) => {
  const [showAnswer, setShowAnswer] = useState(true);
  const [randomizeQuestion, setRandomizeQuestion] = useState(false);
  const [hostMode, setHostMode] = useState(false);

  const {
    setNotificationType,
    setNotificationMessage,
    setNotificationDisplayFlag,
    setLoadingDisplayFlag,
    setHost,
    setRoomId,
    socket,
    setSocket,
    setQuizType,
  } = useContext(Context);

  const classes = useStyles();

  const navigate = useNavigate();
  const location = useLocation();

  const setAlert = (type, message) => {
    setNotificationType(type);
    setNotificationMessage(message);
    setNotificationDisplayFlag(true);
  };

  // Wait until websocket is open (readystate=1), before sending the message.
  const waitForConnection = (socket, message, interval) => {
    if (socket.readyState === 1) {
      socket.send(message);
    } else {
      setTimeout(() => {
        waitForConnection(socket, message, interval);
      }, interval * 2);
    }
  };

  const updateQuiz = (socket) => {
    let sQuizType = readyMadeQuizFlag ? "Ready Made" : "Interest Based";
    waitForConnection(
      socket,
      JSON.stringify({
        action: "updateQuiz",
        categories: selectedCategories,
        quizType: sQuizType,
      }),
      1000
    );
    setQuizType(sQuizType);
    navigate("/waitingroom" , { state: { backToPath: location.pathname } });
  };

  const handlePlayQuizClick = () => {
    setModalOpen(false);
    setLoadingDisplayFlag(true);
    if (socket == null) {
      axios
        .post(BEURL + apiEndpoints.createInvite, {
          quizId: selectedCategories[0],
          showAnswer,
          randomizeQuestion,
          hostMode,
        })
        .then((res) => {
          if (res.data.status === "Success") {
            setRoomId(res.data.roomId);
            const ws_socket = new WebSocket(
              `${WSURL}/ws/quiz/${res.data.roomId}/`,
            );
            setHost(true);
            sessionStorage.setItem("isHost", true);
            setSocket(ws_socket);
            updateQuiz(ws_socket);
          } else if (res.data.status === "Error") {
            setAlert("info", res.data.errorMessage);
          } else {
            setAlert("error", "Something went wrong. Kindly try again later.");
          }
        })
        .catch((error) => {
          setAlert("error", error.response.data);
        })
        .finally(() => setLoadingDisplayFlag(false));
    } else {
      setLoadingDisplayFlag(false);
      updateQuiz(socket);
    }
  };

  return (
    <CustomModalComponent
      open={modalOpen}
      handleClose={() => setModalOpen(false)}
      bgColor="#0097D4">
      <div className="playQuiz__modal">
        <h1>Host Control</h1>
        <div className="playQuiz__hostControl">
          {/* <div className="playQuiz__hostControl__row">
            <p className="hostControl__label">Show Answers</p>
            <Switch
              checked={showAnswer}
              onChange={(e) => setShowAnswer(e.target.checked)}
              sx={{
                "&.MuiSwitch-root .MuiSwitch-switchBase": {
                  color: "#FFCBD1",
                },

                "&.MuiSwitch-root .Mui-checked": {
                  color: "#ECA904",
                },
              }}
            />
          </div> */}
          <div className="playQuiz__hostControl__row">
            <p className="hostControl__label">Randomize Questions</p>
            <Switch
              checked={randomizeQuestion}
              onChange={(e) => setRandomizeQuestion(e.target.checked)}
              sx={{
                "&.MuiSwitch-root .MuiSwitch-switchBase": {
                  color: "#FFCBD1",
                },
                "&.MuiSwitch-root .Mui-checked": {
                  color: "#FDB913",
                },
              }}
            />
          </div>
          <div className="playQuiz__hostControl__row">
            <p className="hostControl__label">Host Mode</p>
            <Switch
              checked={hostMode}
              onChange={(e) => setHostMode(e.target.checked)}
              sx={{
                "&.MuiSwitch-root .MuiSwitch-switchBase": {
                  color: "#FFCBD1",
                },
                "&.MuiSwitch-root .Mui-checked": {
                  color: "#FDB913",
                },
              }}
            />
          </div>
        </div>
        <Button
          onClick={handlePlayQuizClick}
          className={classes.button}
          style={{
            backgroundColor: "#FDB913",
          }}>
          Join Quiz
        </Button>
      </div>
    </CustomModalComponent>
  );
};

export default PlayQuizModalComponent;
