import { createStyles, FormControlLabel, Radio } from '@mui/material'
import {makeStyles} from '@mui/styles'
import React from 'react'

const GreenTick = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7.5" cy="7.5" r="7.5" fill="#75B543"/>
    <path d="M11.7273 5L5.72727 11L3 8.27273" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

const useStyles = makeStyles(theme =>
  createStyles({
    smallRadioButton: {
      "& svg": {
        width: "1rem",
        height: "1rem"
      }
    }
  })
);

const RadioBtnTicked = ({value, isDisabled}) => {
  const classes = useStyles();
  return(
    <FormControlLabel 
      value={value} 
      className={classes.smallRadioButton}  
      disabled = {isDisabled}    
      control={ <Radio 
        disableRipple
        checkedIcon={<GreenTick />}
        />} 
    />
  )}

export default RadioBtnTicked