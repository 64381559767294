import { Tooltip } from '@mui/material'
import React from 'react'
import { FaCrown, FaLock } from 'react-icons/fa'
import "./QuizTile.scss"

const QuizTile = ({id, label, backgroundImage, selectedCategories, tooltipText, handleOnClick, isLocked=false, isPremium=false }) => {
  return (
    <div className='quizTile'>
      <Tooltip title={tooltipText} arrow>
        <div
            className="quizTile__tile"
            onClick={handleOnClick}
            // disabled={disabled}
            style={{
                background: backgroundImage,
                backgroundSize: "cover",
                backgroundColor: selectedCategories.includes(id)
                    ? "rgba(255,255,255,0.9)"
                    : "rgba(0,0,0,0.4)",
                backgroundBlendMode: selectedCategories.includes(id)
                    ? "lighten" //If selected
                    : isLocked 
                      ? "overlay" //If locked
                      : "normal",    //If not locked and not selected
                color: selectedCategories.includes(id)
                    ? "#30b2e7"
                    : "white",
            }}
        >
          {/* {isLocked && <FaLock className='quizTile__icon' />} */}
          {isPremium ? <FaCrown className='quizTile__icon'/> : isLocked && <FaLock className='quizTile__icon' />}
          <label>{label}</label> 
        </div>
      </Tooltip>
    </div>
    
  )
}

export default QuizTile