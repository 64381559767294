import React, { useContext, useEffect } from "react";
import "./QuestionStatsComponent.scss";
import BarChartComponent from "../../../shared/barChartComponent/BarChartComponent";
import Context from "../../../context/Context";
import { useNavigate } from 'react-router-dom';
import FillButtonAnimateComponent from "../../../shared/fillButtonAnimateComponent/FillButtonAnimateComponent";

const QuestionStatsComponent = ({
  barChartData,
  handleNextQuestion
}) => {
  const { playerAnswerCount, playerCorrectAnswerCount, podiumPage, leaderboardData, questionData, noOfQuestions } = useContext(Context);

  // Navigate to Podium when page is set from the backend
  const navigate = useNavigate()
  const goToPodium = () => {
    if (podiumPage) {
      navigate("/podium");
    }
  }
  useEffect(() => {
    goToPodium()
  }, [podiumPage]);

  const correctAnswerPercentage = playerAnswerCount
    ? (playerCorrectAnswerCount / playerAnswerCount) * 100
    : 0;

  const resultMessage =
    correctAnswerPercentage > 50
      ? `Easy question, ${correctAnswerPercentage}% got it right`
      : `Tricky question, ${correctAnswerPercentage}% got it right`;
    
  const indexToRemove = barChartData.labels.indexOf('X');

  if (indexToRemove !== -1) {
    barChartData.labels.splice(indexToRemove, 1);
    barChartData.toolTipMessages.splice(indexToRemove, 1);
    barChartData.values.splice(indexToRemove, 1);
  }
  
  return (
    <div className="questionStatsComponent">
      <h1>Question Stats</h1>
      <div className="questionStatsComponent__container">
        <div className="questionStatsComponent__container__graph">
          <BarChartComponent barChartData={barChartData} />
        </div>
        <div className="questionStatsComponent__container__data">
          <p>{resultMessage}</p>
          <p className="questionStatsComponent__container__data__attemptedText">
            {playerAnswerCount}/{leaderboardData?.noOfActivePlayers} attempted the question
          </p>
          <div className="quizComponent__nextBtnFooter">
            <FillButtonAnimateComponent
              displayText={
                (questionData?.questionNumber === noOfQuestions ? "End Quiz" : "Next Question")
              }
              handleClick={handleNextQuestion} 
              duration={(leaderboardData?.noOfActivePlayers > 1) ? 5 : 0} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionStatsComponent;
