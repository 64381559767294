import React from "react";

const QuizArrangeDragArrange = ({ correctAnswer }) => {

    const correctAnswerList = eval(correctAnswer);
    // console.log(correctAnswerList);

    return (
        <div className="quizDragArrange__droppable_column" style={{ width: "80%", marginTop: "2rem", pointerEvents: "none" }}>
            {
                correctAnswerList.map((element, index) => (
                    <div key={index} className="quizDragArrange__item_wrapper">
                        <span>{index + 1}</span>
                        <div className="quizDragArrange__item quizDragArrange__item_answer">
                            {element}
                        </div>
                    </div>
                ))
            }
        </div>
    );
}

export default QuizArrangeDragArrange;