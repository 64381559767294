import React, { useEffect } from 'react'
import "./QuizFIBComponent.scss"
import { useState } from "react"
import FillButtonComponent from '../../../shared/fillButtonComponent/FillButtonComponent'
import QuizBlanksComponent from '../quizBlanksComponent/QuizBlanksComponent'

const QuizFIBComponent = ({handleFIBAnswer, answerBlanks}) => {
    const [answer, setAnswer] = useState(answerBlanks)
    const [answerSubmitted, setAnswerSubmitted ] = useState(false)
    const [allowSubmission, setAllowSubmission ] = useState(false)
    const [wordIndexes, setWordIndexes] = useState([])

    const handleClick = () => {
        setAllowSubmission(false)
        setAnswerSubmitted(true)
        handleFIBAnswer(answer.join("")) //convert array of characters to string
    }    
    
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            if (allowSubmission) {
                handleClick();
            }
        }
    };

    useEffect(() => {
        setAnswer(answerBlanks)
    }, [answerBlanks])
    

    useEffect(() => { 
        // Find first and last index of all words in the answer (using answerBlanks) and store it in wordIndexes
        let wordIdxs = []
        let firstIdx = 0
        let lastIdx = answerBlanks.indexOf(" ") // Get end index of first word
        while(lastIdx !== -1){
            wordIdxs.push([firstIdx, lastIdx])
            firstIdx = lastIdx+1
            lastIdx = answerBlanks.indexOf(" ", firstIdx)
        }
        wordIdxs.push([firstIdx, answerBlanks.length-1])
        setWordIndexes(wordIdxs)
    }, [answerBlanks])

    return(
        <div className='quizFIB' onKeyDown={handleKeyDown}>        
            <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap', gap: "1rem"}}>
                <QuizBlanksComponent 
                    answerBlanks = {answer}
                    setAnswerBlanks = {setAnswer}
                    answerSubmitted = {answerSubmitted}
                    setAllowSubmission = {setAllowSubmission}
                    wordIndexes = {wordIndexes}
                />
            </div>      
            <div className='quizFIB__submitButton'>
                <FillButtonComponent
                    displayText="SUBMIT"
                    handleClick = {handleClick}
                    isDisabled = {!allowSubmission}
                />
            </div>
        </div>
    )
}

export default QuizFIBComponent