import "./SaveordiscardComponent.scss"
import React from 'react'
import FillButtonComponent from "../../../shared/fillButtonComponent/FillButtonComponent"

const SaveordiscardComponent = ({isSaveDisabled, handleSave, handleDiscard}) => {
  const style ={
    activeSaveStyle:{
      width: "220px",
      fontSize:"22px",
      paddingTop:"8px",
      paddingBottom:"8px"
    },
    inactiveSaveStyle:{
      width: "220px",
      background: "#222222",
      opacity: "0.2",
      color:"white",
      fontSize:"22px",
      paddingTop:"8px",
      paddingBottom:"8px"
    },
    discardStyle:{
      width: "220px",
      border:"2px solid #FFFFFF",
      background: "transparent",
      fontSize:"22px",
      paddingTop:"8px",
      paddingBottom:"8px"

    }
  }
  return (
    <div className="saveordiscard">
      <FillButtonComponent
        displayText="SAVE SLIDE"
        handleClick={handleSave}
        style={isSaveDisabled?style.inactiveSaveStyle:style.activeSaveStyle}
        isDisabled={isSaveDisabled}
        
      />
      <FillButtonComponent
        displayText="DISCARD"
        handleClick={handleDiscard}
        style={style.discardStyle}
      />
    </div>
  )
}

export default SaveordiscardComponent