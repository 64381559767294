import React from 'react'
import { Box, Grid, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles";

const DisplayGridComponent = ({childComponentList}) => {
  const theme = useTheme();  
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ width: isMobile ? "85%" : "90%", margin: "2rem 0" }}>
      <Grid
        container
        rowSpacing={isMobile ? "1.875rem" : "2.8438rem"}
        columnSpacing="1.25rem"
        direction="row"
        justifyContent="center"
      >
        {childComponentList?.map((component,idx) => (
          <Grid item xs={6} sm={4} key={idx}>
            {component}
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}

export default DisplayGridComponent