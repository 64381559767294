import React, { useEffect, useState } from 'react'
import "./StaticComponent.scss"
import apiEndpoints from '../../api/EndPoints'
import { BEURL } from "../../api/BaseUrl"
import axios from 'axios'

export const StaticComponent = () => {
  const [response, setResponse] = useState("<br>")
  useEffect(() => {
    axios.get(BEURL+ apiEndpoints.termsAndConditions).then((response) => setResponse(response.data)).catch((err) => alert(err))
  }, [])
  
  return (
    <div className='static' dangerouslySetInnerHTML={{__html:response}}/>
  )
}
