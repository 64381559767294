import React, { useState, useEffect } from "react";
import QuizImageComponent from './../quizImageComponent/QuizImageComponent'
import QuizVideoComponent from './../quizVideoComponent/QuizVideoComponent'
import QuizAudioComponent from './../quizAudioComponent/QuizAudioComponent'
import { BsExclamationTriangle } from "react-icons/bs";
import { Tooltip } from "@mui/material";

// Determine the media type based on the file extension
const getMediaType = (url) => {
    // For media just uploaded and result read as url
    var uploadFileRegexPattern = /^data:(video|audio|image)\//;
    if(uploadFileRegexPattern.test(url)){
      // Extract the media type from the url using regex exec function
      return uploadFileRegexPattern.exec(url)[1];
    }
    else{
      if(!url){
        return 'noMedia';
      }
      // Find imagename from url
      const urlImageName = url?.split('/').pop()
      if(!urlImageName.includes(".")){
        return 'noMedia';
      }
      // Find extension from urlImageName 
      const fileExtension = urlImageName?.split('.').pop().toLowerCase();
      if (['mp4', 'webm', 'ogg', 'avi', 'mov'].includes(fileExtension)) {
        return 'video';
      } else if (['mp3', 'wav', 'ogg', 'flac', 'mpeg'].includes(fileExtension)) {
        return 'audio';
      } else if (['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(fileExtension)) {
        return 'image';
      } else {
        return 'unsupported';
      }
    }
};

const UnsupportedMediaComponent = ({slideType="normal"}) => {
  return (
      <div className="audioMedia">
        {slideType=="normal" && (
          <Tooltip title="Unsupported File" placement="top" arrow>
              <div>
                  <BsExclamationTriangle size="12rem"/>
              </div>
          </Tooltip>
        )}
        {slideType=="mini" && (<BsExclamationTriangle size="3rem"/>)}
      </div>
    );
}

const QuizMediaComponent = ({ mediaUrl, mediaTypeParam=null, width="auto", height="auto", slideType="normal", onMediaClick=()=>{} }) => {
    const [mediaType, setMediaType] = useState("loading");

    useEffect(() => {
        if(mediaTypeParam){
            setMediaType(mediaTypeParam)
        }
        else{
            setMediaType(getMediaType(mediaUrl))
        }
    }, [mediaUrl, mediaTypeParam])
    
    return (
        <>
            { (mediaType==='image') && <QuizImageComponent imageUrl={mediaUrl} width={width} height={height} /> }
            { (mediaType==='video') && <QuizVideoComponent videoUrl={mediaUrl} width={width} height={height} slideType={slideType}/> }
            { (mediaType==='audio') && <QuizAudioComponent audioUrl={mediaUrl} width={width} height={height} slideType={slideType}/> }
            { (mediaType==='noMedia') && <img
                src="/images/QT-Question-Flip.png"
                width={width}
                height={height}
                onClick={onMediaClick}
                alt="quiz image"
            /> }
            { (mediaType==='unsupported') && <UnsupportedMediaComponent slideType={slideType}/> }
            { (mediaType==='loading') && "Loading..." }
        </>
  );
};

export default QuizMediaComponent;