import React from 'react';
import "./PlayerDetailsComponent.scss";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import Context from "../../../context/Context";
import axios from "axios"
import { BEURL, WSURL } from "./../../../api/BaseUrl";
import apiEndpoints from "./../../../api/EndPoints";
import "./PlayerDetailsComponent.scss"

import NameAgeComponent from "./../nameAgeComponent/NameAgeComponent";

const PlayerDetailsComponent = ({ urlRoomId }) => {
    const [playerName, setPlayerName] = useState("")
    const [playerEmail, setPlayerEmail] = useState("")
    const [ageGroup, setAgeGroup] = useState("")
    const [sessionId, setSessionId] = useState(Math.random().toString(36).slice(-10))
    const { socket,
        setSocket,
        host,
        roomId,
        setRoomId,
        setLoadingDisplayFlag,
        setUserName,
        setNotificationType,
        setNotificationMessage,
        setNotificationDisplayFlag,
        podiumPage,
        isLoggedIn
    } = useContext(Context)
    const navigate = useNavigate()

    // setAlert to modify notifications before display
    const setAlert = (type, message) => {
        setNotificationType(type)
        setNotificationMessage(message)
        setNotificationDisplayFlag(true)
    }

    useEffect(() => {
        if (!host && !urlRoomId) {
            navigate("/home")
        }
        if (isLoggedIn){
            setSessionId(sessionStorage.getItem("sessionId"))
        }
    }, [])

    useEffect(() => { // This useEffect will run for players/invitees joining
        if (socket !== null && !host) {
            sessionStorage.setItem("sessionId", sessionId)
            setLoadingDisplayFlag(false)
            if (podiumPage) {
                navigate("/podium");
            } else {
                navigate("/waitingroom")
            }
        }
    }, [socket])

    useEffect(() => {
        if (playerName && playerEmail && ageGroup) {
            setUserName(playerName)
            if (host) {
                sessionStorage.setItem("sessionId", sessionId)
                navigate("/quizselection")
            }
            else { // Block to execute for all invitees/players
                if (socket == null) {
                    const socket = new WebSocket(`${WSURL}/ws/quiz/${roomId}/`)
                    setSocket(socket)
                }
            }
        }
    }, [playerName, playerEmail, ageGroup])

    const handleNameAge = (name, email, age, avatar) => {
        sessionStorage.setItem("ageGroup", age);
        sessionStorage.setItem("name", name);
        sessionStorage.setItem("email", email);
        sessionStorage.setItem("avatar", avatar);
        setPlayerName(name)
        setPlayerEmail(email)
        setAgeGroup(age)
    }
    
    return (
        <div className="playerDetailsComponent">
            <NameAgeComponent proceedCallback={handleNameAge} />
        </div>
    )
}

export default PlayerDetailsComponent