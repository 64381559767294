import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import React from "react";
import MobileTimerComponent from "../../quizComponent/mobileTimerComponent/MobileTimerComponent";
import "./QtestMobileFixedHeader.scss"

const QtestMobileFixedHeader = ({ questionCount, noOfQuestions, timerProgress, maxTime }) => {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down("md"));
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <div className="qtestMobileFixedHeader">
            {isTablet && (
                <div className="qtestMobileFixedHeader__container">
                    <div className="qtestMobileFixedHeader__header">
                        <p className="qtestMobileFixedHeader__questioncount">
                            {`Question ${questionCount} / ${noOfQuestions}`}
                        </p>
                        <div
                            style={{
                                position: "absolute",
                                top: "1px",
                                right: "4vw",
                                paddingTop: isMobile ? "85px" : isTablet ? "85px" : "100px", //less than 600 padding is 85; more than 900 its 100; in between it is 92
                            }}
                        >
                            <MobileTimerComponent maximumTime={maxTime} progress={timerProgress} />{" "}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
export default QtestMobileFixedHeader;