import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import "./FillButtonAnimateComponent.scss"

const FillButtonAnimateComponent = ({ displayText, isDisabled, bgColor="#FDB913", duration = 0, handleClick, style }) => {
  const [counter, setCounter] = useState(duration);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (counter > 0)
        setCounter(prevState => prevState - 1);
      // else if(counter==0)
      //   startQuiz()
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [counter])


  return (

    <Button
      disabled={isDisabled || counter ? true : false}
      onClick={handleClick}
      sx={{
        textTransform: "unset !important",
        color: "white",
        borderColor: "#FDB913",
        padding: "0.8rem 2rem",
        fontFamily: "CaustenR",
        fontWeight: "700",
        letterSpacing: "3px",
        borderRadius: "10px",
        display: "block",
        background: `linear-gradient(to right, ${bgColor} 50%, transparent 0)`,
        backgroundSize: "200% 100%",
        backgroundPosition: "right",
        animation: "makeItfadeIn linear forwards",
        animationDuration: `${duration}s`,
        fontSize: "clamp(0.75rem,3vh,1.75rem)",
        "@media screen and (max-width: 1200px)": {
          fontSize: "1rem",
        },
        "@media screen and (max-width: 600px)": {
          fontSize: "0.875rem",
        }
      }}
      style={{ ...style }}
    >

      {displayText}
    </Button>
  );
}

export default FillButtonAnimateComponent;