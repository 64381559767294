import "./BlueInputComponent.scss"
import React from 'react'

const BlueInputComponent = ({Type="text", Value, OnChange, Maxlength, id, disabled=false}) => {
  return (
    <input className="blueinput" type={Type} value={Value} id={id} 
      onChange={OnChange} maxLength={Maxlength} disabled={disabled}
    />
  )
}

const BlueTextAreaComponent = ({Value, OnChange, Maxlength, Rows="4"}) => {
  return (
    <textarea className="blueinput" style={{height: "unset", marginBottom:"1rem", padding:"1rem", paddingLeft:"1.5rem",resize:"none"}}
       maxLength={Maxlength} value = {Value} onChange={OnChange} rows={Rows}
    > </textarea>
  )
}

export {BlueInputComponent as default, BlueTextAreaComponent }