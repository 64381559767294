import React, { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { CgArrowLongRight } from "react-icons/cg";
import FillButtonComponent from "../../../../shared/fillButtonComponent/FillButtonComponent";


import "./quizMTFComponent.scss"

const QuizMTFComponent = ({ question, questionCount, noOfQuestions, handleAnswerSubmit, leaderBoardOpen, selectedAnswer }) => {


    const { questionId, questionTitle, quizType, imageName, answerChoices, quizId } = question || {};

    const answerChoicesList = eval(answerChoices);

    const [mtfQuestions, setMtfQuestions] = useState([...answerChoicesList[0]]);
    const [mtfAnswers, setMtfAnswers] = useState([]);
    const [mtfOptions, setMtfOptions] = useState([...answerChoicesList[1]]);

    const handleDragEnd = ({ destination, source }) => {
        // console.log("to ", destination);
        // console.log("from ", source);
        if (!destination) {
            console.log("not valid drop location");
            return;
        }

        if (destination.index === source.index && destination.droppableId === source.droppableId) {
            console.log("Dropped in same position");
            return;
        }

        // Note: The react-beautiful-dnd seems to be unstable, and to overcome some issues, 
        // have added some workaround.
        // When an element is dragged/moved out of mtfQuestions, mtfAnswers or mtfOptions, generally it should be
        // enough to remove it from the list. However, removing it breaks the DnD. So we are replacing it with
        // a dummy element called magicWordFFF.

        let itemCopy;
        if (destination.droppableId === "options") {
            if (source.droppableId != "options") {
                itemCopy = mtfAnswers[parseInt(source.droppableId)]
                setMtfOptions(prev => [...prev, itemCopy])
                setMtfAnswers(prev => {
                    prev[parseInt(source.droppableId)] = "magicWordFFF"
                    return prev;
                })
            }

        } else {

            //dropped in one of the answer column

            if (source.droppableId === "options") { //picked up from options
                itemCopy = mtfAnswers[parseInt(destination.droppableId)]
                setMtfAnswers(prev => {
                    prev[parseInt(destination.droppableId)] = mtfOptions[source.index]
                    return prev
                })
                setMtfOptions(prev => {
                    prev[source.index] = "magicWordFFF";
                    if (itemCopy) {
                        prev = [...prev, itemCopy]
                    }
                    return prev
                })
            } else { //picked up from answers
                itemCopy = mtfAnswers[parseInt(destination.droppableId)]
                setMtfAnswers(prev => {
                    prev[parseInt(destination.droppableId)] = prev[parseInt(source.droppableId)]
                    prev[parseInt(source.droppableId)] = "magicWordFFF"
                    return prev
                })
                if (itemCopy) {
                    setMtfOptions(prev => [...prev, itemCopy])
                }
            }
        }

    }


    const handleSubmit = () => {
        handleAnswerSubmit(mtfAnswers);
    }

    return (
        <div className={leaderBoardOpen ? "quizMTFComponent quizMTF__leaderboardOpen" : "quizMTFComponent"}>

            <div className="quizBasic__question">
                <p className="quizMTFComponent__question">{questionTitle}</p>
                <p>
                    {questionCount} / {noOfQuestions}
                </p>
            </div>
            <div className="quizMTFComponent__match" style={{ pointerEvents: !!selectedAnswer ? "none" : "auto" }}>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <div className="quizMTFComponent__matchWrapper">
                        {mtfQuestions.map((question, index) => (
                            <div key={index} className="quizMTFComponent__wrapper">

                                <p className="quizMTF__matchQuestion">{question}</p>

                                <CgArrowLongRight className="quizMTF__arrow" size={44} style={{ color: "white" }} />

                                <Droppable droppableId={index.toString()}>
                                    {provided => {
                                        return (
                                            <div ref={provided.innerRef} {...provided.droppableProps}
                                                className="quizMTF__matchAnswerWrapper">
                                                <Draggable index={index} draggableId={index.toString()}>
                                                    {(provided) => {
                                                        return (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                {
                                                                    (mtfAnswers[index] &&
                                                                        mtfAnswers[index] !== "magicWordFFF") ?
                                                                        (<p className="quizMTF__matchAnswer">
                                                                            {mtfAnswers[index]}
                                                                        </p>)
                                                                        : ""
                                                                }
                                                            </div>
                                                        )
                                                    }}
                                                </Draggable>
                                                {provided.placeholder}
                                            </div>
                                        )
                                    }}
                                </Droppable>
                            </div>
                        ))}
                    </div>
                    <hr className="quizMTFComponent__line" />
                    {/* </div> */}
                    <div className="quizMTFComponent__options">


                        <Droppable droppableId="options" className>
                            {provided => {
                                return (
                                    <div ref={provided.innerRef} {...provided.droppableProps}
                                        className="quizMTFComponent__optionsDroppable">
                                        {mtfOptions?.map((option, index) => {
                                            return (
                                                <Draggable key={index} index={index} draggableId={`${index}-${option}`}>
                                                    {(provided) => {
                                                        return (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                            >
                                                                {
                                                                    option == "magicWordFFF" ||

                                                                    <p className="quizMTF__item_wrapper quizMTF__matchOption">
                                                                        {option}
                                                                    </p>
                                                                }
                                                            </div>
                                                        )
                                                    }}
                                                </Draggable>
                                            )
                                        })}
                                        {provided.placeholder}
                                    </div>
                                )
                            }}
                        </Droppable>

                    </div>
                </DragDropContext>
            </div>
            <div className="quizMTFComponent__submitButton">
                <FillButtonComponent displayText={"Submit"} handleClick={handleSubmit} isDisabled={!!selectedAnswer} />
            </div>
        </div>
    );
}

export default QuizMTFComponent;