import "./TitleCardComponent.scss"
import React from 'react'
import { MdOutlineArrowBackIosNew } from "react-icons/md"

const TitleCardComponent = ({title, handleBackButton, children}) => {
  return (
    <div className="titleCard">
      <div className="titleCard__heading">
        <MdOutlineArrowBackIosNew className="titleCard__clickable"
            style={{visibility: handleBackButton? "visible": "hidden"}} 
            size={50} 
            onClick={handleBackButton}
        />
        <p className="titleCard__title">{title}</p>
      </div>  
      <div>
        {children}
      </div> 
    </div>
  )
}

export default TitleCardComponent