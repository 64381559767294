import React from 'react';

export const HomeQTImage = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid" width="100%" height="auto" viewBox="0 0 900 700">
      <g transform="matrix(-1 0 0 1 900 0)">
        <ellipse cx="616.468" cy="360.308" fill="rgb(0,0,0)" rx="250.982" ry="248.884"/>
        <path fill="rgb(0,0,0)" fillRule="evenodd" d="M807.79276434 199.26555669c-.9038975-.8963396-10.24417177-60.95109304-15.36625766-89.03640061-1.20519668-6.5731571-9.94287261-8.06705644-13.25716348-2.39023895l-27.1169253 43.92064057 11.14806929 53.18281647 44.59227715-5.67681748z"/>
        <path fill="rgb(0,0,0)" fillRule="evenodd" d="M680.04191628 142.79616167c-.90389751-.8963396-10.24417178-50.49379766-15.36625767-78.57910524-1.20519668-6.5731571-9.94287261-8.06705643-13.25716348-2.39023894l-37.36109708 54.07915607 21.0909419 43.02430097 44.89357633-16.13411286z"/>
        <g>
        <g>
            <path fill="rgb(255,255,255)" fillRule="evenodd" d="M760.48879466 376.4420183c-4.21818838 0-8.43637676-.8963396-12.35326597-2.6890188-11.44936846-5.07925776-17.47535186-16.13411287-15.968856-28.68286732.30129916-2.09145907 2.10909418-3.58535841 4.21818837-3.28657855 2.1090942.29877987 3.61559004 2.09145908 3.31429087 4.18291815-1.20519668 9.56095577 3.31429087 17.32923234 11.75066763 21.21337062 8.43637676 3.88413828 18.0779502 2.09145907 23.50133526-3.88413828 1.50649585-1.49389934 3.9168892-1.7926792 5.42338506-.29877987 1.50649585 1.49389934 1.80779502 3.88413828.30129917 5.37803762-5.42338506 5.07925775-12.65456514 8.06705643-20.1870444 8.06705643z"/>
            <path fill="rgb(255,255,255)" fillRule="evenodd" d="M707.76143991 371.96032028c-12.65456514 0-24.70653193-6.87193696-30.129917-17.92679206-.9038975-1.79267921 0-4.18291815 1.80779503-5.07925776 1.80779502-.8963396 4.21818838 0 5.12208589 1.79267921 4.82078671 9.85973564 16.27015517 15.53655313 28.0208228 13.44509405 11.1480693-1.7926792 18.68054854-10.1585155 19.88574522-21.81093035.30129917-2.09145907 2.1090942-3.58535841 4.21818838-3.28657855 2.1090942.29877987 3.61559004 2.09145908 3.31429087 4.18291815-1.80779502 14.9389934-11.75066763 25.9938485-26.21302779 28.38408745-2.10909419 0-3.9168892.29877986-6.0259834.29877986z"/>
        </g>
        <path fill="rgb(255,255,255)" fillRule="evenodd" d="M732.16667268 356.12498729l-13.25716348-25.39628876c-1.20519668-2.09145908.60259834-4.48169802 3.0129917-4.18291815l32.23901119 3.58535841c2.41039336.29877987 3.31429087 2.98779868 1.80779502 4.78047789l-18.68054854 21.51215048c-1.80779502 1.7926792-4.21818838 1.49389934-5.12208589-.29877987z"/>
        </g>
        <path fill="rgb(0,0,0)" fillRule="evenodd" d="M356.7479069 498.64298424c-39.77149044 0-75.62609167-12.54875445-103.04431614-36.45114387-35.25200289-31.07310626-51.2208589-77.9815455-44.29097798-128.47534317 5.42338505-38.84138281-4.82078672-69.91448907-26.2130278-78.8778851-18.9818477-8.06705643-43.9896788 3.28657855-63.87542403 28.68286731-15.36625767 19.71947128-43.68837964 23.30482969-63.2728257 8.06705643C36.16559005 276.35076258 32.55 248.26545501 47.91625768 228.8447636c45.49617466-58.5608541 112.3845904-80.96934418 170.5353302-56.76817489 60.56113317 25.0975089 92.19754602 93.51809863 80.74817756 173.88988307-2.1090942 14.64021353-1.20519668 34.95724454 14.76365933 48.99989833 15.968856 14.04265378 42.78448213 17.6280122 73.2156983 9.85973563 24.1039336-6.27437722 48.81046554 8.06705644 55.1377481 31.96944586 6.32728258 23.90238943-8.13507758 48.40233859-32.23901118 54.67671581-18.0779502 4.78047789-36.1559004 7.17071683-53.32995309 7.17071683z"/>
        <path fill="rgb(0,0,0)" fillRule="evenodd" d="M745.12253699 643.85c-25.0078311 0-45.1948755-20.01825114-45.1948755-44.81698017V486.9905694c0-24.79872903 20.1870444-44.81698018 45.1948755-44.81698018s45.1948755 20.01825115 45.1948755 44.81698018v112.04245043c0 24.79872903-20.1870444 44.81698017-45.1948755 44.81698017z"/>
        <path fill="rgb(0,0,0)" fillRule="evenodd" d="M655.33538434 643.85c-25.00783111 0-45.1948755-20.01825114-45.1948755-44.81698017V486.9905694c0-24.79872903 20.18704439-44.81698018 45.1948755-44.81698018 25.0078311 0 45.1948755 20.01825115 45.1948755 44.81698018v112.04245043c0 24.79872903-20.1870444 44.81698017-45.1948755 44.81698017z"/>
        <path fill="rgb(0,0,0)" fillRule="evenodd" d="M564.64433418 643.85c-25.00783111 0-45.1948755-20.01825114-45.1948755-44.81698017V486.9905694c0-24.79872903 20.18704439-44.81698018 45.1948755-44.81698018 25.0078311 0 45.1948755 20.01825115 45.1948755 44.81698018v112.04245043c0 24.79872903-20.1870444 44.81698017-45.1948755 44.81698017z"/>
        <path fill="rgb(0,0,0)" fillRule="evenodd" d="M474.85718152 643.85c-25.0078311 0-45.1948755-20.01825114-45.1948755-44.81698017V486.9905694c0-24.79872903 20.1870444-44.81698018 45.1948755-44.81698018 25.00783111 0 45.1948755 20.01825115 45.1948755 44.81698018v112.04245043c0 24.79872903-20.18704439 44.81698017-45.1948755 44.81698017z"/>
        <path fill="rgb(255,255,255)" fillRule="evenodd" d="M622.49377441 270.37515259c0 29.57922363 24.40527344 53.7803955 54.23388672 53.7803955 29.82861328 0 54.23376465-24.20117187 54.23376465-53.7803955 0-29.57919312-24.40515137-53.780365-54.23376465-53.780365s-54.23388672 24.20117188-54.23388672 53.780365zm9.0390625 0c0-24.79873657 20.18701172-44.81698609 45.19482422-44.81698609 25.00793457 0 45.1949463 20.01824952 45.1949463 44.81698609 0 24.79870605-20.18701173 44.81695556-45.1949463 44.81695556-25.0078125 0-45.19482422-20.0182495-45.19482422-44.81695556z"/>
        <path fill="rgb(255,255,255)" fillRule="evenodd" d="M732.7692871 272.76538086c0 25.69506836 21.0909424 46.60968018 47.00268556 46.60968018 25.91174316 0 47.00268554-20.91461182 47.00268554-46.60968018 0-25.69506836-21.09094238-46.60964966-47.00268554-46.60964966-25.91174317 0-47.00268555 20.9145813-47.00268555 46.60964966zm9.0390625.2987976c0-20.91461181 17.17407227-37.64627075 37.96374513-37.64627075 21.09094238 0 37.96362304 16.73165894 37.96362304 37.64627076 0 20.9145813-16.87268066 37.64624023-37.96362304 37.64624023-21.09094239 0-37.96374512-16.73165893-37.96374512-37.64624023z"/>
        <rect width="9.943" height="8.963" x="726.442" y="268.284" fill="rgb(255,255,255)" rx="0" ry="0"/>
        <path fill="rgb(255,255,255)" fillRule="evenodd" d="M627.01326236 274.85686324h-.90389751l-121.72486467-20.317031c-2.41039336-.29877988-4.21818838-2.68901882-3.61559004-5.07925776.30129917-2.39023894 2.71169253-4.18291815 5.12208589-3.58535841l121.72486467 20.317031c2.41039336.29877987 4.21818838 2.68901882 3.61559004 5.07925776 0 2.09145907-2.10909419 3.58535841-4.21818838 3.58535841z"/>
        <path fill="rgb(255,255,255)" fillRule="evenodd" d="M767.71997474 390.78345196l-67.1897149-3.88413828 35.85460122 37.64626334 31.33511368-33.76212506z"/>
        <path fill="rgb(255,255,255)" fillRule="evenodd" d="M736.38486106 416.77730046l20.78964273 83.95714285-27.71952364 19.42069141-25.61042945-21.81093035 32.54031036-81.56690391z"/>
        <path fill="rgb(255,255,255)" fillRule="evenodd" d="M703.8445507 285.01537875c-1.80779502 0-3.61559004-1.19511947-4.21818838-2.68901881-4.51948755-11.05485511-12.65456513-17.92679207-21.39224106-18.22557194-8.43637676-.29877987-15.96885601 5.6768175-20.78964273 16.13411286-1.20519668 2.09145908-3.91688921 3.28657855-6.0259834 2.09145908-2.41039336-1.19511947-3.31429087-3.58535841-2.1090942-5.97559736 6.32728258-13.74387392 16.87275353-21.21337061 28.62342116-21.21337061h.9038975c12.35326597.29877987 23.50133526 9.56095577 29.52731866 23.90238942.90389751 2.39023895-.30129917 4.78047789-2.41039336 5.97559736-1.20519668-.29877987-1.50649585 0-2.10909419 0z"/>
        <path fill="rgb(255,255,255)" fillRule="evenodd" d="M801.76678095 284.41781901c-1.80779502 0-3.61559004-1.19511947-4.21818838-2.6890188-3.61559004-8.96339604-9.94287261-14.34143366-16.87275352-14.64021353-6.62858174 0-12.65456514 4.48169802-16.57145435 12.84753432-1.20519668 2.09145907-3.61559004 3.28657854-6.0259834 2.09145907-2.41039336-1.19511947-3.31429087-3.58535841-2.1090942-5.97559736 5.72468424-11.65241484 14.76365934-18.22557193 25.00783112-17.92679206 10.54547095.29877986 20.18704439 8.06705643 25.0078311 20.317031.90389751 2.39023895-.30129916 4.7804779-2.41039335 5.97559736-.60259834-.29877986-1.20519668 0-1.80779502 0z"/>
      </g>
    </svg>    
  );
}
