import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Context from '../../../context/Context';
import FillButtonAnimateComponent from '../../../shared/fillButtonAnimateComponent/FillButtonAnimateComponent';

const QuizInfoComponent = () => {

  const {
    questionData,
    noOfQuestions,
    podiumPage,
    leaderboardData,
    socket,
    host
  } = useContext(Context);

  const navigate = useNavigate()

  const goToPodium = () => {
    if (podiumPage) {
      navigate("/podium");
    }
  }

  useEffect(() => {
    goToPodium()
  }, [podiumPage]);

  const handleNextQuestion = () => {
    if (questionData?.questionNumber === noOfQuestions) {
      socket &&
        socket.send(
          JSON.stringify({
            action: "goToPodium",
          })
        );
    } else if (questionData?.questionNumber !== noOfQuestions) {
      socket &&
        socket.send(
          JSON.stringify({
            action: "sendNextQuestion",
          })
        );
    }
  };
  return (
    <>      
      {eval(questionData.answerChoices)[0] && (<div className="quizanswer__info">
        <p className="quizanswer__infoText">{eval(questionData.answerChoices)[0]}</p>
      </div>)}
      {(host && (
        <div className="quizComponent__nextBtnFooter">
          <FillButtonAnimateComponent displayText={(questionData?.questionNumber === noOfQuestions ? "End Quiz" : "PROCEED")}
            handleClick={handleNextQuestion} duration={questionData.timeToAnswer} />
        </div>
      ))}
    </>
  )
}

export default QuizInfoComponent