import "./ForgotPasswordComponent.scss"
import React, { useContext, useState } from 'react'
import FillButtonComponent from '../../shared/fillButtonComponent/FillButtonComponent'
import InputComponent from '../../shared/inputComponent/InputComponent'
import UserAuthComponent from '../../shared/userAuthComponent/UserAuthComponent'
import { VscEye, VscEyeClosed } from "react-icons/vsc"
import { BEURL } from "../../api/BaseUrl"
import apiEndpoints from "../../api/EndPoints"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import Context from "../../context/Context"

export const ForgotPasswordComponent = () => {
  const [email_usrnm, setEmail_usrnm] = useState("")
  const [code, setCode] = useState("")
  const [password, setPassword] = useState("")
  const [re_password, setRe_password] = useState("")
  const [reset_emailId, setReset_emailId] = useState(false)
  const [inputType_1, setInputType_1] = useState("password")
  const [inputType_2, setInputType_2] = useState("password")
  
  const {setNotificationType, setNotificationMessage, setNotificationDisplayFlag, setLoadingDisplayFlag} = useContext(Context)

  const navigate = useNavigate()

  const setAlert = (type, message) => {
    setNotificationType(type)
    setNotificationMessage(message)
    setNotificationDisplayFlag(true)
  }  

  const handleOnPasswordRevealClick = (pwd_id) => {
    pwd_id === 1 ?
    setInputType_1((prevValue) => {
      if (prevValue === "password")
        return "text"
      else  
        return "password"
    }) :
    setInputType_2((prevValue) => {
      if (prevValue === "password")
        return "text"
      else  
        return "password"
    })
  }

  const handleResetPassword = () => {
    if ( !(email_usrnm.length > 0) ){ //min length is 0
      return setAlert("error", "Please enter a valid email/username")
    }
    setLoadingDisplayFlag(true)
    const url = BEURL + apiEndpoints.forgotPassword
    const data = {
      "username":email_usrnm
    }
    axios.post(
      url, data
    ).then((response) => setReset_emailId(response.data)
    ).catch((error) => setAlert("error",error.response.data)
    ).finally(() => setLoadingDisplayFlag(false))
  }
  
  const handleChangePassword = () => {   
    if(code.length !== 6){
      return setAlert("error", "Invalid Code")
    } 
    if (password === re_password){
      if(password.length < 8){
        return setAlert("error", "Password must be of atleast 8 characters")
      }      
      setLoadingDisplayFlag(true)
      const url = BEURL + apiEndpoints.confirmForgotPassword
      const data = {
        "username":email_usrnm,
        "password":password,
        "confirmation_code":code
      }
      axios.post(
        url, data
      ).then(() => {
          setAlert("success","Your password has been reset.")          
          setTimeout(() => {
            navigate("/")
          }, 1000);          
        } 
      ).catch((error) => setAlert("error",error.response.data)
      ).finally(() => setLoadingDisplayFlag(false))
    }else{
      setAlert("error","Passwords do not match!")
    }
  }

  return (
    <UserAuthComponent>
      {reset_emailId ?
        <>
          <p className="forgotpwd__title moremargin">Reset your password</p>
            <p className="forgotpwd__subtitle">{`We have sent a password reset code by email to ${reset_emailId}. Enter it below to reset your password.`}</p>
            <InputComponent ClassName="forgotpwd__input" 
              Label="Code" Type="text" Value={code} OnChange={(e) => setCode(e.target.value)}
            />
            <InputComponent ClassName="forgotpwd__input" 
              Type={inputType_1} Label="New Password" Value={password} OnChange={(e) => setPassword(e.target.value)}
            >
              {inputType_1==="password" ? 
                <VscEye className="forgotpwd__icon" onClick={() => handleOnPasswordRevealClick(1)}/> :
                <VscEyeClosed className="forgotpwd__icon" onClick={() => handleOnPasswordRevealClick(1)}/>
              }
            </InputComponent>
            <InputComponent ClassName="forgotpwd__input" 
              Type={inputType_2} Label="Enter New Password Again" Value={re_password} OnChange={(e) => setRe_password(e.target.value)}
            >
              {inputType_2==="password" ? 
                <VscEye className="forgotpwd__icon" onClick={() => handleOnPasswordRevealClick(2)}/> :
                <VscEyeClosed className="forgotpwd__icon" onClick={() => handleOnPasswordRevealClick(2)}/>
              }
            </InputComponent>     
            <div className="forgotpwd__savebtn">
              <FillButtonComponent 
                displayText={"Change Password"} 
                handleClick={handleChangePassword}
              />
          </div>   
        </>              
        :
        <>
          <p className="forgotpwd__title moremargin">Forgot your password?</p>
          <p className="forgotpwd__subtitle">Enter your Username below and we will send a message to reset your password</p>
          <InputComponent ClassName="forgotpwd__input moremargin" 
            Label="USERNAME OR EMAIL" Type="text" Value={email_usrnm} OnChange={(e) => setEmail_usrnm(e.target.value)}
          />      
          <div className="forgotpwd__savebtn">
            <FillButtonComponent 
              displayText={"Reset Password"} 
              handleClick={handleResetPassword}
            />
          </div>   
        </> 
      }
    </UserAuthComponent>
  )
}
