import "./SelectSlideComponent.scss"
import React, { useEffect, useState } from 'react'
import TitleCardComponent from "../titleCardComponent/TitleCardComponent"
import FillButtonComponent from "../../../shared/fillButtonComponent/FillButtonComponent"

export const SelectSlideComponent = ({setComponentInFocus}) => {
  const style = {
    addQuestionStyle:{height:"150px", flexBasis:0, flexGrow:1},
    selectQuestionTypeStyle: {borderRadius:"60px", fontSize:"16px"}
  }
  const addQuestionMethods = ["Select Question Template", "Select from Question Bank", "Upload Spreadsheet"] 
  const questionTypes = {"MCQ":"MCQ", "TRUE OR FALSE":"TRF", "MATCH THE FOLLOWING":"MTF","CONNECT QUESTION":"CNT", "ARRANGE IN ORDER":"DND", "FILL IN THE BLANKS":"FIB"}
  
  const [selectedQuestionType, setSelectedQuestionType] = useState("")
  const [selectedQuestionMethod, setSelectedQuestionMethod] = useState("")
  const [slideInFocus, setSlideInFocus] = useState("selectSlide")

  const handleQuestionTypeSelection = (e) => (setSelectedQuestionType((prevValue)=>{
    afterSelectQuestionTypeSlide(questionTypes[e.target.innerText.trim()])
    return questionTypes[e.target.innerText]
  }))
  const afterSelectQuestionTypeSlide = (questionTypeOption) => { 
    setComponentInFocus(questionTypeOption)
  }
  const handleAddQuestionMethod = (e) =>  (setSelectedQuestionMethod((prevValue)=>{
    afterAddQuestionSlide(e.target.innerText.trim())
    return e.target.innerText
  }))
  const afterAddQuestionSlide = (slideOption) => {
    switch(slideOption){
      case "Select Question Template":
        setSlideInFocus("selectQuestionTypeSlide")
        break
      default:
        alert("Come back soon to explore this!")
    }
  } 
  
  return (
    <div className="slide">     
      {slideInFocus==="selectSlide"&&<TitleCardComponent title="Select which kind of slide you want to add">
        <div className="selectSlide">
          <FillButtonComponent 
            displayText="ADD QUESTION SLIDE"
            handleClick={() => setSlideInFocus("addQuestionSlide")}
          />
          <p>OR</p>
          <FillButtonComponent
            displayText="ADD NON-QUESTION SLIDE"
            handleClick={() => setComponentInFocus("NQS")}
          />        
        </div>
      </TitleCardComponent>}

      {slideInFocus==="addQuestionSlide"&&<TitleCardComponent title="Add Question" handleBackButton={() => setSlideInFocus("selectSlide")}>
        <div className="addQuestionSlide">
          {
            addQuestionMethods.map((addQuestionMethod) => 
              <FillButtonComponent
                bgColor={selectedQuestionMethod===addQuestionMethod?"#ECA904" :"#0097D4"}
                bgHoverColor="#ECA904"
                style={{...style.addQuestionStyle}}
                displayText={addQuestionMethod}
                handleClick={handleAddQuestionMethod}
                key={addQuestionMethod}
              />
            )
          }
        </div>   
      </TitleCardComponent>}

      {slideInFocus==="selectQuestionTypeSlide"&&<TitleCardComponent title="Select Question Type" handleBackButton={() => setSlideInFocus("addQuestionSlide")}>
        <div className="selectQuestionTypeSlide">
          {
            Object.keys(questionTypes).map((questionType) => 
              <FillButtonComponent 
                bgColor={selectedQuestionType===questionTypes[questionType]?"#ECA904" :"#0097D4"}
                bgHoverColor="#ECA904"
                style={{...style.selectQuestionTypeStyle}}
                displayText={questionType}
                handleClick={handleQuestionTypeSelection}
                key={questionType}
              />
            )
          }
        </div>  
      </TitleCardComponent>}

    </div>
)
}
