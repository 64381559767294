import React from "react";
// import { PiFilmSlate } from "react-icons/pi";
import { MdOndemandVideo } from "react-icons/md";

const QuizVideoComponent = ({ videoUrl, width, height, slideType="normal", onMediaClick=()=>{} }) => {
    return (
        <>
            {slideType=="normal" && (
                <video width={width} height={height} onClick={onMediaClick} autoPlay={false} muted loop controls>
                    <source src={videoUrl} />
                    Your browser does not support the video tag.
                </video>
            )}
            {slideType=="mini" && (
                <div className="audioMedia">
                    <MdOndemandVideo size="4rem"/>
                </div>
            )}
        </>
    );
};

export default QuizVideoComponent;
