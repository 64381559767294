import "./PricingComponent.scss"

import React, { useContext, useEffect, useState } from 'react'
import { TiTick } from "react-icons/ti"
import FillButtonComponent from "../../shared/fillButtonComponent/FillButtonComponent"
import axios from "axios"
import Context from "../../context/Context"
import LoginPromptComponent from "../../shared/loginPromptComponent/LoginPromptComponent"
import {BEURL, EnterpriseContactUsFormURL} from "../../api/BaseUrl"
import apiEndpoints from "../../api/EndPoints"
import loadScript from "../../utils/loadScript"
import { EnterpriseTierCopy, FreeTierCopy, PremiumTierCopy } from "../../copies"
import PremiumModalComponent from "./PremiumModalComponent"
import { useNavigate } from "react-router-dom"


const PricingTile = ({handleOnClick, copy}) => {
  return(
    <div className="pricingTile">
      <div className={`pricingTile__content`}>
        <p className="pricingTile__title">
          {copy.title}
        </p>
        <p className="pricingTile__price">
          {copy.price}
        </p>
        {copy.features.map((feature) => (
          <p className="pricingTile__description">
            {/* <TiTick className="pricingTile__icon"/> */}
            {feature}
          </p>
        ) )}
      </div>
      <FillButtonComponent displayText={copy.buttonText} 
        bgColor="#30B2E7"
        handleClick = {handleOnClick}
      />
    </div>
  )
}

const PricingComponent = () => {
  const {isLoggedIn, setLoadingDisplayFlag, setNotificationType, setNotificationMessage, setNotificationDisplayFlag} = useContext(Context)
  const [openLoginPrompt, setOpenLoginPrompt] = useState(false)
  const [openPremiumModal, setOpenPremiumModal] = useState(false)
  const navigate = useNavigate()
  
  useEffect(() => {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
  });   
  const setAlert = (type, message) => {
    setNotificationType(type)
    setNotificationMessage(message)
    setNotificationDisplayFlag(true)
  }

  const getRazorpayOrderOptions = (name, email, phone_number, orderId, amount, receiptId) => ({
    "key": process.env.REACT_APP_RAZORPAY_API_ID, 
    "amount": amount, 
    "currency": "INR",
    "name": "Qshala",
    "description": "Premium Plan - One Year",
    "order_id": orderId, 
    "handler": (response) => {
      axios.post(BEURL + apiEndpoints.confirmPayment, response).then(() => {
        setLoadingDisplayFlag(false) // flag 2
        setAlert("success","Payment Confirmed, redirecting to Home Page")
        setTimeout(() => {
          navigate('/')
        }, 5000);        
      }).catch((error) => {
        setLoadingDisplayFlag(false) // flag 2
        setAlert("error",error.response.data)
      })
    },
    "modal": {
      // Checkout modal closed by user
      "ondismiss": function(){
        setLoadingDisplayFlag(false) // flag 2
      }
    },
    "prefill": {
      "name": `${name}`,
      "email": `${email}`,
      "contact": `${phone_number}`
    },
    "notes": {
      "name": name,
      "receipt_id": receiptId
    }
  })

  const getRazorpaySubscriptionOptions = (name, email, phone_number, subscription_id ) => ({
    "key": process.env.REACT_APP_RAZORPAY_API_ID, 
    "subscription_id": subscription_id,
    "name": "Qshala",
    "description": "Premium Plan - Monthly",
    "handler": (response) => {
      axios.post(BEURL + apiEndpoints.confirmPayment, response).then(() => {
        setLoadingDisplayFlag(false) // flag 2
        setAlert("success","Payment Confirmed, redirecting to Home Page")
        setTimeout(() => {
          navigate('/')
        }, 5000);
      }).catch((error) => {
        setLoadingDisplayFlag(false) // flag 2
        setAlert("error",error.response.data)
      })
    },
    "modal": {
      // Checkout modal closed by user
      "ondismiss": function(){
        setLoadingDisplayFlag(false) // flag 2
      }
    },
    "prefill": {
      "name": `${name}`,
      "email": `${email}`,
      "contact": `${phone_number}`
    },
    "notes": {
      "name": name
    }
  })

  const handleOnClickBuy = async (plan) => { 
    if (!isLoggedIn){
      return setOpenLoginPrompt(true)
    }   
    // Yearly plan   
    if(plan == "Premium_Yearly"){
      setLoadingDisplayFlag(true) // flag 1
      let profile = axios.get(BEURL + apiEndpoints.getProfile)
      let order = axios.post(BEURL + apiEndpoints.createOrder, {"plan":plan})
      Promise.all([profile, order]).then(([profile, order]) => {
        setLoadingDisplayFlag(false) // flag 1
        let name = profile.data['given_name']+" "+profile.data['family_name']
        let email = profile.data['email']
        let phone_number = profile.data['phone_number'] || ""        
        let orderId = order.data["orderId"]
        let receiptId = order.data["receiptId"]
        let amount  = order.data["amount"]
        let options = getRazorpayOrderOptions(name, email, phone_number, orderId, amount, receiptId)
        let paymentObject = new window.Razorpay(options);
        paymentObject.open();
        setLoadingDisplayFlag(true) // flag 2
        paymentObject.on('payment.failed', function (response){
          setLoadingDisplayFlag(false) // flag 2
          setAlert("error", response.error.description);
        }); 
      })      
    }    
    // Monthyl subscription
    if(plan == "Premium_Monthly"){
      setLoadingDisplayFlag(true) // flag 1
      let profile = axios.get(BEURL + apiEndpoints.getProfile)
      let subscription = axios.post(BEURL + apiEndpoints.createSubscription, {"plan":plan})
      Promise.all([profile, subscription]).then(([profile, subscription]) => {
        setLoadingDisplayFlag(false) // flag 1
        let name = profile.data['given_name']+" "+profile.data['family_name']
        let email = profile.data['email']
        let phone_number = profile.data['phone_number'] || ""        
        let subscription_id = subscription.data["subscriptionId"]
        let options = getRazorpaySubscriptionOptions(name, email, phone_number, subscription_id)
        let paymentObject = new window.Razorpay(options);
        paymentObject.open();
        setLoadingDisplayFlag(true) // flag 2
        paymentObject.on('payment.failed', function (response){
          setLoadingDisplayFlag(false) // flag 2
          setAlert("error", response.error.description);
        }); 
      })      
    }
  }

  const handleOnClickPremium = () => { 
    if (!isLoggedIn){
      return setOpenLoginPrompt(true)
    } 
    setLoadingDisplayFlag(true)
    // Check if user is already a premium member
    const url_stats = BEURL + apiEndpoints.getUserStats
    axios.get(url_stats).then((res) => {
      setLoadingDisplayFlag(false)
      if (res.data['role'] === "Premium"){
        setAlert("info", "You are already a Premium Member")
        setTimeout(() => {
          navigate('/')
        }, 5000);
      }       
      else  
        setOpenPremiumModal(true)
    }).catch((err) => setAlert("error", err.response.data))        
   }

  const handleOnClickFree = () => {
    if (!isLoggedIn)
      navigate('/signin')
    else
      navigate('/')
  }

  const handleOnClickEnterprise = () => {
    window.open(EnterpriseContactUsFormURL);
  } 

  return (
    <>
    <PremiumModalComponent open={openPremiumModal} handleClose={() => setOpenPremiumModal(false)} handleOnClickBuy={handleOnClickBuy} />
    <LoginPromptComponent open={openLoginPrompt} handleClose={() => setOpenLoginPrompt(false)} />
    <div className="pricing">
      <div className="pricing__title">
        <p>Simple, Easy Pricing</p>
      </div>
      <div className="pricing__right">
        <PricingTile  handleOnClick={handleOnClickFree} copy={FreeTierCopy}/>
        <PricingTile  handleOnClick={handleOnClickPremium} copy={PremiumTierCopy}/>
        <PricingTile  handleOnClick={handleOnClickEnterprise} copy={EnterpriseTierCopy}/>
      </div>
    </div>
    </>    
  )
}

export default PricingComponent