import React, { useContext } from "react";
import { Divider, Tooltip } from '@mui/material';
import CustomModalComponent from "../customModalComponent/CustomModalComponent";
import { makeStyles } from '@mui/styles';
import Context from "../../context/Context";

import "./ChoicePopupComponent.scss";
import FillButtonComponent from "../fillButtonComponent/FillButtonComponent";

const ChoicePopupComponent = ({
  showPopup,
  handleClosePopup,
  popupTitle,
  popupDescription,
  handleChoiceOne,
  handleChoiceTwo,
  choiceOneText,
  choiceTwoText,
  choiceOneDescription,
  choiceTwoDescription,
}) => {

  const {
    socket,
    isLoggedIn,
  } = useContext(Context);

  const useStyles = makeStyles((theme) => ({
    divider: {
        background: "white",
        flex:"0.45",
        height:"0.5px",
    },
  }));

  const classes = useStyles();

  return (
    <div className="choicePopupComponent">
      <CustomModalComponent open={showPopup} handleClose={handleClosePopup}>
        <div className="selectMultiplayer__modal choicePopupComponent">
          {popupTitle && <h1>{popupTitle}</h1>}
          {popupDescription && <p>{popupDescription}</p>}

          {choiceOneDescription && <p>{choiceOneDescription}</p>}
          {choiceOneText &&
          <div className="selectMultiplayer__modalJoin">
            <FillButtonComponent displayText={choiceOneText} handleClick={handleChoiceOne} bgColor="#30b2e7" bgHoverColor="#007FB3"/>
          </div>}
          {choiceTwoText &&
            ((socket == null) && ( // Don't display QTest if user comesback to change quiz in readymade
              <>
                <div className="selectMultiplayer__dividerContainer">
                  <Divider classes={{ root: classes.divider }} />
                  <p>OR</p>
                  <Divider classes={{ root: classes.divider }} />
                </div>
                {choiceTwoDescription && <p>{choiceTwoDescription}</p>}
                <div className="selectMultiplayer__modalJoin">
                    <Tooltip title={isLoggedIn? "" : "You must be logged in to create a QTest"} arrow>
                      <span>
                        <FillButtonComponent isDisabled={!isLoggedIn} displayText={choiceTwoText} handleClick={handleChoiceTwo} bgColor="#30b2e7" bgHoverColor="#007FB3"/>
                      </span>
                    </Tooltip>
                </div>
              </>
            ))
          }
        </div>
      </CustomModalComponent>
    </div>
  );
};

export default ChoicePopupComponent;
