
import React, { useContext } from "react";
import Context from "../../../../context/Context";
import QuizMTFComponent from "./QuizMTFComponent";

//This wrapper has been added coz, due to some bug in react-beautiful-dnd library,
//the drag and drop stops working properly if we access useContext(context) in the same
//file.

const QuizMTFCompWrapper = ({ question,
    setSelectedAnswer,
    selectedAnswer,
    questionCount,
    noOfQuestions, }) => {

    const { socket, roomId, leaderBoardOpen, mobileLeaderBoardOpen, timerProgress } = useContext(Context);

    const { questionId, questionTitle, quizType, imageName, answerChoices, quizId } = question || {};

    const handleAnswerSubmit = (finalAnswer) => {
        setSelectedAnswer(finalAnswer);
        socket.send(
            JSON.stringify({
                action: "submitAnswer",
                playerId: sessionStorage.getItem("sessionId"), 
                questionId: questionId,
                quizId: quizId,
                roomId: roomId, //roomId, 
                timeTaken: timerProgress,
                questionType: "MTF",
                playerAnswer: JSON.stringify(finalAnswer),
            })
        );
    }

    return ( 
        <QuizMTFComponent question={question}
        selectedAnswer={selectedAnswer}
        questionCount={questionCount}
        noOfQuestions={noOfQuestions}
        handleAnswerSubmit={handleAnswerSubmit}
        leaderBoardOpen={leaderBoardOpen}/>
     );
}
 
export default QuizMTFCompWrapper;