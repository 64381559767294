import React from "react";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { capitalizeString } from "../constants";

const BarChartComponent = ({ barChartData }) => {
  Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels,
  );

  // Extract correct answer index from barChartData object
  const correctAnswerIndex = barChartData?.correctAnswerIndex;

  // Define colors for the bars, highlight the correct answer with green
  const barColors = barChartData?.values.map((_, index) => {
    return index === correctAnswerIndex ? "#75B543" : "#ECA904"; // Correct answer in green, others in yellow
  });

  return (
    <Bar
      data={{
        labels: barChartData?.labels, // Dynamic labels
        datasets: [
          {
            data: barChartData?.values, // Dynamic values
            backgroundColor: barColors, // Dynamic bar colors
            borderColor: "#FFF", // Set the border color to black for all bars
            borderWidth: 2, // Set the border width
            // Set a minimum bar length for zero values
            minBarLength: 10, // Adjust this value as needed for visibility
          },
        ],
      }}
      height={400}
      options={{
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            grid: {
              display: false,
              color: "#c1c1c1",
              borderColor: "#FFF",
              borderWidth: 2,
            },
            ticks: {
              display: false,
              color: "#c1c1c1"
            }
          },
          x: {
            grid: {
              display: false,
              color: "#c1c1c1",
              borderColor: "#FFF",
              borderWidth: 2,
            },
            ticks: {
              // display: false,
              color: "#FFF",
              font: {
                weight: 'bold',
              },
            }
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            anchor: "top",
            align: "end",
            color: "#FFF",
            font: {
              size: 15,
              weight: 'bold',
            },
            formatter: (value) => value,
          },
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                return " " + capitalizeString(barChartData?.toolTipMessages[tooltipItem.dataIndex]); // Dynamic tooltips. Added extra space for aesthetics.
              },
            },
          },
        },
        animation: {
          duration: 2000,
          delay: (context) => {
            let delay = 20;
            if (
              context.type === "data" &&
              context.mode === "default" &&
              !context.dropped
            ) {
              delay = context.dataIndex * 500 + context.datasetIndex * 100;
            }
            return delay;
          },
        },
        elements: {
          bar: {
            borderRadius: 5,
          }
        }
      }}
    />
  );
};

export default BarChartComponent;
