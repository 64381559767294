import React from "react";
const QuizImageComponent = ({ imageUrl, width, height, onMediaClick=()=>{} }) => {
  const handlefallbackImage = (e) => {
    e.target.src = "/images/QT-Question-Flip.png"
  };
  return (
    <img
      src={imageUrl}
      width={width}
      height={height}
      onError={handlefallbackImage}
      onClick={onMediaClick}
      alt="quiz image"
    />
  );
};

export default QuizImageComponent;
