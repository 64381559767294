import React from 'react'
import MiniSlideComponent from '../miniSlideComponent/MiniSlideComponent'

const MiniMTFComponent = ({questionData, handleDeleteClick}) => {
  let options = []
  for (let i=0; i< 8; i++)
    options.push( <div className="miniOptions" key={i}/>)
  return (
    <MiniSlideComponent questionData={questionData} isImage={false} handleDeleteClick={handleDeleteClick}>
      <div className="miniMCQ">
        {options}
      </div>
    </MiniSlideComponent>
  )
}

export default MiniMTFComponent