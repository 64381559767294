import "./DiscardSlideComponent.scss"
import React from 'react'
import CustomModalWithTitleComponent from '../../../shared/customModalComponent/CustomModalWithTitleComponent'
import FillButtonComponent from "../../../shared/fillButtonComponent/FillButtonComponent"

const DiscardSlideComponent = ({open, handleClose, handleDiscardSlide}) => {
  return (
    <CustomModalWithTitleComponent 
      title="Discard Slide" open={open} handleClose={handleClose}
      bgColor="#0097D4" width="40%"
    >
      <div className='discardSlide'>
        <p>
          Are you sure you want to discard this slide? This action cannot be undone.
        </p>
        <FillButtonComponent
          displayText="DISCARD"
          handleClick={handleDiscardSlide}         
        />
      </div>
    </CustomModalWithTitleComponent>
  )
}

export default DiscardSlideComponent