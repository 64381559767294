import React, { useContext, useEffect, useState } from 'react'
import "./WelcomeBannerComponent.scss"
import FillButtonComponent from "../../shared/fillButtonComponent/FillButtonComponent"
import { useNavigate } from 'react-router-dom'
import { BEURL } from '../../api/BaseUrl'
import apiEndpoints from '../../api/EndPoints'
import LoginPromptComponent from '../../shared/loginPromptComponent/LoginPromptComponent'
import axios from 'axios'
import Context from '../../context/Context'
import { capitalizeString } from '../../shared/constants'

const WelcomeBannerComponent = ({handleOnClick, description, buttonText}) => {
  const {isLoggedIn, setLoadingDisplayFlag} = useContext(Context)
  const navigate = useNavigate()
  const [name, setName] = useState(capitalizeString(sessionStorage.getItem("name")))
  
  if (!isLoggedIn){
    return(
      <LoginPromptComponent open={!isLoggedIn} handleClose={() => navigate('/')} />
    )
  } 
  
  return (
    <div className='welcomeBanner'>
      <p className='welcomeBanner__salutation'> Hi {name}! </p>
      <p className='welcomeBanner__description'> {description} </p>
      <div style={{width:"70%", whiteSpace:"nowrap"}} >
        < FillButtonComponent 
          displayText={buttonText}
          bgColor='#30B2E7'
          handleClick={handleOnClick}
          // style={{width:"70%", whiteSpace:"nowrap"}}
          // style={{width:"40%", minWidth:"385px", whiteSpace:"nowrap", textShadow: "black 0.1em 0.1em 0.2em"}}
        />
      </div>
      
    </div>
  )
}

export default WelcomeBannerComponent