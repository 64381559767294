import "./SignUpComponent.scss"
import {VscEye, VscEyeClosed} from "react-icons/vsc"
import React, { useContext, useState } from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel';
import FillButtonComponent from "../../shared/fillButtonComponent/FillButtonComponent"
import InputComponent from "../../shared/inputComponent/InputComponent"
import {BEURL} from "../../api/BaseUrl"
import apiEndpoints from "../../api/EndPoints"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import Context from "../../context/Context"
import UserAuthComponent from "../../shared/userAuthComponent/UserAuthComponent"
import { checkTypes, checkValidation } from "../../utils/inputCheckValidation"
import { TiTick } from "react-icons/ti";
import { FcGoogle } from "react-icons/fc"

const RadioBtn = ({value, label}) => (
  <FormControlLabel sx = {{
    '.MuiFormControlLabel-label':{
        color:"#FFF",
        fontFamily:"CaustenR",
        fontWeight: 700,
        fontSize:"1.188rem",
        lineHeight:"1.188rem",
        '@media screen and (max-width:1200px)': {
          fontSize: '1.188rem',
          lineHeight: '1.188rem',          
        },
        '@media screen and (max-width:1000px)': {
          fontSize: '1rem',
          lineHeight: '1rem',          
        },
        '@media screen and (max-width:600px)': {
          fontSize: '1rem',
          lineHeight: '1rem',          
        }
      }
    }} 
  value={value} 
  control={ <Radio 
              sx={{
                color: "#FFF",
                '&.Mui-checked': {
                  color: "#fdb913",
                },                                    
              }}
          />} 
  label={label} />
)

const SignUpComponent = () => {
  const [inputType, setInputType] = useState("password")
  const [password, setPassword] = useState("")
  const [usrnmChecked, setUsrnmChecked] = useState(false)
  const [email, setEmail] = useState("")
  const [usrnm, setUsrnm] = useState("")
  const [agegrp, setAgegrp] = useState("")
  const {setNotificationType, setNotificationMessage, setNotificationDisplayFlag, setLoadingDisplayFlag} = useContext(Context)
  const navigate = useNavigate()

  const setAlert = (type, message) => {
    setNotificationType(type)
    setNotificationMessage(message)
    setNotificationDisplayFlag(true)
  }

  const handleClick = () => {
    var errorMessage = checkValidation([
      {value: usrnm, fieldName:"Username", checksFor: [checkTypes.NOTEMPTY, checkTypes.NOOFCHARS], charLength: 5, charLengthCheckType:"GREATER"},
      {value: email, fieldName:"Email", checksFor: [checkTypes.NOTEMPTY, checkTypes.EMAIL]},
      {value: password, fieldName:"Password", checksFor: [checkTypes.NOTEMPTY, checkTypes.NOOFCHARS], charLength: 8, charLengthCheckType:"GREATER"},
      {value: agegrp, fieldName:"Age", checksFor: [checkTypes.NOTEMPTY]}
    ])
    if(errorMessage){
      return setAlert("error", errorMessage)
    }
    else{
      const url = BEURL + apiEndpoints.signUp
      const data = {
        "username": usrnm.trim(),
        "email": email.trim(),
        "password": password,
        "age_group": agegrp,
      }
      setLoadingDisplayFlag(true)
      axios.post(
        url,
        data
      ).then((res) => navigate(`/confirmemail/${usrnm}`, {state:{ message: res.data}})
      ).catch((error) => setAlert("error", error.response.data)
      ).finally(() => setLoadingDisplayFlag(false))
    }
  }

  const handleUsernameChange = (e) => {
    if(usrnm.length>=5){
      const url = BEURL + apiEndpoints.checkUsername
      const data = {
        "username": e.target.value
      }
      setLoadingDisplayFlag(true)
      axios.post(
        url,
        data
      ).then((res) => {
          setAlert("info", res.data)
          setUsrnm(e.target.value)
          setUsrnmChecked(true)
        }
      ).catch((error) => {
          setAlert("error", error.response.data)
          setUsrnm("")
          setUsrnmChecked(false)
        }
      ).finally(() => setLoadingDisplayFlag(false))
    }
    else{
      setUsrnmChecked(false)
    }
  }

  const handleOnPasswordRevealClick = () => {
    setInputType((prevValue) => {
      if (prevValue === "password")
        return "text"
      else  
        return "password"
    })
  }

  const handleGoogleClick = () => {
    window.location = `https://${process.env.REACT_APP_COGNITO_DOMAIN}/oauth2/authorize?identity_provider=Google&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&response_type=CODE&scope=${process.env.REACT_APP_COGNITO_SCOPE}&redirect_uri=${process.env.REACT_APP_COGNITO_REDIRECT}`
  }

  const handleFacebookClick = () => {
    window.location = `https://${process.env.REACT_APP_COGNITO_DOMAIN}/oauth2/authorize?identity_provider=Facebook&client_id=${process.env.REACT_APP_COGNITO_CLIENT_ID}&response_type=CODE&scope=${process.env.REACT_APP_COGNITO_SCOPE}&redirect_uri=${process.env.REACT_APP_COGNITO_REDIRECT}`
  }

  return (
    <UserAuthComponent>
      <p className="signup__title">Sign Up</p>
      <p className="signup__subtitle">Get started on your Quest to Quriosity</p>
      <div className="signin__social">
        {/* <p>Or sign in using</p> */}
        <div className="signin__social__button" onClick={handleGoogleClick}>
          <FcGoogle />
          <p>GOOGLE</p>
        </div>
        
        <div className="signin__social__button" onClick={handleFacebookClick} >
            <img src="/images/facebook_logo_icon.png"/>
            <p>FACEBOOK</p>
        </div>
      </div>    
      <p>OR</p>
      <InputComponent ClassName="signup__input" 
        Label="USERNAME" Type="text" Value={usrnm} OnChange={(e) => setUsrnm(e.target.value)} OnBlur={handleUsernameChange}
      >
        {usrnmChecked && 
          <TiTick className="signup__icon" fontSize={"x-large"} />
        }
      </InputComponent>
      <InputComponent ClassName="signup__input" 
        Label="EMAIL" Type="text" Value={email} OnChange={(e) => setEmail(e.target.value)}
      />
      <InputComponent ClassName="signup__input"
        Type={inputType} Label="PASSWORD" Value={password} OnChange={(e) => setPassword(e.target.value)}
      >
        {inputType==="password" ? 
          <VscEye className="signup__icon pointer" onClick={handleOnPasswordRevealClick}/> :
          <VscEyeClosed className="signup__icon pointer" onClick={handleOnPasswordRevealClick}/>
        }
      </InputComponent>
      <div className="signup__ageContainer">
        <label>AGE</label>        
          <RadioGroup
            aria-label="quiz"
            name="quiz"
            onChange={(e) => setAgegrp(e.target.value)}
            row
            sx = {{rowGap: "0.5rem", columnGap: "%"}}
          >                       
            <RadioBtn label={"6-8 YEARS"} value={"6 to 8"} />
            <RadioBtn label={"9-17 YEARS"} value={"9 to 14"}/>                                              
            <RadioBtn label={"18+ YEARS"} value={"9 to 14 "}/>      
          </RadioGroup>           
      </div>      
      <div className="signup__savebtn">
        <FillButtonComponent 
          displayText={"Sign Up"} 
          handleClick={handleClick}
        />
      </div>
      <div className="oneline">
        <p 
          className="signin__subtitle">By Signing up you agree to our&nbsp;
          <span className="signin__subtitle yellow" onClick={() => navigate('/termsandconditions')}>terms and condtions.</span>
        </p>
      </div>
      <div className="oneline">
        <p 
          className="signup__subtitle">Already have an account?&nbsp;
          <span className="signup__subtitle yellow pointer" onClick={() => navigate('/signin')}>Log in here</span>
        </p>
      </div>        
    </UserAuthComponent>
  )
}
export default SignUpComponent