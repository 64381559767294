import "./MiniMCQComponent.scss";
import MiniSlideComponent, {
  MiniOptions,
} from "../miniSlideComponent/MiniSlideComponent";

import React from "react";

const MiniMCQComponent = ({
  questionData = {},
  correctOption = "",
  questionType,
  handleDeleteClick,
}) => {
  const Options = questionType === "MCQ" ? ["A", "B", "C", "D"] : ["A", "B"];
  return (
    <MiniSlideComponent
      questionData={questionData}
      handleDeleteClick={handleDeleteClick}
    >
      <div className="miniMCQ">
        {Options.map((option) => (
          <MiniOptions
            option={option}
            correctOption={correctOption}
            key={questionData.questionId + option}
          />
        ))}
      </div>
    </MiniSlideComponent>
  );
};

export default MiniMCQComponent;
