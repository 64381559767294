import { RadioGroup } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BEURL } from "../../api/BaseUrl";
import apiEndpoints from "../../api/EndPoints";
import Context from "../../context/Context";
import CustomModalWithTitleComponent from "../../shared/customModalComponent/CustomModalWithTitleComponent";
import FillButtonComponent from "../../shared/fillButtonComponent/FillButtonComponent";
import InputComponent from "../../shared/inputComponent/InputComponent";
import MediaUploadComponent from "../createQuizComponents/mediaUploadComponent/MediaUploadComponent";
import DashboardDrawerComponent from "../dashboardDrawerComponent/DashboardDrawerComponent";
import { RadioBtn } from "../editProfileComponent/EditProfileComponent";
import WelcomeBannerComponent from "../welcomeBannerComponent/WelcomeBannerComponent";
import {
  TilesCarouselComponent,
  transformQuizData,
  transformQtestData,
} from "../tilesCarouselComponent/TilesCarouselComponent";
import { dashboard_welcomebanner_description } from "../../copies";
import "./MyLibraryComponent.scss";
import ViewAllTilesComponent from "../../shared/viewAllTilesComponent/viewAllTilesComponent";

const MyLibraryComponent = () => {
  const navigate = useNavigate();

  const {
    setLoadingDisplayFlag,
    setNotificationType,
    setNotificationMessage,
    setNotificationDisplayFlag,
  } = useContext(Context);
  const setAlert = (type, message) => {
    setNotificationType(type);
    setNotificationMessage(message);
    setNotificationDisplayFlag(true);
  };

  const [openCreateNewQuiz, setOpenCreateNewQuiz] = useState(false);
  const [openQuizDetails, setOpenQuizDetails] = useState(false);
  const [openAttachTags, setOpenAttachTags] = useState(false);
  const [quizName, setQuizName] = useState("");
  const [description, setDescription] = useState("");
  const [ageGroup, setAgeGroup] = useState("6 to 8");
  const [isDisabled, setIsDisabled] = useState(false);
  const [imageURL, setImageURL] = useState("");
  const [libraryData, setLibaryData] = useState({
    drafts: [],
    published: [],
    qtests: [],
  });
  const [activePage, setActivePage] = useState("welcomePage")
  const [viewAllData, setViewAllData] = useState([])
  const [viewAllTitle, setViewAllTitle] = useState("")
  const [viewAllSubtitle, setViewAllSubtitle] = useState("")

  const handleOnClickViewChange = (viewName) => () => {
    if(viewName==="viewAllDraftTiles"){
      setViewAllData(libraryData.drafts)
      setViewAllTitle("Drafts")
      setViewAllSubtitle("List of my quiz drafts.")
    }else if(viewName==="viewAllPublishTiles"){
      setViewAllData(libraryData.published)
      setViewAllTitle("Published")
      setViewAllSubtitle("List of my published quizzes.")
    }else if(viewName==="viewAllQTestTiles"){
      setViewAllData(libraryData.qtests)
      setViewAllTitle("QTests")
      setViewAllSubtitle("List of my qtests.")
    }
    setActivePage(viewName)
  }

  const goToQuizDetails = () => {
    setOpenCreateNewQuiz(false);
    setOpenAttachTags(false);
    setOpenQuizDetails(true);
  };

  useEffect(() => {
    setLoadingDisplayFlag(true);
    const url = BEURL + apiEndpoints.getMyLibrary;
    axios
      .post(url)
      .then((res) => {
        console.log(res.data);
        setLibaryData({
          drafts: transformQuizData(res.data.drafts),
          published: transformQuizData(res.data.published),
          qtests: transformQtestData(res.data.qtests),
        });
      })
      .catch((error) => setAlert("error", error.response.data))
      .finally(() => setLoadingDisplayFlag(false));
  }, []);

  useEffect(() => {
    setIsDisabled([quizName, description, imageURL].indexOf("") > -1);
  }, [quizName, description, imageURL]);

  const goToAttachTags = () => {
    setOpenAttachTags(true);
    setOpenQuizDetails(false);
  };

  const createQuiz = () => {
    setLoadingDisplayFlag(true);
    const url = BEURL + apiEndpoints.createQuiz;
    const data = {
      quizName: quizName,
      quizDescription: description,
      ageGroup: ageGroup,
      quizImage: imageURL,
    };
    axios
      .post(url, data)
      .then((res) => navigate(`/createquiz/${res.data["quizId"]}`))
      .catch((error) => {
        alert(error.response.data);
        setLoadingDisplayFlag(false);
      })
      .finally(() => {
        console.log("finally");
        setLoadingDisplayFlag(false);
      });
  };

  const addQuizToList = (quizList, listName) => {
    // Add new element, quizList, to the beginning of the array
    libraryData[listName].unshift(...quizList)
  }

  const placeholder_image = "/images/upload_media_green_placeholder_image.png";
  return (
    <div className="library page">
      <DashboardDrawerComponent selectedView="library" />
      <div className="library__content">
        {activePage === "welcomePage"? (<>
          <p className="page__title">My Library</p>
          {/* <div className="library__header"> */}
          {/* <WelcomeBannerComponent
            description={library_welcomebanner_description}
            handleOnClick={() => setOpenCreateNewQuiz(true)}
            buttonText="CREATE NEW QUIZ"
          /> */}

          <FillButtonComponent
            displayText="CREATE NEW QUIZ"
            bgColor="#30B2E7"
            handleClick={() => setOpenCreateNewQuiz(true)}
            style={{ width: "40%", minWidth: "385px", whiteSpace: "nowrap" }}
          />
          {/* </div> */}

          <div className="library__body">
            <TilesCarouselComponent
              title="Drafts"
              tilesData={libraryData.drafts}
              splitData={true}
              placeholder="You don't have any draft quizzes, try creating one!"
              enableMenuOptions={true}
              addQuizToList={addQuizToList}
              handleOnClickViewAll={handleOnClickViewChange("viewAllDraftTiles")}
            />
            <TilesCarouselComponent
              title="Published"
              tilesData={libraryData.published}
              splitData={true}
              placeholder="You don't have any published quizzes. Try publishing one!"
              enableMenuOptions={true}
              addQuizToList={addQuizToList}
              handleOnClickViewAll={handleOnClickViewChange("viewAllPublishTiles")}
            />
            <TilesCarouselComponent
              title="QTests"
              tilesData={libraryData.qtests}
              splitData={true}
              placeholder="You don't have any QTests. Try creating one!"
              enableMenuOptions={true}
              handleOnClickViewAll={handleOnClickViewChange("viewAllQTestTiles")}
            />
          </div>

          <CustomModalWithTitleComponent
            title="Create new quiz"
            open={openCreateNewQuiz}
            handleClose={() => setOpenCreateNewQuiz(false)}
            bgColor="#75B543"
            customStyles={{ overflow: "hidden", width: "auto" }}
          >
            <div className="createNewQuiz">
              <h5 className="createNewQuiz__subtitle">
                Select one of the following to get started
              </h5>
              <div className="createNewQuiz__options">
                <div
                  className="createNewQuiz__options__left"
                  onClick={goToQuizDetails}
                >
                  <p className="createNewQuiz__options__title">Start Fresh</p>
                  <p className="createNewQuiz__options__description">
                    Start from scratch and customise everything to your wants and
                    needs.
                  </p>
                </div>
                <div className="createNewQuiz__options__right">
                  <p className="createNewQuiz__options__title">
                    Build On A Template
                  </p>
                  <p className="createNewQuiz__options__description">
                    Select from a universe of quizzes and build on an existing
                    template.
                  </p>
                </div>
              </div>
            </div>
          </CustomModalWithTitleComponent>

          <CustomModalWithTitleComponent
            title="Quiz Details"
            open={openQuizDetails}
            handleClose={() => setOpenQuizDetails(false)}
            bgColor="#4B9710"
          >
            <div className="quizDetails">
              <div className="quizDetails__details">
                <InputComponent
                  Type="text"
                  Label="Quiz Name (Upto 100 Letters)"
                  Value={quizName}
                  Maxlength={100}
                  OnChange={(e) => setQuizName(e.target.value)}
                />
                <InputComponent
                  Type="text"
                  Label="Description (Upto 200 Letters)"
                  Value={description}
                  Maxlength={200}
                  OnChange={(e) => setDescription(e.target.value)}
                />
                {/* <div className='quizDetails__details__timelimit'>
                <label >Set quiz time limit?</label>        
                <RadioGroupx
                  aria-label="quiz"
                  name="quiz"
                  value={isTimeLimit}
                  onChange={(e) => setIsTimeLimit(e.target.value)}
                  row
                  sx = {{rowGap: "0.5rem", columnGap: "%"}}
                >                       
                  <RadioBtn label={"Yes"} value={true} />
                  <RadioBtn label={"No"} value={false}/>        
                </RadioGroup>  
              </div> */}
              </div>
              <div className="quizDetails__bannerUpload">
                <MediaUploadComponent
                  imageURL={imageURL}
                  setImageURL={setImageURL}
                  placeholderimage={placeholder_image}
                />
              </div>
            </div>
            <div className="quizDetails__proceedbtn">
              <FillButtonComponent
                isDisabled={isDisabled}
                displayText="PROCEED"
                handleClick={goToAttachTags}
              />
            </div>
          </CustomModalWithTitleComponent>

          <CustomModalWithTitleComponent
            title="Attach Tags"
            open={openAttachTags}
            handleClose={() => setOpenAttachTags(false)}
            handleBackButton={goToQuizDetails}
            bgColor="#4B9710"
          >
            <div className="column attachtags">
              <label className="editprofile__content__label">Age</label>
              <RadioGroup
                aria-label="quiz"
                name="quiz"
                value={ageGroup}
                onChange={(e) => setAgeGroup(e.target.value)}
                row
                sx={{ rowGap: "0.5rem", columnGap: "%" }}
              >
                <RadioBtn label={"6-8 YEARS"} value={"6 to 8"} />
                <RadioBtn label={"9-17 YEARS"} value={"9 to 14"} />
                <RadioBtn label={"18+ YEARS"} value={"9 to 14 "} />
              </RadioGroup>
              <div className="quizDetails__proceedbtn">
                <FillButtonComponent
                  displayText="CONTINUE BUILDING QUIZ"
                  handleClick={createQuiz}
                />
              </div>
            </div>
          </CustomModalWithTitleComponent></>)
        :<ViewAllTilesComponent 
              title={viewAllTitle}
              subtitle={viewAllSubtitle}
              tilesData={viewAllData} 
              splitData={true}
              enableMenuOptions={true}
              addQuizToList={addQuizToList}
              handleOnClickBack={handleOnClickViewChange("welcomePage")} />}
      </div>
    </div>
  );
};

export default MyLibraryComponent;
