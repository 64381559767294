import "./ProfileComponent.scss"
import React, { useContext, useEffect, useState } from 'react'
import TitleComponent from '../../shared/titleComponent/TitleComponent';
import { useNavigate } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import CustomModalComponent from "../../shared/customModalComponent/CustomModalComponent";
import Context from "../../context/Context";
import { BEURL } from "../../api/BaseUrl";
import apiEndpoints from "../../api/EndPoints";
import axios from "axios";
import LoginPromptComponent from "../../shared/loginPromptComponent/LoginPromptComponent";

const ProfileComponent = () => {
  const navigate = useNavigate()
  const {isLoggedIn, setLoadingDisplayFlag, setNotificationType, setNotificationMessage, setNotificationDisplayFlag} = useContext(Context)
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [city, setCity] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [intro, setIntro] = useState("")
  const [userName, setUserName] = useState("")
  const [hostedcount, setHostedcount] = useState("")
  const [wonCount, setWonCount] = useState("")
  const [playedCount, setPlayedCount] = useState("")
  const [imageURL, setImageURL] = useState("")
  const [role, setRole] = useState("")
  const [openCancelConfirmationModal, setOpenCancelConfirmationModal] = useState(false)

  const setAlert = (type, message) => {
    setNotificationType(type)
    setNotificationMessage(message)
    setNotificationDisplayFlag(true)
  }

  useEffect(() => {
    if (!isLoggedIn) // do not proceed if not logged in
      return
    const url_profile = BEURL + apiEndpoints.getProfile
    const url_stats = BEURL + apiEndpoints.getUserStats
    setLoadingDisplayFlag(true)
    axios.get(url_profile).then((res) => { 
      if (!(!!res.data['username'])) { // if social login users  haven't entered a username yet 
        setAlert("info", "Kindly set up your profile to continue. Username and Age mandatory!");
        navigate("/editprofile")     
      }
      setFirstName(res.data['given_name'])
      setLastName(res.data['family_name'])
      setCity(res.data['locale'])
      setPhoneNumber(res.data['phone_number'])
      setIntro(res.data['profile'])
      setUserName('@'+res.data['username'])  
      setImageURL(res.data['picture'] || "https://qshala-quiz-profile-images.s3.ap-south-1.amazonaws.com/no-image.jpg")       
      axios.get(url_stats).then((res) => {
        setHostedcount(res.data['quizzesHosted'])
        setWonCount(res.data['quizzesWon'])
        setPlayedCount(res.data['quizzesPlayed'])
        setRole(res.data['role'])
        setLoadingDisplayFlag(false)
      }).catch((err) => console.log(err)) 
    }).catch((err) => console.log(err))    
  }, []) 

  const handleCancelSubscription = () => {
    setOpenCancelConfirmationModal(false)
    setLoadingDisplayFlag(true)
    axios.get(BEURL + apiEndpoints.cancelSubscription).then((res) => {
      setAlert("info", res.data)
    }).catch((err) => setAlert("error", err.response.data)).finally(() => setLoadingDisplayFlag(false))
  }
  
  return (    
    <div className='profile'>  
      <CustomModalComponent open={openCancelConfirmationModal} handleClose={() => setOpenCancelConfirmationModal(false)}>
          <div className="selectMultiplayer__modal">
            <h2 style={{textAlign:"center"}}>Are you sure you want to cancel your QShala Premium Subscription?</h2>
            <div className="profile__cancelModal">
              <Button
                variant="contained"
                style={{backgroundColor: "#30b2e7"}}
                className="selectMultiplayer__modalButton"
                onClick={handleCancelSubscription}
              >
                Yes
              </Button>
              <Button
                variant="contained"
                style={{backgroundColor: "#30b2e7"}}
                className="selectMultiplayer__modalButton"
                onClick={() => setOpenCancelConfirmationModal(false)}
              >
                No
              </Button>
            </div>            
          </div>
      </CustomModalComponent>    
      {isLoggedIn ?
        <>       
          <TitleComponent 
            title="My Profile" 
            handleOnClick={() => navigate("/")}
            align="left" style={{marginRight: "auto", marginLeft:'14%'}}
            isIcon={true}
            onIconClick={() => navigate("/editprofile")}
          />      
            <div className="profile__userinfo">
              <img src={imageURL} alt="" />              
                <div className="profile__userinfo__left">
                  <div className="profile__userinfo__left__name"> 
                    <p>{firstName} {lastName}</p>
                    <p className="text">{userName}</p>
                  </div>
                  <div className="profile__userinfo__left__about">
                    <p className="text">{city} {phoneNumber? ` | Ph: ${phoneNumber}` : ""}</p>
                    <p className="subtext">{intro}</p>
                  </div>   
                  {/* Show Cancel button only for premium users                 */}
                  {role === "Premium" && <button onClick={() => setOpenCancelConfirmationModal(true)}>
                    Cancel Subscription
                  </button>}                                         
                </div>                   
            </div>
            <div className="profile__quizstats">
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md:10, lg: 30 }}>
                <Grid item xs={12} md={6}>
                  <div className="profile__quizstats__item">
                    <p>Number of quizzes played</p>
                    <p>{playedCount}</p>
                  </div>            
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="profile__quizstats__item">
                      <p>Number of quizzes hosted</p>
                      <p>{hostedcount}</p>
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className="profile__quizstats__item">
                    <p>Number of quizzes won</p>
                    <p>{wonCount}</p>
                  </div>
                </Grid>
                {/* <Grid item xs={12} md={6}>
                  <div className="profile__quizstats__item">
                    <p>Overall points</p>
                    <p>42</p>
                  </div>
                </Grid> */}
              </Grid>
            </div>
        </>://else if not logged in prompt to login
        <LoginPromptComponent open={!isLoggedIn} handleClose={() => navigate('/')} />
      }
    </div>
  )
}
export default ProfileComponent

