export const checkTypes = {
    NOTEMPTY: {errorType:"NOTEMPTY", errorMessage: ": Field cannot be empty"},
    EMAIL: {errorType:"EMAIL", errorMessage: ": Enter a valid email"},
    NOOFCHARS: {errorType:"NOOFCHARS"},
    NAME: {errorType:"NAME", errorMessage: ": Field cannot contain numbers and special characters"}
}

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
}

const validateName = (name) => {
    return /^[a-zA-Z]+$/.test(name);
}

//Returns error string on error or empty string
export const checkValidation = (validateFields)=>{
    for (let field of validateFields){
        for(let checkFor of field.checksFor){
            if( (checkFor.errorType===checkTypes.NOTEMPTY.errorType && !field.value) ||
                (checkFor.errorType===checkTypes.EMAIL.errorType && !validateEmail(field.value)) ||
                (checkFor.errorType === checkTypes.NAME.errorType && !validateName(field.value))) {
                return field.fieldName + checkFor.errorMessage
            }
            else if(checkFor.errorType===checkTypes.NOOFCHARS.errorType && (field.charLengthCheckType==="LESSER") && (field.value.length>field.charLength)){
                return `${field.fieldName}: Must contain atmost ${field.charLength} characters`
            }
            else if(checkFor.errorType===checkTypes.NOOFCHARS.errorType && (field.charLengthCheckType==="EQUAL") && (field.value.length!=field.charLength)){
                return `${field.fieldName}: Must contain only ${field.charLength} characters`
            }
            else if(checkFor.errorType===checkTypes.NOOFCHARS.errorType && (field.charLengthCheckType==="GREATER") && (field.value.length<field.charLength)){
                return `${field.fieldName}: Must contain atleast ${field.charLength} characters`
            }
            
        }
    }
    return "";
}